import Vue from 'vue'
const Components = require.context('./', false, /\w+\.vue$/)

const component = {
    install: function (Vue) {

        Components.keys().map(fileName => {
            let comp = Components(fileName).default;
            Vue.component(comp.name, comp)
        })
    }

}
export default component;