import request from "@/utils/request";
import { isBlank } from "@/utils/whale";

//获取管理的机构信息
export function agencyInfo() {
  return request({
    url: "/agency/admin",
    method: "get",
  });
}

//新增或修改机构角色
export function updateAgencyRole(data) {
  return request({
    url: "/agencyRole",
    method: "post",
    data: JSON.stringify(data),
  });
}

//获取管理的机构角色列表
export function agencyRoleList() {
  return request({
    url: "/agencyRole/list",
    method: "get",
  });
}

//删除管理的机构角色组或角色
export function deleteAgencyRole(agencyRoleId) {
  return request({
    url: `/agencyRole/${agencyRoleId}`,
    method: "delete",
  });
}

//新增或修改组织机构
export function updateAgencyDept(data) {
  return request({
    url: "/agencyDept",
    method: "post",
    data: JSON.stringify(data),
  });
}

//获取组织机构列表
export function agencyDeptList() {
  return request({
    url: "/agencyDept/list",
    method: "get",
  });
}

//根据机构ID获取机构列表
export function fillAgencyDeptList(agencyId) {
  return request({
    url: "/agencyDept/listById/" + agencyId,
    method: "get",
  });
}

//删除组织机构
export function delAgencyDept(agencyDept) {
  return request({
    url: "/agencyDept/" + agencyDept,
    method: "delete",
  });
}

//根据层级获取组织机构列表
export function agencyDeptLevelList(level) {
  return request({
    url: "/agencyDept/list/" + level,
    method: "get",
  });
}

//新增或修改机构用户
export function updateAgencyUser(data) {
  return request({
    url: "/agencyUser",
    method: "post",
    data: JSON.stringify(data),
  });
}

//获取机构用户信息
export function getAgencyUser(agencyId) {
  return request({
    url: `/agencyUser/${agencyId}`,
    method: "get",
  });
}

//获取机构用户详细信息
export function getAgencyUserInfo(agencyId) {
  if(!agencyId){
    return;
  }
  return request({
    url: `/agencyUser/agencyUserInfo/${agencyId}`,
    method: "get",
  });
}

//获取用户的机构列表
export function userAgencyList() {
  return request({
    url: "/user/agencyList",
    method: "get",
  });
}

//删除修改机构用户
export function deleteAgencyUser(agencyUserId) {
  return request({
    url: `/agencyUser/${agencyUserId}`,
    method: "delete",
  });
}

//批量删除用户
export function batchDeleteAgencyUser(agencyUserIds) {
  return request({
    url: `/agencyUser/delete-all`,
    method: "delete",
    data: {agencyUserIds: agencyUserIds}
  });
}

//机构管理员查看，机构用户列表
export function agencyUserList(data) {
  return request({
    url: "/agencyUser/list",
    method: "post",
    data: data
  });
}

//批量导入机构用户信息
export function agencyUserExcelImport(file) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/agencyUser/excelImport",
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

//批量导入机构用户信息进度查询
export function agencyUserExcelImportProgress(callback) {
  var intervalHandler = setInterval(() => {
    request(
      {
        url: "/agencyUser/importProgress",
        method: "get",
      }
    ).then(res=>{
      callback(res.data)
    });
  },
  1000);
  return intervalHandler;
}

//组织设置负责人
export function agencyLeader(agencyDeptId, leader) {
  let leaderIds = !isBlank(leader) ? leader.join(",") : "";
  return request({
    url: "/agencyDept/leader/" + agencyDeptId + "?leader=" + leaderIds,
    method: "put",
  });
}

//批量导入组织架构
export function agencyDeptExcelImport(file) {
  let formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/agencyDept/excelImport",
    method: "post",
    timeout: 0,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
}

//针对权限设置中填报人弹框中列表
export function agencyFillUserList(data) {
  let url = "/agencyUser/windowList";
  let param = {
    agencyDeptId: data.agencyDeptId ? data.agencyDeptId : null,
    agencyRoleId: data.agencyRoleId ? data.agencyRoleId : null,
    allFillUser: data.allFillUser ? data.allFillUser : "",
    name: data.name,
    number: data.number,
    pageNum: data.pageNum,
    pageSize: data.pageSize,
    selectFillUser: data.selectFillUser ? data.selectFillUser : "",
  };
  return request({
    url: url,
    method: "post",
    data: JSON.stringify(param),
  });
}

//针对权限设置中填报人弹框中一键添加
export function addAllFillUser(data) {
  let url = "/agencyUser/selectAll";
  let param = {
    agencyDeptId: data.agencyDeptId ? data.agencyDeptId : null,
    agencyRoleId: data.agencyRoleId ? data.agencyRoleId : null,
    allFillUser: data.allFillUser ? data.allFillUser : "",
    name: data.name,
    number: data.number,
    pageNum: data.pageNum,
    pageSize: data.pageSize,
    selectFillUser: data.selectFillUser ? data.selectFillUser : "",
  };
  return request({
    url: url,
    method: "post",
    data: JSON.stringify(param),
  });
}

export function agencyDashboard() {
  return request({
    url: "/agency/dashboard",
    method: "get",
  });
}

export function versionDashboard() {
  return request({
    url: "/version/dashboard",
    method: "get",
  });
}

// 3.0.0.1 机构用户查询，不分，只查询前100
export function agencyUserMemberList(agencyId, searchTxt, agencyDeptId) {
  if (!agencyId) return;
  let url = "/agencyUser/list/" + agencyId;
  if (!isBlank(searchTxt) && !isBlank(agencyDeptId)) {
    url = url + "?searchTxt=" + searchTxt + "&agencyDeptId=" + agencyDeptId;
  } else {
    if (!isBlank(searchTxt)) {
      url = url + "?searchTxt=" + searchTxt;
    }
    if (!isBlank(agencyDeptId)) {
      url = url + "?agencyDeptId=" + agencyDeptId;
    }
  }
  return request({
    url: url,
    method: "get",
  });
}

// 3.0.0.1 机构设置部门负责人
export function agencyDeptLeader(param) {
  return request({
    url: "/agencyDept/leader",
    method: "put",
    data: param,
  });
}

// 设置子管理员
export function agencySubAdmin(param) {
  return request({
    url: "/agencyUser/add-sub-admin",
    method: "post",
    data: JSON.stringify(param),
  });
}

// 子管理员列表
export function agencySubAdminList(query) {
  return request({
    url: "/agencyUser/sub-admin-list",
    method: "get",
    params: query
  });
}

// 删除子管理员
export function delAgencySubAdmin(param) {
  return request({
    url: "/agencyUser/del-sub-admin",
    method: "delete",
    data: JSON.stringify(param),
  });
}

// 获取管理的机构信息
export function agencyAdmin() {
  return request({
    url: "/agency/admin",
    method: "get",
  });
}

// 查询机构信息详细
export function getAgency(agencyId) {
  if(!agencyId){
    return;
  }
  return request({
    url: "/agency/" + agencyId,
    method: "get",
  });
}

// 下载机构用户导入的Excel模板
export function downloadImportTemplate(agencyId) {
  return request({
    url: `/agencyUser/excel-import-template/${agencyId}`,
    method: "get",
    responseType: "blob",
  });
}

// 下载机构用户导入的Excel模板
export function downloadUserExcel(param) {
  return request({
    url: `/agencyUser/downloadExcel`,
    method: "post",
    data: param,
    responseType: "blob",
  });
}


// 机构用户手动绑定系统账号
export function agencyUserBinding(param) {
  return request({
    url: `/agencyUser/binding`,
    method: "post",
    data: param
  });
}

// 机构用户手动解绑系统账号
export function agencyUserUnBinding(agencyUserId) {
  return request({
    url: `/agencyUser/unBind/${agencyUserId}`,
    method: "get"
  });
}

// 更改用户绑定给手机号
export function agencyUserChangeBinding(param) {
  return request({
    url: `/agencyUser/changeBinding`,
    method: "post",
    data:param
  });
}
