import request from "@/utils/request";
import { isBlank } from "@/utils/whale";
import { getToken } from "../../utils/auth";
import { cleanObject } from "@/utils/deepClone";

// 保存草稿
export function saveDraft(data) {
  return request({
    url: "/fill/draft",
    method: "post",
    data: data,
  });
}

// 草稿修改：/fill/draft
export function updateDraft({formId,fillId,fillJson,fillChange}) {
  return request({
    url: "/fill/draft",
    method: "put",
    data: {
        formId,fillId,fillJson,fillChange,draft: true
    },
  });
}

// 删除草稿: /fill/draft
export function deleteDraft(formId,fillId) {
  return request({
    url: "/fill/draft/",
    method: "delete",
    params:{formId,fillId}
  });
}

// 获取草稿列表: /fill/draft
export function getDraftList(formId,data) {
  return request({
    url: "/fill/draft/list/"+formId,
    method: "post",
    data: cleanObject(data),
  });
}
// 获取草稿详情: /fill/draft
export function getDraft(formId,fillId) {
  return request({
    url: "/fill/draft/",
    method: "get",
    params:{ formId,fillId}
  });
}

// 提交草稿为正式数据：fill/draft/submit
export function submitDraft(formId,fillId) {
  return request({
    url: "/fill/draft/submit",
    method: "post",
    params:{formId,fillId}
  });
}
