<script>
export default {
    name: 'fillSelectCardComponentRender',
    props: {
        widgetRender: Function,
        field: Object,
        row: Object,
        column: Object,
    },
    render(createElement) {       
        let renderSpans = this.widgetRender(
            createElement,
            { events: {} },
            {
                row: this.row,
                column: {
                    ...this.field,
                    property: this.field.name,
                    params: this.field.options,
                },
            }
        );
        let displayBlock = ['picture-upload', 'file-upload'];
        return createElement(
            'div',
            {
                style: {
                    display: displayBlock.find((x) =>x==this.field.type)
                        ? 'block'
                        : 'inline-block',
                    verticalAlign: 'middle',
                },
            },
            renderSpans
        );
    },
};
</script>