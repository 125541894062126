<template>
  <el-dialog :title="subformTitle" :visible.sync="show" v-if="show" :width="isMobile?'95%':'1000px'" append-to-body destroy-on-close>
    <div style="height:600px;">
      <vxe-table ref="xSubformGrid" id="toolbar_demo_2" :seq-config="{ start: 1 }" v-bind="tableConfig" :data="subData" :cell-class-name="rowTitleNameLeft">
        <vxe-column type="seq" title="序号" width="60" v-if="this.tableConfig.showRowNumber"></vxe-column>
        <!-- <vxe-column title="" field="title" min-width="80" align="left" v-if="this.tableConfig.showTitleRow"></vxe-column> -->
        <template v-for="(col,idx) in subColumns">
          <vxe-column v-if="!col.slots" v-bind="col" :filters="col.filters" :filter-render="col.filterRender" :params="col" :key="idx">
            <template #header="{$table,column}">
              <span :title="JSON.stringify(col)">{{ column.title }}</span>
              <!-- <span class="custom-sort" :class="{'is-order': column.order}" v-if="column.sortable" @click="headerCellClickEvent({$table:$table,column:column})">
                <i :class="[column.order  ? `el-icon-sort-${column.order==='asc' ? 'up' : 'down'}` : 'el-icon-sort']"></i>
              </span> -->
            </template>
          </vxe-column>
        </template>
      </vxe-table>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
      <el-button type="primary" @click="show=false">关闭</el-button>
    </div>
  </el-dialog>

</template>
<script>
import filedColumnMap from '@/utils/gridHeader.js'
import { isMobile } from '@/utils/index'
export default {
  data () {
    return {
      show: false,
      subformTitle: '',
      subColumns: [],
      subData: [],
      tableConfig: {
        seqConfig: { start: 1 },
        rowConfig: {
          height: 40
        },

        filterConfig: {
          showIcon: false,
        },
        sortConfig: {
          sortable: false,
          showIcon: false
        },
        showOverflow:'ellipsis'
      },
      rowTitleNameLeft: '',
      isMobile: isMobile(),
    }
  },
  methods: {
    showSubformDialog: function ({
      subformData,
      column,
      //   columnConfig,
      rowTitleNameLeft
    }) {
      const columnConfig = filedColumnMap['sub-form'](column);

      this.subformTitle = columnConfig.title ? `【${columnConfig.title}】详细数据` : '详细数据';
      this.subColumns = columnConfig.params.subWidgets;

      this.subData = subformData;

      if (columnConfig.params.showRowNumber) {
        this.tableConfig.showRowNumber = columnConfig.params.showRowNumber;
      }
      if (columnConfig.params.isShowTitleRow) {
        this.tableConfig.showTitleRow = columnConfig.params.isShowTitleRow;
      }

      this.rowTitleNameLeft = rowTitleNameLeft;
      this.show = true;

    }
  }
}
</script>
<style lang="scss" scoped>
// ::v-deep .vxe-cell--checkbox {
//   padding-left: 10px;
// }

// ::v-deep
//   .vxe-table--render-default
//   .is--checked.vxe-cell--checkbox
//   .vxe-checkbox--icon {
//   color: #3476f0;
// }
// ::v-deep
//   .vxe-table--render-default
//   .is--checked.vxe-cell--checkbox
//   .vxe-checkbox--icon:before {
//   border-color: #3476f0;
//   background-color: #3476f0;
// }
// ::v-deep .vxe-checkbox--icon {
//   top: 0.7em;
// }
// ::v-deep .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
//   color: transparent;
// }

// ::v-deep .vxe-cell.c--tooltip {
//   .vxe-checkbox--icon.vxe-checkbox--indeterminate-icon {
//     display: none;
//   }

//   .is--checked {
//     .vxe-checkbox--icon.vxe-checkbox--checked-icon {
//       display: inline-block !important;
//     }
//     .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
//       display: none !important;
//     }
//     .checkbox-index {
//       display: none;
//     }
//   }

//   .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
//     display: none;
//   }
// }

// ::v-deep .vxe-body--row.row--hover {
//   &:hover {
//     .checkbox-index {
//       display: none;
//     }

//     .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
//       display: inline-block;
//     }
//   }
// }
</style>