<template>
<div class="signature-dialog" :class="[direction]">
    <div class="signature-area">
        <div class="title" v-if="options.title">手写签名</div>
        <vue-esign ref="esign" v-if="isReady" :width="signatureWidth" :height="signatureHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" @on-draw="onDraw" :direction="direction" />
        <div class="signature-tool">
            <el-button size="mini" type="default" v-if="!disabled && options.clear" @click="clearSignature">清空</el-button>
            <el-button size="mini" type="default" v-if="!disabled && options.undo" @click="undoSignature">撤销</el-button>
        </div>
        <div class="signature-confirm">
            <el-button size="mini" type="primary" @click="(e)=>saveSign(e)" v-if="!disabled && options.confirm">确定</el-button>
            <el-button size="mini" v-if="options.close" @click="closeSign">关闭</el-button>
        </div>
    </div>
</div>
</template>
<script>
import vueEsign from './esign.vue'
export default {
    name: 'VueSignaturePad',
    components:{
        vueEsign
    },
    props:{
        width:{
            type:Number,
            default: 800
        },
        height:{
            type:Number,
            default: 400
        },
        options:{
            type: Object,
            default: ()=> {
                return {
                title: false,
                clear: true,
                undo: true,
                close: false,
                confirm: false
            }}
        }
    },
    mounted(){
        this.isReady = true;
        
        var evt = "onorientationchange" in window ? "orientationchange" : "resize";
        window.removeEventListener(evt, this.orientationChange)
        window.addEventListener(evt, this.orientationChange, false);
        this.rotate = window.orientation;
        if (window.orientation == 180 || window.orientation == 0) {
            this.signatureWidth = 800
            this.signatureHeight = 600
            this.direction = 'vertical'
        }else{
            this.signatureWidth = 800
            this.signatureHeight = 600
            this.direction = 'horizontal'
        }
        this.$emit("ondirection", this.direction)
    },
    data () {
        return {
            lineWidth: 6,
            lineColor: '#000000',
            bgColor: '',
            resultImg: '',
            isCrop: true,
            history:[],

            esignW: 0,
            esignH: 0,
            isReady: false,

            disabled: false,
            direction: '',
            signatureWidth: this.width,
            signatureHeight: this.height
        }
    },
    methods: {
        orientationChange () {
            this.rotate = window.orientation;
            if (window.orientation == 180 || window.orientation == 0) {
                console.log('竖屏');
                this.direction = 'vertical'
            }
            if (window.orientation == 90 || window.orientation == -90) {
                console.log('横屏');
                this.direction = 'horizontal'

                this.$emit("ondirection", this.direction)
            }
        },
        handleReset () {
            this.$refs.esign.reset()
        },
        saveSignature(){
            return this.$refs.esign.saveSignature();    
        },
        handleGenerate () {
            this.$refs.esign.generate().then(res => {
            this.resultImg = res
            }).catch(err => {
                alert(err) // 画布没有签字时会执行这里 'Not Signned'
            })
        },
        fromDataURL(data){
            this.$refs.esign.fromDataURL(data);    
        },
        undoSignature(){
           this.$refs.esign.undoSignature();
        },
        clearSignature(){
            this.$refs.esign.reset()
        },
        lockSignaturePad(){

        },
        closeSign(){
            this.$emit('on-close')
        },
        saveSign(e){
            e.preventDefault();
            this.$emit('on-save', this.saveSignature())
        },
        onDraw(){
            this.$emit('on-draw')
        }
    }
}
</script>
<style lang="scss" scoped>
.signature-dialog{
    display:flex;
    justify-content:center;
    align-items: center;
    width:100%;
    height:100%;
    box-sizing: border-box;
    background-color: #f5f5f5;

    &.vertical{
        align-items: flex-end;
    }
}
.signature-area{
    display:inline-flex;
    flex-direction:column;
    box-sizing:border-box;
    justify-content:center;
    align-items:center;
    position:relative;

    border:dashed 2px #aaaaaa;

  .signature-tool{
      display:inline-flex;
      position:absolute;
      bottom:5px;
      left:10px;

      &-item{
        margin-right: 10px;
        padding: 5px;
        border:solid 1px #aaaaaa;
      }
  }
  .signature-confirm{
    display:inline-block;
    position:absolute;
    bottom:5px;
    right:10px;
  }

  
  .title {
    height: 25px;
    line-height: 25px;
    flex-shrink: 0;
    box-sizing: border-box;    
    display: inline-block;
    overflow: hidden;
    text-align: left;
    user-select: none;

    position:absolute;
    top:0px;
    left:10px;
  }
}

</style>