<template>
    <div class="my-filter-input">
        <el-input type="text" v-model="option.data" placeholder="请输入筛选内容" size="small" @keyup.enter.native="confirmFilter" @input="changeOptionEvent"></el-input>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterUniversity',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
            university:[],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;

            $panel.changeOption(null, checked, option);
        },
        clearFilter() {
            this.option.data = '';
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data;

            //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter(this.params);
        },
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
}
</style>