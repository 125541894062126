import axios from 'axios'
import {agencyDeptList} from '@/api/system/agency.js'
const state = {
  dept: [],
}

const mutations = {
  SET_DEPT: (state,code) => {
    state.dept=code;
  }
}

const actions = {
  GetDeptList(context,callback) {
    if(context.state.dept.length==0){
      agencyDeptList().then((res)=>{
        if(callback && res)callback(res.data);
      })
    }else{
      if(callback)callback(state.dept);
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
