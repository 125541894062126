var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { height: _vm.height } },
    [
      _c("MyVxeTable", {
        ref: "fillGridTable",
        attrs: {
          columns: _vm.renderFillSelectTableViewColumns(
            _vm.fields,
            _vm.showDelete
          ),
          data: _vm.datas,
          params: _vm.params,
        },
        on: {
          "view-picture": _vm.onHandlePreviewImg,
          "clear-global-search": _vm.clearGlobalSearch,
          "filter-change": _vm.fillFilterChanged,
          "sort-change": _vm.fillSortChanged,
        },
        scopedSlots: _vm._u([
          {
            key: "operate",
            fn: function () {
              return [
                _c("vxe-column", {
                  attrs: {
                    title: "操作",
                    width: _vm.operatorWidth,
                    "slots:default": "operate",
                    "header-class-name": "sysfield",
                    fixed: "right",
                    align: "center",
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.inFillSelect
                        ? {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "min-width": "30px" },
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-view",
                                      status: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewItem(row, row._index)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.isMobile
                                      ? _c("span", [_vm._v("查看")])
                                      : _vm._e(),
                                  ]
                                ),
                                !_vm.disabled
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "min-width": "30px" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-remove",
                                          status: "primary",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(
                                              row,
                                              row._index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.isMobile
                                          ? _c("span", [_vm._v("移除")])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "min-width": "30px" },
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-view",
                                      status: "primary",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewItem(row, row._index)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.isMobile
                                      ? _c("span", [_vm._v("查看")])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm.showDelete ||
                                row.userId === _vm.userAgency.userId
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "min-width": "30px" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-view",
                                          status: "primary",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(row, row._index)
                                          },
                                        },
                                      },
                                      [
                                        !_vm.isMobile
                                          ? _c("span", [_vm._v("编辑")])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.showDelete ||
                                row.userId === _vm.userAgency.userId
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: { "min-width": "30px" },
                                        attrs: {
                                          type: "text",
                                          icon: "el-icon-remove",
                                          status: "primary",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeItem(
                                              row,
                                              row._index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.isMobile
                                          ? _c("span", [_vm._v("删除")])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }