const DRAWING_ITEMS = 'drawingItems'
const DRAWING_ITEMS_VERSION = '1.2'
const DRAWING_ITEMS_VERSION_KEY = 'DRAWING_ITEMS_VERSION'
const DRAWING_ID = 'idGlobal'
const TREE_NODE_ID = 'treeNodeId'
const FORM_CONF = 'formConf'
const FORM_INFO = 'formInfo'

export function getDrawingList(userId) {
  // 加入缓存版本的概念，保证缓存数据与程序匹配
  // const version = localStorage.getItem(DRAWING_ITEMS_VERSION_KEY)
  // if (version !== DRAWING_ITEMS_VERSION) {
  //   localStorage.setItem(DRAWING_ITEMS_VERSION_KEY, DRAWING_ITEMS_VERSION)
  //   saveDrawingList([])
  //   return null
  // }
  const str = localStorage.getItem(DRAWING_ITEMS+ '_' + userId)
  if (str) return JSON.parse(str)
  return null
}

export function saveDrawingList(list, userId) {
  localStorage.setItem(DRAWING_ITEMS + '_' + userId, JSON.stringify(list))
}

export function getIdGlobal() {
  const str = localStorage.getItem(DRAWING_ID)
  if (str) return parseInt(str, 10)
  return 100
}

export function saveIdGlobal(id) {
  localStorage.setItem(DRAWING_ID, `${id}`)
}

export function getTreeNodeId() {
  const str = localStorage.getItem(TREE_NODE_ID)
  if (str) return parseInt(str, 10)
  return 100
}

export function saveTreeNodeId(id) {
  localStorage.setItem(TREE_NODE_ID, `${id}`)
}

export function getFormConf() {
  const str = localStorage.getItem(FORM_CONF)
  if (str) return JSON.parse(str)
  return null
}

export function saveFormConf(obj) {
  localStorage.setItem(FORM_CONF, JSON.stringify(obj))
}

export function saveFormInfo(obj) {
  localStorage.setItem(FORM_INFO, JSON.stringify(obj))
}

export function setSessionStorageObj(key,obj) {
  sessionStorage.setItem(key, JSON.stringify(obj))
}

export function getSessionStorageObj(key) {
  return JSON.parse(sessionStorage.getItem(key))
}

export function setLocalStorageObj(key,obj) {
  localStorage.setItem(key, JSON.stringify(obj))
}

export function getLocalStorageObj(key) {
  return JSON.parse(localStorage.getItem(key))
}



