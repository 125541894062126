// import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */
// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/agGrid',
    component: (resolve) => require(['@/views/system/form/agGrid'], resolve),
    hidden: true
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/admin_login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/login_redirect',
    component: (resolve) => require(['@/views/login_redirect'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/apidoc',
    component: (resolve) => require(['@/views/tool/apidoc/index'], resolve),
    hidden: true
  },
  {
    path: '/myForm',
    component: Layout,
    redirect: 'form',
    children: [
      {
        path: '',
        component: (resolve) => require(['@/views/system/form/index'], resolve),
        name: '我的填报',
        meta: { title: '我的填报', icon: 'dashboard', flag: true }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/system/agency/index'], resolve),
        name: '首页',
        meta: { title: '首页', icon: 'dashboard', flag: true }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user', flag: false }
      },
      {
        path: 'wechat',
        component: (resolve) => require(['@/views/system/user/profile/wechatBind'], resolve),
        name: 'wechat',
        meta: { title: '微信绑定', icon: 'user', flag: true }
      }
    ]
  },
  {
    path: '/mechan',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/system/mechan/index'], resolve),
        name: 'Profile',
        meta: { title: '机构管理', flag: true },
      }
    ]
  },
  {
    path: '/form',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'new',
        component: (resolve) => require(['@/views/system/form/new'], resolve),
        name: 'New',
        meta: { title: '创建单表单项目', flag: false }
      },
      {
        path: 'edit/:id',
        component: (resolve) => require(['@/views/system/form/edit'], resolve),
        name: 'Index',
        meta: { title: '修改表单', flag: false }
      },
      {
        path: 'detail/:id',
        // component: (resolve) => require(['@/views/system/form/detail'], resolve),        
        component: (resolve) => require(['@/views/system/form/dataView/detail'], resolve),                                                                                                                                                                                                     
        name: 'Detail',
        meta: { title: '表单详情', flag: false }
      },
      {
        path: 'view/:id',
        // component: (resolve) => require(['@/views/system/form/detail'], resolve),
        component: (resolve) => require(['@/views/system/form/dataView/detail'], resolve),
        name: 'Detail',
        meta: { title: '表单详情', flag: false }
      },
      {
        path: 'multipleFormDetail/:id',
        component: (resolve) => require(['@/views/system/form/multipleDetail'], resolve),
        name: 'multipleFormDetail',
        meta: { title: '多表单项目详情', flag: false }
      }
    ]
  },
  {
    path: '/formCreate',
    component: (resolve) => require(['@/views/system/form/form'], resolve),
    hidden: true
  },
  {
    path: '/h5Detail/:id',
    component: (resolve) => require(['@/views/system/form/h5Detail'], resolve),
    name: 'h5Detail',
    meta: { title: 'h5表单详情', flag: false }
  },
  {
    path: '/h5Render/:ver/:id/:token',
    component: (resolve) => require(['@/views/system/form/h5Render'], resolve),
    name: 'h5Render',
    meta: { title: '表单填报', flag: false }
  },
  {
    path: '/h5Render/:ver/:id/:token/:fillId',
    component: (resolve) => require(['@/views/system/form/h5Render'], resolve),
    name: 'h5Render',
    meta: { title: '表单详情', flag: false }
  },
  {
    path: '/share/:id',
    component: (resolve) => require(['@/views/system/form/share'], resolve),
    name: 'BlankDetail',
    meta: { title: '表单详情', flag: false }
  },
  {
    path: '/dataShare/:id',
    component: (resolve) => require(['@/views/system/form/dataShare'], resolve),
    name: 'dataShare',
    meta: { title: '数据详情', flag: false }
  },
  {
    path: '/blankDetail/:id',
    // component: (resolve) => require(['@/views/system/form/blankDetail'], resolve),
    component: (resolve) => require(['@/views/system/form/dataView/detail'], resolve),     
    name: 'BlankDetail',
    meta: { title: '表单详情', flag: false }
  },
  {
    path: '/projectBlankDetail/:id',
    // component: (resolve) => require(['@/views/system/form/projectBlankDetail'], resolve),
    component: (resolve) => require(['@/views/system/form/multipleDetail'], resolve),
    name: 'projectBlankDetail',
    meta: { title: '项目详情', flag: false }
  },
  {
    path: '/sso/nzxy',
    component: (resolve) => require(['@/views/sso/nzxy/index.vue'], resolve),
    name: 'sso_nzxy',
    meta: { title: '统一身份认证', flag: false }
  },

]

const router = new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})


router.beforeEach((to,from,next)=>{
  router.options.from = from;
  next();
})
export default router;
