var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signature-dialog", class: [_vm.direction] },
    [
      _c(
        "div",
        { staticClass: "signature-area" },
        [
          _vm.options.title
            ? _c("div", { staticClass: "title" }, [_vm._v("手写签名")])
            : _vm._e(),
          _vm.isReady
            ? _c("vue-esign", {
                ref: "esign",
                attrs: {
                  width: _vm.signatureWidth,
                  height: _vm.signatureHeight,
                  isCrop: _vm.isCrop,
                  lineWidth: _vm.lineWidth,
                  lineColor: _vm.lineColor,
                  bgColor: _vm.bgColor,
                  direction: _vm.direction,
                },
                on: {
                  "update:bgColor": function ($event) {
                    _vm.bgColor = $event
                  },
                  "update:bg-color": function ($event) {
                    _vm.bgColor = $event
                  },
                  "on-draw": _vm.onDraw,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "signature-tool" },
            [
              !_vm.disabled && _vm.options.clear
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "default" },
                      on: { click: _vm.clearSignature },
                    },
                    [_vm._v("清空")]
                  )
                : _vm._e(),
              !_vm.disabled && _vm.options.undo
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "default" },
                      on: { click: _vm.undoSignature },
                    },
                    [_vm._v("撤销")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "signature-confirm" },
            [
              !_vm.disabled && _vm.options.confirm
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function (e) {
                          return _vm.saveSign(e)
                        },
                      },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
              _vm.options.close
                ? _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.closeSign } },
                    [_vm._v("关闭")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }