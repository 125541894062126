const permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {

  },
  actions: {

  }
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) =>  require([`@/views/${view}`], resolve)
}

export default permission
