import axios from "axios";
import { MessageBox, Message } from "element-ui";
const _Message = Message;
import store from "@/store";
import { getToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 30000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    if (getToken() && !isToken) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const message = errorCode[code] || res.data.msg || errorCode["default"];
    if (code == 200) {
      const regex = /; filename=/i;

      // console.log('content-disposition',res.headers["content-disposition"],regex.test(res.headers["content-disposition"]))
      if (
        res.headers["content-disposition"] &&
        regex.test(res.headers["content-disposition"])
      ) {
        return res;
      } else {
        return res.data;
      }
    } else if (code == 10001) {
      const { pathname } = window.location;
      if (pathname.indexOf("/login") === -1) {
        MessageBox.confirm(
          "登录状态已过期，您可以继续留在该页面，或者重新登录",
          "系统提示",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("LogOut").then(() => {
            location.reload();
          });
        });
      }
    } else if (code == 10002) {
      const { pathname } = window.location;
      if (pathname.indexOf("/login") === -1) {
        Message.alert("检测到您的账号已在其它设备登录", "系统安全提示", {
          confirmButtonText: "重新登录",
          type: "warning",
          callback: (action) => {
            store.dispatch("LogOut").then(() => {
              location.reload();
            });
          },
        });
      }
    } else {
      _Message.closeAll();
      _Message({
        message: message,
        customClass: 'global-messageZIndex',
        type: "error",
      });
      return Promise.reject(new Error(message));
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    _Message({
      message: message,
      type: "error",
      customClass: 'global-messageZIndex',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
