import Vue from 'vue'
import Cookies from 'js-cookie'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import ELEMENT from 'element-ui'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/whale.scss' // whale css


// import vform from '@/lib/VFormDesigner.umd.min.js'
import vform from 'VFormDesigner'
Vue.use(vform);

// Vue.config.devtools = true

import App from './App'
import store from './store'
import router from './router'

import './assets/icons' // icon
import './permission' // permission control
import 'quill/dist/quill.core.css' // 引入样式
import { handleTree, isBlank, numberToUpperCase, encode, decode } from "@/utils/whale";
import Pagination from "@/components/Pagination";

import nodeWrap from '@/views/system/form/components/nodeWrap'
Vue.use(nodeWrap)
Vue.component('nodeWrap', nodeWrap); //初始化组件
import addNode from "@/views/system/form/components/addNode"
Vue.use(addNode)
Vue.component('addNode', addNode); //初始化组件

// 在弹出组件里使用弹出组件，需要全局注册了
import VFormViewer from '@/views/system/form/components/vFormViewer/index.vue'
Vue.component('VFormViewer', VFormViewer);


import VueSignaturePad from '@/components/VueSignaturePad';
// Vue.use(VueSignaturePad);
Vue.component('VueSignaturePad',VueSignaturePad);

import GlobalPosition from '@/components/GlobalPosition/index.vue';
Vue.component('GlobalPosition',GlobalPosition);

// 全局方法挂载
Vue.prototype.handleTree = handleTree
Vue.prototype.isBlank = isBlank
Vue.prototype.numberToUpperCase = numberToUpperCase
Vue.prototype.encode = encode;
Vue.prototype.decode = decode;

let msgHandler=null;
let msgDuration=2000;

['Success','Error','Info','Warning'].forEach(x=>{
  Vue.prototype['msg'+x] =function(msg){
    if(msgHandler){
      msgHandler.close()
      setTimeout(()=>{
        msgHandler=null;
        Vue.prototype['msg'+x](msg);
      },100)
    }else{
      msgHandler=this.$message({ showClose: true, message: msg, type: x.toLocaleLowerCase(), customClass:"global-messageZIndex", duration:msgDuration,offset:'60',onClose:()=>{
        msgHandler=null;
      } });
    }
  }
})

// Vue.prototype.msgSuccess = function (msg) {

// }

// Vue.prototype.msgError = function (msg) {
//   msgHandler=this.$message({ showClose: true, message: msg, type: "error",duration:msgDuration,offset:'60' });
// }

// Vue.prototype.msgWarning = function (msg) {
//   msgHandler=this.$message({ showClose: true, message: msg, type: "warning",duration:msgDuration,offset:'60' });
//   console.log(msgHandler)
// }

// Vue.prototype.msgInfo = function (msg) {
//   msgHandler=this.$message.info(msg);
// }
Vue.prototype.cookie=Cookies;

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.use(ELEMENT, {
  size: Cookies.get('size') || 'small' // set element-ui default size
})

import 'xe-utils'
import VXETable from 'vxe-table'
import VXEFilter from '@/utils/gridRenderSetup'
// import 'vxe-table/lib/style.css'

Vue.use(VXETable)

import VXETablePluginElement from 'vxe-table-plugin-element'
import 'vxe-table-plugin-element/dist/style.css'
VXETable.use(VXETablePluginElement)

import filters from '@/utils/gridrender';

Vue.use(filters);

Vue.config.productionTip = false



new Vue({
  el: '#app',
  router,
  store,
  components: {
    nodeWrap,
    addNode,
  },
  render: h => h(App)
})
