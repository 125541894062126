var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("treeselect", {
        attrs: {
          options: _vm.deptOptions,
          multiple: false,
          flat: false,
          "show-count": true,
          "default-expand-level": 1,
          "disable-branch-nodes": true,
          placeholder: "所属部门",
          size: "mini",
        },
        on: { select: _vm.selected },
        model: {
          value: _vm.option.data,
          callback: function ($$v) {
            _vm.$set(_vm.option, "data", $$v)
          },
          expression: "option.data",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }