// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n* I think element-ui's default theme color is too light for long-term use.\n* So I modified the default color and you can modify it to your liking.\n**/\n/* theme color */\n/* icon font path, required */", ""]);
// Exports
exports.locals = {
	"theme": "#3476F0"
};
module.exports = exports;
