var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "用户选择",
        visible: _vm.deptSelect.open,
        width: _vm.deptSelect.width,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.deptSelect, "open", $event)
        },
        close: _vm.onSelectClose,
      },
    },
    [
      _c("div", { staticClass: "fx_member_select" }, [
        _c("div", { staticClass: "select-menu" }, [
          _c("div", { staticClass: "select-btn select" }, [_vm._v("成员")]),
          _c(
            "div",
            {
              staticClass: "select-search-pane",
              style: { width: _vm.isFocus ? "100%" : "114px" },
            },
            [
              _c(
                "div",
                { staticStyle: { "margin-top": "6px" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        size: "mini",
                        type: "text",
                        placeholder: "搜索",
                      },
                      on: { focus: _vm.onFocusSearchTxt },
                      nativeOn: {
                        keyup: function ($event) {
                          return _vm.onChangeSearchTxt($event)
                        },
                        blur: function ($event) {
                          return _vm.onBlurSearchTxt($event)
                        },
                      },
                      model: {
                        value: _vm.memberParams.searchTxt,
                        callback: function ($$v) {
                          _vm.$set(_vm.memberParams, "searchTxt", $$v)
                        },
                        expression: "memberParams.searchTxt",
                      },
                    },
                    [
                      _vm.isFocus
                        ? _c("el-button", {
                            attrs: { slot: "append", icon: "el-icon-search" },
                            on: { click: _vm.onChangeSearchTxt },
                            slot: "append",
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "select-pane" }, [
          _c("div", { staticClass: "member-pane" }, [
            _vm.memberList && _vm.memberList.length > 0
              ? _c("div", { staticClass: "member-wrapper" }, [
                  _c(
                    "div",
                    {
                      staticClass: "select-member",
                      on: { scroll: _vm.memberListScroll },
                    },
                    [
                      _c(
                        "ul",
                        _vm._l(_vm.memberList, function (item, index) {
                          return _c(
                            "li",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.onRadioSelect(item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "user-name fl",
                                  class: { mobile: _vm.isMobile },
                                },
                                [
                                  !_vm.isMobile
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-flex",
                                              width: "120px",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            item.avatarUrl
                                              ? _c("span", {
                                                  staticClass:
                                                    "user-card-avatar",
                                                  style: {
                                                    backgroundImage:
                                                      "url(" +
                                                      item.avatarUrl +
                                                      "?x-oss-process=image/resize,m_fixed,h_100,w_100)",
                                                  },
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "user-card-avatar",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.name.substring(
                                                          0,
                                                          1
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "light",
                                                  placement: "top",
                                                  "open-delay": 300,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "编号：" +
                                                          _vm._s(item.number)
                                                      ),
                                                    ]),
                                                    item.agencyUserDeptNames &&
                                                    item.agencyUserDeptNames
                                                      .length > 0
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm._v(
                                                              "所属部门： "
                                                            ),
                                                            _vm._l(
                                                              item.agencyUserDeptNames,
                                                              function (
                                                                dpt,
                                                                dptIdx
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: dptIdx,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        dptIdx +
                                                                          1
                                                                      ) +
                                                                        "." +
                                                                        _vm._s(
                                                                          dpt
                                                                        )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "user-card-name",
                                                    staticStyle: {
                                                      width: "240px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "15px",
                                              width: "200px",
                                              display: "inline-block",
                                              overflow: "none",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "编号：" + _vm._s(item.number)
                                            ),
                                          ]
                                        ),
                                        item.agencyUserDeptNames &&
                                        item.agencyUserDeptNames.length > 0
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                  width: "200px",
                                                  display: "inline-block",
                                                  overflow: "none",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "部门：" +
                                                    _vm._s(
                                                      item
                                                        .agencyUserDeptNames[0]
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "inline-flex",
                                              width: "100%",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            item.avatarUrl
                                              ? _c("span", {
                                                  staticClass:
                                                    "user-card-avatar",
                                                  style: {
                                                    backgroundImage:
                                                      "url(" +
                                                      item.avatarUrl +
                                                      "?x-oss-process=image/resize,m_fixed,h_100,w_100)",
                                                  },
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "user-card-avatar",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.name.substring(
                                                          0,
                                                          1
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "light",
                                                  placement: "top",
                                                  "open-delay": 300,
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "content" },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "编号：" +
                                                          _vm._s(item.number)
                                                      ),
                                                    ]),
                                                    item.agencyUserDeptNames &&
                                                    item.agencyUserDeptNames
                                                      .length > 0
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm._v(
                                                              "所属部门： "
                                                            ),
                                                            _vm._l(
                                                              item.agencyUserDeptNames,
                                                              function (
                                                                dpt,
                                                                dptIdx
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: dptIdx,
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        dptIdx +
                                                                          1
                                                                      ) +
                                                                        "." +
                                                                        _vm._s(
                                                                          dpt
                                                                        )
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      height: "30px",
                                                      "line-height": "30px",
                                                      "margin-left": "15px",
                                                      width: "100px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "user-card-code" },
                                              [
                                                _vm._v(
                                                  " 编号：" +
                                                    _vm._s(item.number) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "user-card-dept" },
                                          [
                                            _vm._v(
                                              "部门：" +
                                                _vm._s(
                                                  item.agencyUserDeptNames &&
                                                    item.agencyUserDeptNames
                                                      .length > 0
                                                    ? item
                                                        .agencyUserDeptNames[0]
                                                    : "--"
                                                )
                                            ),
                                          ]
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "select-check fr",
                                  class: { select: _vm.isItemSelected(item) },
                                },
                                [_c("i", { staticClass: "icon-blank" })]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "member-wrapper" }, [
                  _c("span", { staticClass: "empty-tips" }, [
                    _vm._v(
                      _vm._s(
                        this.fieldOptions && this.fieldOptions.scopeType === ""
                          ? "请搜索用户信息"
                          : "没有可选成员"
                      )
                    ),
                  ]),
                ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "-20px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onSelectClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSelectConfirm } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }