var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hah" }, [
    _vm.$route.meta.flag
      ? _c(
          "div",
          { staticClass: "navbar app-inner-container" },
          [
            _c(
              "router-link",
              { staticClass: "left-menu", attrs: { to: "/myForm" } },
              [
                _vm.logoImg
                  ? _c("img", {
                      attrs: {
                        src: _vm.logoImg ? _vm.logoImg : _vm.logoImgDefault,
                      },
                    })
                  : _c("img", {
                      staticStyle: { height: "30px", "margin-top": "10px" },
                      attrs: {
                        src: _vm.logoImg ? _vm.logoImg : _vm.logoImgDefault,
                      },
                    }),
              ]
            ),
            _c("div", { staticClass: "center-menu" }, [
              _c("ul", { staticClass: "menu-ul" }, [
                _c(
                  "li",
                  { class: { active: _vm.$route.path === "/index" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/index" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.saveDefaultRouer("/index")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "agencyHomeWhite" },
                        }),
                        _c("span", [_vm._v("机构首页")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  { class: { active: _vm.$route.path === "/myForm" } },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/myForm" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.saveDefaultRouer("/myForm")
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "navbarMyForm" },
                        }),
                        _c("span", [_vm._v("个人首页")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.userInfo.type == "2" || _vm.userInfo.type == "3"
                  ? _c(
                      "li",
                      {
                        class: { active: _vm.$route.path === "/mechan/index" },
                      },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/mechan/index" } },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "navbarAgencyManage" },
                            }),
                            _c("span", [_vm._v("机构管理")]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("title-user-menu"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }