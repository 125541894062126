<template>
<div class="fill-card-item-wrapper" style="padding-right:5px;">
  <!-- isActive 当前表已选择 -->
  <filleCard :fields="fields" :isActive="isActive" :cardData="source" :maxCount="maxCount" :checkMaxSelected="checkMaxSelected" :selectedMark="selectedMark" :selectItem="select" :isSelected="isSelected" :viewItemHandler="viewItemHandler" :selectItemHandler="selectItemHandler"></filleCard>
  </div>
</template>
<script>
import filleCard from './fill-select-card.vue'
export default {
  components: { filleCard },
  name: 'fillSelectCardItem',
  componentName: 'fillSelectCardItem',
  props: {
    index: { // 每一行的索引
      type: Number
    },
    //source就是items中的每一条数据，items是从接口接到的数据（这里items是一个数组，数组中的每个元素是一个对象，每个对象有id、title两个属性）
    source: {
      type: Object,
      default: () => { }
    },
    maxCount:Number,
    checkMaxSelected:Function,
    selectItem:Function,
    fields:Array,
    isActive: Function,
    isSelected:Function,
    selectedCount:Function,
    viewItemHandler:Function,
    selectItemHandler:Function,
    selectedMark: Function
  },
  data() {
    return {
      select:{}
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.fill-card-item-wrapper{
 .user-card-avatar {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 3px;
  display: inline-block;
  text-align: center;
  border: 1px solid #d3d3d3;
  border-radius: 50%;
  background-color: #5b2bf6;
  background-size: cover;
  background-position: center center;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
}
}
</style>
