<template>
  <div class="myVxeTableContainer">
    <el-row class="el-row-table-bar">
      <el-col :span="8">
        <!-- 筛选条件展示 -->
        <div v-if="filters.length>0||sorts.length>0">
          <el-tag style="margin-right: 5px;" size="mini" closable v-for="(filter,index) in filters.filter(x=>x.showValue!==undefined)" :key="index" disable-transitions @close="removeFilter(index)">
            <span class="filter-tag-content">
              <el-tooltip v-if="filter.title.length>5" class="item" effect="light" :content="filter.title" placement="top-start">
                <span class="filter-tag-content-label">{{filter.title}}</span>
              </el-tooltip>
              <span class="filter-tag-content-label" v-else>{{filter.title}}</span>
              : {{filter.showValue}}
            </span>
          </el-tag>
          <el-tag closable v-for="(sort,index) in sorts" size="mini" :key="'sort'+index" disable-transitions @close="removeSort(index)">
            <span class="filter-tag-content">
              <el-tooltip v-if="sort.label.length>5" class="item" effect="light" :content="sort.label" placement="top-start">
                <span class="filter-tag-content-label">{{sort.label}}</span>
              </el-tooltip>
              <span class="filter-tag-content-label" v-else>{{sort.label}}</span>
              ：{{sort.direction==1?'升序':'降序'}}
            </span>
          </el-tag>
        </div>
      </el-col>
      <el-col :span="16" style="text-align: right;" v-if="$slots.toolbar_search || $slots.toolbar_share ||$slots.toolbar_function||$slots.toolbar_button">

        <el-form :inline="true" size="mini" class="list-search" @submit.native.prevent="() => {}">
          <el-form-item v-if="$slots.toolbar_search">
            <slot name="toolbar_search">
            </slot>
          </el-form-item>
          <el-form-item v-if="$slots.toolbar_share">
            <slot name="toolbar_share">
            </slot>
          </el-form-item>
          <el-form-item>
            <el-tooltip class="item" effect="dark" content="列设置" placement="top-start">
            <vxe-toolbar v-if="customTableConfig.columnFilter" title="" custom ref="xToolbar1" size="mini" style="height: 28px;"></vxe-toolbar>
            </el-tooltip>
          </el-form-item>
          <el-form-item v-if="$slots.toolbar_function">
            <slot name="toolbar_function">
            </slot>
          </el-form-item>
          <el-form-item style="margin-right: 0px" v-if="$slots.toolbar_button">
            <slot name="toolbar_button">
            </slot>
          </el-form-item>
        </el-form>

        <!-- <slot name="toolbarContainer">
        </slot> -->
        <!-- <vxe-toolbar v-if="customTableConfig.columnFilter" ref="xToolbar1" custom style="height:35px;width:45px;display:inline-block;padding:0px;"></vxe-toolbar> -->
      </el-col>
    </el-row>

    <!-- </div> -->
    <div style="overflow-y:hidden;height:100%;margin-top:10px;">
      <vxe-table ref="xGrid" :id="tableName" class="mytable-scrollbar" :auto-resize="true" :loading="loading" border="inner" :loading-config="{icon: 'vxe-icon-indicator roll', text: '正在拼命加载中...'}" :columns="fixColumns" 
      v-bind="tableConfig" v-on="tableEvent" align="left"
                 :empty-text="emptyText" @filter-change="filterChangeEvent" @sort-change="sortChangeEvent">
        <!-- <vxe-column v-if="selectedMark!=null" width="170">
          <template #header="{$table,column}">
            填报记录情况
          </template>
          <template #default="{ row,rowIndex }">
            <span> {{selectedMark(row.fillId)?'个人填报中已选过此数据':'从未选择此数据'}}</span>
          </template>
        </vxe-column> -->
        <template v-for="(col,idx) in fixColumns">
          <vxe-column type="checkbox" v-bind="col" align="center" title="[序号]" v-if="col.type=='checkbox'" :key="idx">
            <template #header="{$table,column}">
              <template v-if="pageConfig">
                <div class="checkbox-header-select" style="width:100%;height:20px;overflow:hidden">
                  <template v-if="allowCheckAllData && allowCheckAllPagesData">
                    <el-dropdown ref="dpSelect" placement="bottom-start" trigger="hover" @command="dpSelectClick" style="float:right">
                      <span class="el-checkbox__inner" style="width: 16px;height: 16px;" @click="selectAllChanged" :class="[selectScopeCheckboxChecked?'checked':'']"></span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="allPageRows">选择本页记录</el-dropdown-item>
                        <el-dropdown-item command="allDataRows">选择全部记录</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-else>
                    <span class="el-checkbox__inner" style="width: 16px;height: 16px;" @click="selectAllToggleChange" :class="[selectScopeCheckboxChecked?'checked':'']"></span>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="checkbox-header-select" style="width:100%;height:20px;overflow:hidden">
                  <span class="el-checkbox__inner" style="width: 16px;height: 16px;" @click="selectAllToggleChange" :class="[selectScopeCheckboxChecked?'checked':'']"></span>
                  <!-- <el-dropdown ref="dpSelect" placement="bottom-start" trigger="hover" @command="dpSelectClick" style="float:right">

                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="allPageRows">选择本页记录</el-dropdown-item>
                      <el-dropdown-item command="allDataRows">选择全部记录</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                </div>
              </template>
            </template>
            <template #default="{ row, rowIndex }">
                <span class="checkbox-index" v-if="pageConfig">{{(pageConfig.currentPage-1)*pageConfig.pageSize+(rowIndex+1)}}</span>
                <span class="checkbox-index" v-else>{{rowIndex+1}}</span>
            </template>
          </vxe-column>
          <vxe-column v-else-if="!col.slots" v-bind="col" :filters="col.filters" :fixed="col.fixed" :filter-render="col.filterRender" :params="col" :key="col.field+idx">
            <!-- <vxe-column v-else-if="!col.slots" v-bind="col" :params="col" :key="idx" v-viewer="{inline: true}"> -->
            <template #header="{$table,column}">
              <span>{{ column.title }}</span>
              <!-- <span class="custom-sort" :class="{'is-order': column.order}" v-if="column.sortable" @click="headerCellClickEvent({$table:$table,column:column})">
                <i :class="[column.order  ? `el-icon-sort-${column.order==='asc' ? 'up' : 'down'}` : 'el-icon-sort']"></i>
              </span> -->
            </template>
            <template #toolbarContainer="{$table,column}">
              <span>{{ column.title }}</span>
              <!-- <span class="custom-sort" :class="{'is-order': column.order}" v-if="column.sortable" @click="headerCellClickEvent({$table:$table,column:column})">
                <i :class="[column.order  ? `el-icon-sort-${column.order==='asc' ? 'up' : 'down'}` : 'el-icon-sort']"></i>
              </span> -->
            </template>
          </vxe-column>
          <slot v-else :name="col.slots.default" :column="col" :params="col"></slot>
        </template>
      </vxe-table>
    </div>
    <!-- <el-button type="primary" size="mini" @click="selectTest">切换选择</el-button> -->
    <vxe-pager v-if="pageConfig" size="mini" align="right" :current-page.sync="pageConfig.currentPage" :page-sizes="pageConfig.pageSizes" :page-size.sync="pageConfig.pageSize" :total="pageConfig.total" @page-change="handleDataPageChange"> </vxe-pager>
    <!-- 富文本查看对话框 -->
    <el-dialog :title="richTitle" :visible.sync="showRichTextDialog" width="1000px" append-to-body>
      <div v-html="richText"></div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="showRichTextDialog=false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 子表单查看对话框 -->
    <table-subform ref="table_subform"></table-subform>
    <!-- 文件查看对话框 -->
    <el-dialog :title="filelistTitle" :visible.sync="isShowFileList" width="600px" append-to-body>
      <div class="fileListBox">
        <div class="fileListContainer">
          <a :href="item.url" target="_blank" v-for="(item,index) in fileList" :key="index">
            <div class="fileListView">
              {{item.name}}<span>{{countFileSize(item)}}</span>
            </div>
          </a>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="isShowFileList=false">关闭</el-button>
      </div>
    </el-dialog>
   <auditLogDialog ref="auditLogRef"></auditLogDialog>
  </div>
</template>
<script>
// import VueViewer from 'v-viewer'
import "viewerjs/dist/viewer.css";
import { api as viewerApi } from "v-viewer";
import gridConfig from "@/utils/grid.js";
import picViewer from "@/utils/imagePreview.js";
import { httpGetFormFieldV2, fillDetail, editFill, getWaterMarkFile, auditLog } from "@/api/system/form";
import moment from "moment";
import StatusCode from "../statusCode.vue";
import { deepClone } from '@/utils/deepClone.js'
import { changedValue, showPicturePreview } from "@/utils/formUtil";
import teacher from './../../../mechan/teacher.vue'
import tableSubform from './table_extend_subform.vue'
import auditLogDialog from './auditLog'
export default {
  name: "MyVxeTable",
  componentName: "MyVxeTable",
  components: { StatusCode, teacher, tableSubform, auditLogDialog },
  props: {
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableName:{
      type: String,
      default: 'default_table'
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pageConfig: {
      type: Object,
    },
    params: {
      type: Object,
    },
    customTableConfig: {
      type: Object,
      default: () => {
        return {
          columnFilter: true,
          showToolbar: true,
        };
      },
    },
    // 选中所有数据的委托
    selectAllRowsHandler: {
      type: Function,
    },
    selectedMark: {
      type: Function,
      default: null,
    },
    agencyId: {
      type: Number,
    },
    formJson: {
      type: Object,
    },
    formDetail: {
      type: Object
    },
    rowEditable: {
      type: Function,
      default: () => false
    },
    extendTableConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    allowCheckAllData: {
      type: Boolean,
      default: true
    },
    allowCheckAllPagesData:{
      // 允许跨页全选数据
      type: Boolean,
      default: true
    },
    emptyText:{
      type: String,
      default: '暂无数据'
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (value) {
        // 为了更新数据后选中，采用手动更新数据的方式。
        this.$refs.xGrid.reloadData(value).then(() => {
          if (this.isAllDataSelected) {
            this.checkAndSelectDataInCurrentPage();
          }
        });
      }
    },
    excludeIds: {
      deep: true,
      handler: function (value) {
        this.params.excludeIds = value;
      },
    },
  },
  computed: {
    // 修复字段超长不显示问题，限制仅显示50个
    fixColumns: function () {
      if (this.columns.length > 50) {
        let operator = this.columns[this.columns.length - 1];
        let newColumns = this.columns.splice(0, 50);
        newColumns.push(operator);

        this.$nextTick(()=>{
          this.$refs.xGrid&&this.$refs.xGrid.refreshColumn(newColumns)
        })
        return newColumns;
      } else {
        this.$nextTick(()=>{
          this.$refs.xGrid&&this.$refs.xGrid.refreshColumn(this.columns)
        })
        return this.columns;
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.$refs.xGrid && this.$refs.xToolbar1)
        this.$refs.xGrid.connect(this.$refs.xToolbar1)
      this.editStatus = false
    })
  },
  data () {
    return {
      tableConfig: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        showOverflow: true,
        loading: false,
        autoResize: true,
        height: "100%",
        highlightHoverRow: true,
        highlightCurrentRow: true,
        emptyText: "暂无数据",
        showHeaderOverflow: "tooltip",
        headerCellClassName: this.tableFillRowClassName,
        customConfig: { storage: true },
        checkboxConfig: {
          labelField: "",
          checkAll: false,
          highlight: true,
          trigger: "default",
          reserve: false,
          range: true,
          ...this.extendTableConfig.checkboxConfig
        },
        sortConfig: gridConfig.sortConfig,
        seqConfig: { seqMethod: this.tableSeqMethod },
        editConfig: { trigger: 'click', mode: 'cell', showIcon: false, activeMethod: this.beforEditMethodEvent },
        columnFilter: true,
        rowConfig: {
          keyField: "fillId",
          height: 40
        },
        imgPreviewVisible: true,
        imgPreviewPath: "",
        checkboxConfig: {
          trigger: 'row',
        },
        filterConfig:{remote:true},
        ...this.extendTableConfig
      },
      tableEvent: {
        "cell-click": this.cellFillClickEvent,
        "checkbox-all": this.selectAllEvent,
        "checkbox-change": this.selectChangeEvent,
        "header-cell-dblclick": this.headerCellClickEvent,
        "edit-closed": this.rowEditClosedEvent
      },
      pictureFileList: [],
      selectedRowKeys: [],
      selectionRows: [],
      showSubformDialog: false,

      clickRow: {},
      subformTitle: "数据详情",
      subColumns: [],
      subData: [],

      richTitle: "内容详情",
      richText: "",
      picturelistTitle: "",
      isShowPictureList: false,
      isShowFileList: false,
      isShowPictureViewer: false,
      showRichTextDialog: false,
      selectScopeCheckboxChecked: false,

      fileList: [],
      filelistTitle: "",
      rowTitleNameLeft: "",
      filters: [],
      sorts: [],
      excludeIds: [], //全选后又排除的数据id,
      editRowClone: {},
      editRowOrigin: {},
      editRowId: -1,
      editStatus: false,
      editDisableConfirm: false,
      selectAllToggleFlag: false,
      moment
    };
  },
  methods: {
    beforEditMethodEvent ({ row, rowIndex, column, columnIndex }) {
      //vxe-table 执行顺序的坑：
      //如果单元格编辑了，先触发edit-close事件再触发beforeEdit,
      //如果没有编辑先触发beforeEdit事件后触发edit-close
      //会导致editRowId错乱，只能在rowEditCloseEvent里延时执行，等待beforeEdit先执行把editRowId设置好再判断是否弹出提示框

      if (this.editRowId != -1 && this.editStatus) {
        if (this.editRowId != rowIndex) {
          this.editRowId = rowIndex
          this.$emit("setEditRowIndex", rowIndex);
          return false;
        } else {
          this.enableEditRow({ row, rowIndex });
          return true;
        }
      } else {
        return this.enableEditRow({ row, rowIndex });
      }
    },
    // 启动行的编辑
    enableEditRow ({ row, rowIndex }) {
      // 记录修改后的行数据
      row.editRowClone = deepClone(row);
      // row.editRow = this.editRowClone;
      //给beforEditMethodEvent用，判断当前是否有正则编辑的单元格，如果有，不允许编辑其他行
      row.setEditStatus = (val) => { this.editStatus = val; this.editRowId = rowIndex }
      //给rowEditCloseEvent 用，判断当前行是否修改过，如果修改过，提示是否提交更改
      row.editStatus = false;

      if (this.rowEditable({ row }) && !this.editStatus) {
        this.$emit("setEditRowIndex", rowIndex);
        return true;
      } else {
        return false;
      }
    },
    async saveEditFillClick ({ row, rowIndex }, disableConfirm = false) {
      this.editDisableConfirm = disableConfirm;
      setTimeout(() => {
        this.editDisableConfirm = false;
      }, 500)
      // 读取填报数据
      const { fillJson } = (await fillDetail(row.fillId, this.formDetail.formId)).data;

      // 复制填报数据，把更改字段加入进去
      const fillJsonEdit = deepClone(fillJson);

      // 获取到变更数据
      Object.keys(fillJsonEdit).forEach(key => {
        fillJsonEdit[key] = row[key]
      })

      const changed = changedValue(this.formJson, fillJson, fillJsonEdit);
      // 保存填报
      editFill({
        formId: this.formDetail.formId,
        fillId: row.fillId,
        fillJson: fillJsonEdit,
        fillChange: changed
      }).then((response) => {
        if (response.code == 200) {
          this.msgSuccess("修改成功");

        }
      })
        .catch((err) => {
          this.msgError("修改失败");
        });
      this.editStatus = true;
      this.$emit("setEditRowIndex", -1);
    },
    cancelEditFillClick ({ row, rowIndex }, disableConfirm = false) {
      this.editDisableConfirm = disableConfirm;
      setTimeout(() => {
        this.editDisableConfirm = false;
      }, 500)
      Object.keys(row).forEach(key => {
        if (key != 'editRowClone' && key != 'editStatus' && key != 'setEditStatus') {
          row[key] = row.editRowClone[key];
        }
      })

      this.$emit("setEditRowIndex", -1);
    },
    rowEditClosedEvent ({ row, rowIndex, column }) {
      //修改一下editRowId，为-1之外的值，因为-1 会引起beforeEditMethodEvent开启点击行可编辑
      //如果是点击表格内部失去焦点，beforeEditMethodEvent会执行，会把editRowId设置为点击行，这样可以判断出行index和editRowId是否一致
      //如果点击的是表格外部失去输入框焦点，beforeEditMethodEvent不会执行，editRowId还是-2，这样就会弹出提示框
      this.editRowId = -2;

      setTimeout(() => {
        if (row.editStatus && rowIndex !== this.editRowId && !this.editDisableConfirm) {
          this.$emit("setEditRowIndex", rowIndex);
          this.$confirm("是否提交更改？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: 'warning'
          }).then(() => {
            this.editStatus = false;
            this.$emit("setEditRowIndex", -1);
            this.saveEditFillClick({ row, rowIndex });
          }).catch(() => {
            this.editStatus = false;
            this.cancelEditFillClick({ row, rowIndex });
          });

          this.editStatus = false
        }

      }, 200)
    },
    selectTest () {
      this.$refs.xGrid.toggleCheckboxRow(this.data[0]);
    },
    handleDataPageChange (obj) {
      // 如果翻页不保存状态，每次页面变化，清空选中记录
      if (!this.tableConfig.checkboxConfig.reserve && !this.isAllDataSelected) {
        this.clearSelected();
      }

      // 如果标记选择了全部的数据，给本页数据打上勾
      if (this.isAllDataSelected) {
        this.checkAndSelectDataInCurrentPage();
      }

      this.$emit("page-change", obj);
    },
    filterClear () { },
    selectAllChanged () {
      //20230625 用户不知道选的是本页的还是全部的，因此取消默认选择本页数据，弹出下拉接口交给用户选择

      // if (!this.selectScopeCheckboxChecked) {
      //   this.dpSelectClick('allPageRows')
      //   this.selectScopeCheckboxChecked = true
      // } else {
      this.clearSelected();
      // }
      this.$forceUpdate();
    },
    selectAllToggleChange () {
      if (this.selectAllToggleFlag) {
        this.clearSelected();
        this.$forceUpdate();
        this.selectAllToggleFlag = false
      } else {
        this.dpSelectClick('allPageRows');
        this.selectAllToggleFlag = true
      }
    },
    clearSelected () {
      this.selectScopeCheckboxChecked = false;
      this.isAllDataSelected = false;
      this.params.isAllDataSelected = false;
      this.selectionRows.splice(0);
      this.selectedRowKeys.splice(0);
      this.excludeIds.splice(0);
      this.params.selectedRowKeys.splice(0);
      this.$refs.xGrid.clearCheckboxRow();
    },
    dpSelectClick (command) {
      this.excludeIds.splice(0);

      // allPageRows 选择本页记录
      if (command === "allPageRows") {
        this.isAllDataSelected = false;
        this.params.isAllDataSelected = false;

        // 如果不允许全选数据 并且数据没有选择过，就选择
        if (!this.allowCheckAllData) {
          if (!this.selectScopeCheckboxChecked) {
            this.selectAllPageRows();
            this.selectScopeCheckboxChecked = true;
          }
          else {
            this.clearSelected();
            this.selectScopeCheckboxChecked = false;
          }
        } else {
          this.selectAllPageRows();
          this.selectScopeCheckboxChecked = true; // 表头 div模拟的checkbox 是否选择标记          
        }
      } else {
        this.isAllDataSelected = true;
        this.params.isAllDataSelected = true;
        // 使用委托，获取所有的数据，并从中选择id和状态字段 (数据量大占内存大)
        if (this.selectAllRowsHandler) {
          this.selectAllDataRows();
        } else {
          // 使用全选标记
          this.selectedRowKeys.splice(0);
          this.params.selectedRowKeys.splice(0);
          this.checkAndSelectDataInCurrentPage();
        }
        this.selectScopeCheckboxChecked = true; // 表头 div模拟的checkbox 是否选择标记
      }
    },
    // 判断当前页数据哪些是在所有的选中的数据中
    checkAndSelectDataInCurrentPage () {
      const selectRows = [];

      if (!this.isAllDataSelected) {
        this.data.forEach((x) => {
          // 如果选中行不包括这一行
          if (
            this.selectedRowKeys.findIndex((t) => t.fillId == x.fillId) >= 0
          ) {
            selectRows.push(x);
          }
        });
      } else {
        this.data.forEach((x) => {
          // 也不在排除列表中，把他加入选中行
          if (this.excludeIds.findIndex((t) => t == x.fillId) < 0) {
            selectRows.push(x);
          }
        });
      }

      this.$refs.xGrid.setCheckboxRow(selectRows, true);
    },
    // 选择本页记录的方法
    selectAllPageRows () {
      // 待选择的数据
      let autoSelectedData = [...this.data.map((v) => this.mapValue(v))];

      this.selectedRowKeys = [];
      let overMax = false; //全选数量超出最大数量
      if (this.extendTableConfig.checkboxConfig && this.extendTableConfig.checkboxConfig.max) {
        autoSelectedData.forEach((x) => {
          if (this.selectedRowKeys.length >= this.extendTableConfig.checkboxConfig.max) {
            overMax = true
            return
          }
          this.selectedRowKeys.push(x);
        })
      }
      if (overMax) {
        this.$message.warning('最多只能选择' + this.extendTableConfig.checkboxConfig.max + ',条数据，超出部分未能选择');
        this.$refs.xGrid.setCheckboxRow(this.data.filter(x => this.selectedRowKeys.some(y => y.fillId == x.fillId)), true);
      } else {
        this.selectedRowKeys = autoSelectedData;
        this.$refs.xGrid.setAllCheckboxRow(true);
      }

      this.params.selectedRowKeys.splice(0);
      this.selectedRowKeys.forEach((x) => {
        this.params.selectedRowKeys.push({
          fillId: x.fillId,
          version: x.version,
          status: x.status,
          payStatus: x.payStatus
        });
      });

    },
    // 选择所有的数据
    selectAllDataRows () {
      // 使用代理，获取数据并全选
      this.$refs.xGrid.setCheckboxRow(this.data, true);

      this.selectAllRowsHandler(this.pageConfig.total, (rows) => {
        this.selectedRowKeys.splice(0);
        this.params.selectedRowKeys.splice(0);

        rows.forEach((row) => {
          this.selectedRowKeys.push(this.mapValue(row));
          this.params.selectedRowKeys.push(deepClone(this.mapValue(row)));
        });
      });

    },
    mapValue (v) {
      return {
        fillId: v.fillId,
        payStatus: v.payStatus,
        status: v.status,
        fillStatus: v.fillStatus,
        version: v.version,
      };
    },
    selectAllEvent ({ checked, records, reserves }) { },
    selectChangeEvent ({ checked, records, reserves, row, $rowIndex, column, columnIndex, $columnIndex, $event }) {
      const mapValue = this.mapValue;

      if (column.type === 'operate' || row.__disabled === true) {
        this.$refs.xGrid.setCheckboxRow([row], false)
        return false;
      }
      if (checked) {

        if (this.extendTableConfig.checkboxConfig && this.extendTableConfig.checkboxConfig.max) {
          if (records.length > this.extendTableConfig.checkboxConfig.max) {
            this.$message.error('最多只能选择' + this.extendTableConfig.checkboxConfig.max + '条数据');
            this.$refs.xGrid.setCheckboxRow([row], false)
            return;
          }
        }


        // 第一次选数据，还未进行翻页时
        if (reserves.length == 0) {
          this.selectedRowKeys = records.map(mapValue);
          // this.selectionRows = records
        } else {
          // id集合，翻页存在已选中的数据时,拼接新选中的数据
          this.selectedRowKeys = [
            ...reserves.map(mapValue),
            ...records.map(deepClone(mapValue)),
          ];
          // 数据集合，翻页存在已选中的数据时,拼接新选中的数据
          // this.selectionRows = [...reserves, ...records]
        }

        // 在使用全选标记后，把选中的数据从排除列表中删除
        if (this.isAllDataSelected) {
          const existIdx = this.excludeIds.findIndex((x) => x === row.fillId);
          if (existIdx > -1) {
            this.$delete(this.excludeIds, existIdx);
          }
        }

      } else {
        // 取消选中时
        const idIndex = this.selectedRowKeys.findIndex(
          (x) => x.fillId == row.fillId
        );
        if (idIndex > -1) {
          // 删除取消选中删除指定元素id
          this.$delete(this.selectedRowKeys, idIndex);
        }

        // 如果使用全选标记，把取消选择的项目加入排除列表
        if (this.params.isAllDataSelected) {
          this.excludeIds.push(row.fillId);
        }
      }
      this.params.selectedRowKeys.splice(0);
      this.params.selectedRowKeys = [...this.selectedRowKeys];
    },
    headerCellClickEvent: ({
      $table,
      column,
      triggerResizable,
      triggerSort,
      triggerFilter,
    }) => {
      // 如果触发了列的其他功能按钮
      if (
        column &&
        column.sortable &&
        !(triggerResizable || triggerSort || triggerFilter)
      ) {
        if (column.order === "desc") {
          $table.clearSort();
        } else if (column.order === "asc") {
          $table.sort(column.property, "desc");
        } else {
          $table.sort(column.property, "asc");
        }
      }
    },
    cellFillClickEvent ({ row, column }) {
      // 填报列表 图片、附件 列点击
      const property = column.property;
      const value = row[property];
      if (!this.isBlank(property)) {
      }
    },
    //表的序号
    tableSeqMethod ({ row, rowIndex }) {
      if (this.pageConfig) {
        return (
          (this.pageConfig.currentPage - 1) * this.pageConfig.pageSize +
          rowIndex +
          1
        );
      } else {
        return rowIndex;
      }
    },
    // 表行的背景
    tableFillRowClassName ({ row, column, rowIndex, columnIndex }) {
      if (
        column.title &&
        (column.title == "填报时间" ||
          column.title == "填报人编号" ||
          column.title == "填报人" ||
          column.title == "填报状态" ||
          column.title == "所属一级部门" ||
          column.title == "所属二级部门")
      ) {
        return "fontNormal";
      } else {
        return "fontWeight";
      }
    },
    showRichEdiorViewer (field, richText) {
      const columnConfig = this.fixColumns.find((x) => x.field == field);
      this.richTitle = `【${columnConfig.title}】内容详情`;

      this.richText = richText;
      this.showRichTextDialog = true;
    },
    showSubform (field, subformData) {
      const columnConfig = this.fixColumns.find((x) => x.field == field);
      // this.$nextTick(() => {
       
      // });
      // this.showSubformDialog = true;
      this.$refs.table_subform.showSubformDialog({column: columnConfig.params, subformData, rowTitleNameLeft: this.rowTitleNameLeft})
      this.$forceUpdate();
    },
    showPictureView (field, file, fileList, index) {
      showPicturePreview(file, fileList);

      // if (file.mediaType && file.mediaType.indexOf('video') >= 0) {
      //   let base64FileName = btoa(file.url);
      //   let url = `${process.env.VUE_APP_BASE_FILE_PREVIEW}/onlinePreview?url=${encodeURIComponent(base64FileName)}`;
      //   window.open(url);
      // } else {
      //   const viewer = new picViewer();
      //   viewer.show(
      //     fileList.map((x) => {
      //       return { url: x.url, src: x.thumb };
      //     }),
      //     index
      //   );
      // }
    },
    showSignView (field, file, fileList, index) {
      const viewer = new picViewer();
      viewer.showSign(
        fileList.map((x) => {
          return { url: x, src: x };
        }),
        0
      );
    },
    showSignRemark (row) {
      this.clickRow = row;
      this.$refs.auditLogRef.show(this.formDetail.formId,row.fillId,row)
    },
    showPictureList (field, file, fileList, index) {
      const viewer = new picViewer();
      viewer.show(
        fileList.map((x) => {
          return { url: x.url, src: x.thumb };
        }),
        0
      );
    },
    inited (viewer) {
      this.$viewer = viewer;
    },
    showFileList (field, file, fileList) {
      // console.log('hello')
      const columnConfig = this.fixColumns.find((x) => x.field == field);
      this.filelistTitle = `【${columnConfig.title}】 全部文件`;
      this.fileList = fileList;
      this.isShowFileList = true;
    },
    showFileView (url) {
      window.open(url);
    },
    showFillView (selectRowData, selectItem, i, showFields) {
      if (this.$parent.$refs.dlgvFormViewer) {
        // 同时加载，提升加载速度
        const loadField = new Promise(async (resolve, reject) => {
          const res = await httpGetFormFieldV2(selectRowData.formId);
          resolve(res.data);
        });
        const loadData = new Promise(async (resolve, reject) => {
          const res = await fillDetail(selectItem.fillId, selectRowData.formId);
          resolve(res.data.fillJson);
        });

        let self = this;
        Promise.all([loadField, loadData]).then((values) => {
          this.$parent.$refs.dlgvFormViewer.showView({
            fillId: selectItem.fillId,
            formId: selectRowData.formId,
            formJson: values[0],
            formData: values[1],
            showHiddenWidgets: this.$parent.cur != 3,
            showFields: showFields,
            enableReadonly: true,
            agencyId: this.agencyId,
            formDetail: this.formJson,
            callback: () => {
              self.$nextTick(() => {
                self.$parent.$refs.dlgvFormViewer.disableForm();
              })
            }
          });
        });
      } else {
        this.$message.warning("未找到关联数据查看组件");
      }
    },
    countFileSize (countFile) {
      let i = 0;
      if (!countFile.size) return "";
      let size = countFile.size;
      const sizeName = ["B", "KB", "MB", "GB"];
      while (size > 1024) {
        size = size / 1024;
        i++;
      }
      return size.toFixed(2) + sizeName[i];
    },
    //区分是表单字段还是系统字段
    filterChangeEvent (event, checked, option) {
      const newFilterList = this.filterData(event.filterList);
      // this.filters.splice(0)
      console.log(newFilterList, event.filterList)
      newFilterList.forEach(x => {
        if (this.filters.some(y => y.field === x.field)) {
          this.filters.splice(this.filters.findIndex(y => y.field === x.field), 1,x)
        }else{
          this.filters.push(x)
        }
      })
      this.pageConfig.currentPage=1;
      // this.filters.push.apply(this.filters, newFilterList)
      this.$emit("filter-change", this.filters);
      this.$emit("clear-global-search")
    },
    sortChangeEvent ({ $table, column }) {
      // if (column.order === "desc") {
      //   let idx = this.sorts.findIndex((x) => x.field == column.property);
      //   if (idx >= 0) {
      //     this.sorts[idx].direction = 1;
      //   } else {
      //     this.sorts.push({
      //       field: column.property,
      //       direction: 1,
      //       label: column.title,
      //     });
      //   }

      //   $table.sort(column.property, "asc");
      // } else if (column.order === "asc") {
      //   let idx = this.sorts.findIndex((x) => x.field == column.property);
      //   this.sorts.splice(idx, 1);

      //   $table.sort(column.property, null);
      // } else {
      //   // $table.clearSort();

      //   let idx = this.sorts.findIndex((x) => x.field == column.property);
      //   if (idx >= 0) {
      //     this.sorts[idx].direction = -1;
      //   } else {

      let direction = null;
      this.sorts.splice(0);

      if (column.order === "desc") {
        direction = -1
        this.sorts.push({
          field: column.property,
          direction,
          label: column.title,
          fieldType: column.params.isSysField ? 'sysField' : 'formField'
        });
      } else if (column.order === "asc") {
        direction = 1;
        this.sorts.push({
          field: column.property,
          direction,
          label: column.title,
          fieldType: column.params.isSysField ? 'sysField' : 'formField'
        });
      }

      // }

      // $table.sort(column.property, "desc");
      // }

      this.$emit("sort-change", this.sorts);
    },
    removeFilter (index) {
      let field = this.filters[index].field;
      this.$refs.xGrid.clearFilter(this.$refs.xGrid.getColumnByField(field));
      this.filters.splice(index, 1);

      this.$emit("filter-change", this.filters);
    },
    clearFilters (quite = false) {
      this.filters.splice(0);
      if (!quite) this.$emit("filter-change", this.filters);
    },
    clearFiltersAndSearch ({quite=false, runQuery= true}) {
      this.filters.splice(0);
      if (!quite) this.$emit("filter-change", this.filters, runQuery);
      if (!quite) this.$emit("clearSearch");
    },
    setFilter (fieldName, fieldValue) {
      if (!fieldName) return;
      const xTable = this.$refs.xGrid;
      const column = xTable.getColumnByField(fieldName);

      var option = column.filters[0];
      option.data = fieldValue;
      option.checked = true;

      xTable.updateData();
    },
    setSort ({ field, direction }) {
      const xTable = this.$refs.xGrid;
      if (direction == 1) xTable.sort(field, "asc");
      else if (direction == -1) xTable.sort(field, "desc");
      else xTable.clearSort();
    },
    removeSort (index) {
      let removeItem = this.sorts.splice(index, 1);
      const xTable = this.$refs.xGrid;
      xTable.sort(removeItem[0].field, "null");
    },
    clearFilter () {
      this.filters.splice(0);
      this.$refs.xGrid.clearFilter();
      this.$emit("filter-change", this.filters);
    },
    clearSort (disableRefresh = false) {
      this.sorts.splice(0);
      this.$refs.xGrid.clearSort();
      if (!disableRefresh) {
        this.$emit("sort-change", this.sorts);
      }
    },

    filterData (filters) {
      let filterdata = [];

      filters.forEach((filter) => {
        filterdata.push({
          title: filter.column.title,
          field: filter.column.field,
          sysField: !!filter.column.params.isSysField,
          filterType: filter.column.params.fieldType,
          showValue: this.filterShowValueBuilder(
            filter.column.params.fieldType,
            filter.datas[0],
            filter.column.params.optionsItem
          ),
          value: this.filterValueBuilder(
            filter.column.params.fieldType,
            filter.datas[0]
          ),
        });
      });
      return filterdata;
    },
    filterValueBuilder (filterType, filterData, filterOptionsItem) {
      if (filterType == "select" && !Array.isArray(filterData)) {
        return [filterData];
      } else if (filterType == "rate" || filterType == "number") {
        if (!filterData.find) filterData.find = 0;
        if (!filterData.from) filterData.from = 0;
        if (!filterData.to) filterData.to = 0;
        return filterData;
      } else {
        return filterData;
      }
    },
    filterShowValueBuilder (filterType, filterData, filterOptionsItem) {
      let stringHandler = () => {
        return filterData;
      };
      let arrayHandler = () => {
        return filterData.toString(",");
      };
      let selectSingleHandler = () => {
        if(!filterData) return "";
        let item = filterOptionsItem.find((x) => x.value == filterData);
        return item.label;
      };
      let selectStatusHandler = () => {
        let item = filterOptionsItem.find((x) => x.value == filterData);
        return item.label;
      };
      let selectMuiltiHandler = () => {
        let labels = [];
        filterData.forEach((itemData) => {
          if (itemData == '') {
            labels.push('');
            return;
          } else {
            let item = filterOptionsItem.find((x) => x.value == itemData);
            labels.push(item.label);
          }
        });
        return labels.join(",");
      };
      let selectCascaderHandler = () => {
        let labels = [];

        let level = filterOptionsItem;
        filterData.forEach((itemData) => {
          let levelItem = level.find((x) => x.value == itemData);
          level = levelItem.children;
          labels.push(levelItem.label);
        });
        return labels.join(",");
      };
      let numberRangeHandler = () => {
        let x = [];
        let y = [];
        if (filterData.find) y.push(`等于${filterData.find}`);

        if (filterData.from != null && filterData.from != filterData.to)
          x.push(`大于${filterData.from}`);
        if (filterData.to != null && filterData.from != filterData.to)
          x.push(`小于${filterData.to}`);

        if (x.length == 1) {
          y.push(x.join(" 且 "));
        } else if (x.length == 2) {
          if (y.length == 0) y.push(x.join(" 且 "));
          else y.push("(" + x.join(" 且 ") + ")");
        }

        return y.join(" 或 ");
      };

      let fType = {
        input: stringHandler,
        textarea: stringHandler,
        number: numberRangeHandler,
        radio: selectMuiltiHandler,
        checkbox: selectMuiltiHandler,
        sysStatus: selectStatusHandler,
        payStatus: selectMuiltiHandler,
        select: () => {
          if (Array.isArray(filterData)) {
            return selectMuiltiHandler();
          } else {
            return selectSingleHandler();
          }
        },
        date: () => {
          return `${moment(filterData[0]).format("YYYY-MM-DD")}~${moment(
            filterData[1]
          ).format("YYYY-MM-DD")}`;
        },
        createTime: () => {
          return `${moment(filterData[0]).format("YYYY-MM-DD")}~${moment(
            filterData[1]
          ).format("YYYY-MM-DD")}`;
        },
        updateTime: () => {
          return `${moment(filterData[0]).format("YYYY-MM-DD")}~${moment(
            filterData[1]
          ).format("YYYY-MM-DD")}`;
        },
        rate: () => {
          if (typeof filterData === "object") {
            return numberRangeHandler();
          } else {
            return stringHandler();
          }
        },
        name: stringHandler,
        mail: stringHandler,
        mobile: stringHandler,
        idcard: stringHandler,
        region: arrayHandler,
        address: arrayHandler,
        nationality: arrayHandler,
        'user-select': stringHandler,
        'fillUser': stringHandler,
        'sign': () => {
          return filterData[0] == "1" ? '已签' : '未签'
        },
        'picture-upload': () => {
          return filterData[0] == "1" ? '已上传' : '未上传'
        },
        'file-upload': () => {
          return filterData[0] == "1" ? '已上传' : '未上传'
        },
        cascader: selectCascaderHandler,
        sysDept: () => {
          return filterOptionsItem.label;
        },
      };

      if (fType[filterType]) return fType[filterType]();
    },
    //vxe-table 设置选中
    setSelection (selection, trueOrFalse) {
      this.selectedRowKeys = selection;

       this.selectedRowKeys.forEach((x) => {
        this.params.selectedRowKeys.push({
          fillId: x.fillId,
          version: x.version,
          status: x.status,
          payStatus: x.payStatus
        });
      });
      this.$nextTick(()=>{
        // selection.forEach(x=>{
        //   x.__disabled = false;
        // })
        this.data.find(x=>{ 
          if(selection.find(y=>y.fillId == x.fillId)){
            x.__disabled = false;
          }
        })
        this.$nextTick(()=>{
          this.$refs.xGrid.setCheckboxRow(selection, trueOrFalse);
        })

        //  this.$refs.xGrid.setCheckboxRow([this.data[0].fillId], trueOrFalse);
      })
    },
    setRowStyle (style) {
      Object.keys(style).forEach(key => {
        let styleName = key;
        let styleValue = style[key];
        this.$set(this.tableConfig.rowConfig, styleName, styleValue)
      })
    },
    async getWaterMark (fileName, fieldId, watermark, ossAddress) {
      return getWaterMarkFile({
        fileName: fileName,
        agencyId: this.agencyId,
        fieldId, watermark,
        formId: this.formDetail.formId,
        ossAddress,
      })
    },
    getNativeTable(){
      return this.$refs.xGrid
    }
  },
};
</script>
<style lang="scss" scoped>
@import url('~@/assets/styles/filetype.css');
.myVxeTableContainer {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  width:100%;
  // ::v-deep [class*='vxe-'] {
  //   font-size: 12px;
  // }
  ::v-deep .checkbox-header-select .el-select .el-input__inner {
    background-color: transparent;
    border: none;
    color: black;
    &:before {
      content: '请选择';
      color: red;
    }
  }
  ::v-deep .vxe-pager {
    height: 40px;
    // line-height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-end;
    justify-self: flex-end;
  }

  ::v-deep .el-row-table-bar {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
::v-deep .vxe-table--fixed-right-wrapper .clearExpandTemplate {
  // display:none;
  // padding-left:1000px;
  // overflow: hidden;
  opacity: 0;
}

::v-deep .checkbox-index {
  position: absolute;
  left: 0px;
  display: inline-block;
  width: 100%;
  text-align: center;
  display: inline-block;
}

::v-deep .vxe-cell--checkbox {
  padding-left: 10px;
}

::v-deep
  .vxe-table--render-default
  .is--checked.vxe-cell--checkbox
  .vxe-checkbox--icon {
  color: #3476f0;
}
::v-deep
  .vxe-table--render-default
  .is--checked.vxe-cell--checkbox
  .vxe-checkbox--icon:before {
  border-color: #3476f0;
  background-color: #3476f0;
}
::v-deep .vxe-checkbox--icon {
  top: 0.7em;
}
::v-deep .vxe-table--render-default .vxe-cell--checkbox .vxe-checkbox--icon {
  color: transparent;
}

::v-deep .vxe-cell.c--tooltip {
  .vxe-checkbox--icon.vxe-checkbox--indeterminate-icon {
    display: none;
  }

  .is--checked {
    .vxe-checkbox--icon.vxe-checkbox--checked-icon {
      display: inline-block !important;
    }
    .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
      display: none !important;
    }
    .checkbox-index {
      display: none;
    }
  }

  .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
    display: none;
  }
}

::v-deep .vxe-body--row.row--hover {
  &:hover {
    .checkbox-index {
      display: none;
    }

    .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
      display: inline-block;
    }
  }
}

::v-deep .picture-view {
  height: 25px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  border: solid 1px transparent;
  border: solid 1px #e8e8e8;

  box-sizing: border-box;
  vertical-align: middle;
  &:hover {
    border: solid 1px #c5c5c5;
  }
  &.more {
    border: solid 1px #e8e8e8;
    &:hover {
      background-color: #c5c5c5;
      color: white;
    }
  }
}

::v-deep .audit-sign-view {
  height: 32px;
  line-height: 32px;
  width: 60px;
  display: inline-block;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
  border: solid 1px #e8e8e8;
    background-repeat: no-repeat;
    background-color: white;

  box-sizing: border-box;
  vertical-align: middle;
  &:hover {
    border: solid 1px #c5c5c5;
  }
  &.more {
    border: solid 1px #e8e8e8;
    &:hover {
      background-color: #c5c5c5;
      color: white;
    }
  }
}

::v-deep .pictureListBox {
  width: 950px;
  height: 500px;
  display: inline-block;
  box-sizing: border-box;
}

::v-deep .pictureListContainer {
  margin-left: -5px;
  margin-right: -5px;
  box-sizing: border-box;
  text-align: left;
  overflow-y: auto;
}
::v-deep .pictureListView {
  width: 128px;
  height: 128px;
  text-align: center;
  line-height: 128px;
  display: inline-block;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  margin: 5px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  &:hover {
    border: solid 1px #409eff;
  }
}

::v-deep .fileListView {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: solid 1px #e8e8e8;
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: -1px;
  position: relative;
  z-index: 100;
  &:hover {
    color: #3476f0;
    border: solid 1px #3476f0;
    z-index: 101;
  }
  &:last-of-type {
    border-bottom: solid 1px #e8e8e8;
    &:hover {
      color: #3476f0;
      border: solid 1px #3476f0;
    }
  }
  a {
    text-decoration: none;
  }
}

::v-deep .cell-link-view {
  cursor: pointer;
  color: #3476f0;

  // display: inline-block;
  height: 24px;
  width: 25px;
  display: inline-block;
  // margin-top: 2px;
  // border: solid 1px transparent;
  background-size: cover;
  background-position: center center;
  // border-radius: 2px;
  // &:hover {
  //   text-decoration: underline;
  //   border: solid 1px #c5c5c5;
  // }
}
::v-deep .el-checkbox__inner {
  cursor: pointer;
}
::v-deep .el-checkbox__inner.checked {
  border: solid 1px #3476f0;
  background-color: #3476f0;
  color: white;
  line-height: 16px;

  &::before {
    content: '';
    position: absolute;
    height: 0.7em;
    width: 0.32em;
    top: 50%;
    left: 50%;
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

::v-deep .cursor {
  cursor: pointer;
}

::v-deep .cell-link-view {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-top: 5px;
  .svg-icon {
    font-size: 16px;
  }
}
::v-deep .user-card-wrpper {
  display: inline-flex;
  align-items: center;
  .user-bind {
    font-size: 12px;
    margin-right: 10px;
  }
}
::v-deep .user-card {
  display: inline-flex;
  align-items: center;
  border-radius: 9px;
  // padding-right: 8px;
  // margin-right: 4px;
  font-size: 12px;
  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;

  &-avatar {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 3px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: #3476f0;
    background-size: cover;
    background-position: center center;
    color: white;
    position: relative;
  }
}

::v-deep .sysfield {
  color: #3476f0 !important;
}

::v-deep .sysfield-r {
  color: #f56c6c !important;
}
::v-deep .sysfield-y {
  color: #f09f35 !important;
}
::v-deep .sysfield-g {
  color: #67c23a !important;
}

::v-deep .cell-dept {
  .cell-dept-item {
    margin-right: 5px;
    &:nth-last-of-type() {
      margin-right: 0px;
    }
  }
}
</style>
<style lang="scss">
.file-info {
  box-sizing: border-box;
  &-thumb {
    width: 150px;
    position: relative;
  }
  &-name {
    min-width: 150px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  &-extra {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  &-size {
    display: inline-block;
  }
  &-actions {
    display: inline-block;

    .svg-icon {
      padding: 0px;
      font-size: 20px;
      color: #9e9e9e;
      border-radius: 4px;
      cursor: pointer;
      border: solid 1px transparent;

      &:hover {
        color: #3476f0;
        border: solid 1px #e5e5e5;
        background-color: #eeeeee;
      }
    }
  }
}

::v-deep .viewer-container .viewer-list {
  width: 80% !important;
}

/* 筛选图标鼠标移上图片才出现 */

.vxe-table--render-default .vxe-cell {
  padding: 0px 5px;
}
.vxe-table--render-default .vxe-body--column:not(.col--ellipsis) {
  padding: 0px 5px;
}
.vxe-cell--title span {
  display: inline-block;
  vertical-align: middle;
}
// .custom-sort.is-order {
//   // height:22px;
//   width: 20px;
//   overflow: hidden;
// }
// .vxe-cell--filter {
//   padding-top: 2px;
// }
// .vxe-cell--filter .vxe-filter--btn,
// .custom-sort .iconfont-jyd.jyd-icon-paixu {
//   color: #d3d3d3;
//   display: inline-block;
//   // width: 20px;
//   // height:20px;
// }

// .custom-sort.is-order,
// .iconfont-jyd.jyd-icon-sort-descending,
// .iconfont-jyd.jyd-icon-sort-ascending {
//   color: #d3d3d3;
// }

.vxe-table--render-default.border--default .vxe-header--column:hover {
  background-color: #e9edf0;
}

.vxe-header--column:hover {
  .vxe-cell--filter .vxe-filter--btn {
    color: #888888;
    cursor: pointer;
  }
  .iconfont-jyd {
    color: #888888;
    cursor: pointer;
  }
}

.vxe-table--body-wrapper.body--wrapper {
  // height:auto!important;
}

.toolbar-wrapper {
  display: flex;
  height: 30px;

  justify-content: space-between;
  // justify-content: flex-start;
  align-items: center;
  margin-top: 16px;

  .search-wrapper {
    display: inline-flex;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
  }
  .filter-wrapper {
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding-right: 10px;

    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 1;
    // margin-bottom: 2px;
    padding-bottom: 0px;

    &-group {
      display: inline-flex;
      max-width: 50%;
    }

    &-title {
      width: 45px;
      text-align: center;
      flex-shrink: 0;
      display: inline-block;
    }
    &-container {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      display: inline-block;

      &::-webkit-scrollbar {
        /*高宽分别对应横竖滚动条的尺寸*/
        width: 10px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #f0f0f0;
        &:hover {
          background: #a8a8a8;
        }
      }
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        background: #fafafa;
      }
    }
  }

  .filter-tag {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    user-select: none;

    // &:last-of-type {
    //   margin-right: 0px;
    // }

    &-content {
      margin-right: 5px;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;

      &-label {
        display: inline-block;
        float: left;
        max-width: 85px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .filter-tag .filter-tag-content + i {
    margin-top: 3px;
    line-height: 17px;
  }
}

.audit-view-remark {
  line-height: 25px;
}
.audit-view-sign {
  padding: 10px 0px;
}

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  // background-color: #ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.vxe-table--render-default .vxe-header--column.col--ellipsis {
  height: 36px;
  background-color: #f2f5f7;
}

.editable-input {
  height: 30px;
  line-height: 30px;
  padding: 0px 5px;
  border: 1px solid #d3d3d3;
  outline: none;
  font-size: 14px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}
.signResult-view-item {
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 10px;
}
.signResult-view-item-title {
  font-weight: bold;
  line-height: 30px;
}
</style>
