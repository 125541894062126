<template>
    <div class="my-filter-input">
        <div class="filter-data">
            <deptTree :option="option" @selected="deptSelected"></deptTree>
        </div>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
import deptTree from '@/components/DeptTree'
export default {
    name: 'FilterDept',
    components:{
        deptTree
    },
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
            selectDept:{}
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;
            $panel.changeOption(null, checked, option);
        },
        clearFilter(){
            this.option.data="";
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { params, option } = this;
            const { $panel } = this.params;
            const checked = !!option.data;

            
            option.label=this.selectDept.label;
            
            params.column.params.optionsItem=this.selectDept;
            $panel.changeOption({optionsItem:[]}, checked, option);
            $panel.confirmFilter();
        },
        deptSelected(node){ 
            this.selectDept=node;
        }
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
    max-width:240px;
}
</style>