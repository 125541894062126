var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { cursor: "default" } },
    [
      _vm.statusCode == "1"
        ? _c(
            "el-tag",
            { attrs: { type: "success", round: "", size: "mini" } },
            [_vm._v("无需审核")]
          )
        : _vm._e(),
      _vm.statusCode == "2"
        ? [
            _vm.row
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "待【" +
                          _vm._s(
                            (_vm.row.auditAuth1AgencyUserList || [])
                              .map(function (x) {
                                return x.name
                              })
                              .join(",")
                          ) +
                          "】审核"
                      ),
                    ]),
                    _c("el-tag", { attrs: { round: "", size: "mini" } }, [
                      _vm._v("待一级审核"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "el-tag",
                  { attrs: { type: "primary", round: "", size: "mini" } },
                  [_vm._v("待一级审核")]
                ),
          ]
        : _vm._e(),
      _vm.statusCode == "3" || _vm.statusCode == "4" || _vm.statusCode == "5"
        ? [
            _vm.row && _vm.row.auditAuth1Operator
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "审核人：【" +
                          _vm._s(_vm.row.auditAuth1Operator.name) +
                          "】"
                      ),
                    ]),
                    _vm.statusCode == "3"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "success", round: "", size: "mini" },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "4"
                      ? _c(
                          "el-tag",
                          { attrs: { type: "info", round: "", size: "mini" } },
                          [_vm._v("一级审核不通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "5"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "warning", round: "", size: "mini" },
                          },
                          [_vm._v("一级审核返回修改")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : [
                  _vm.statusCode == "3"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", round: "", size: "mini" } },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "4"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "info", round: "", size: "mini" } },
                        [_vm._v("一级审核不通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "5"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "warning", round: "", size: "mini" } },
                        [_vm._v("一级审核返回修改")]
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
      _vm.statusCode == "6"
        ? [
            _vm.row && _vm.row.auditAuth2AgencyUserList
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "待【" +
                          _vm._s(
                            (_vm.row.auditAuth2AgencyUserList || [])
                              .map(function (x) {
                                return x.name
                              })
                              .join(",")
                          ) +
                          "】审核"
                      ),
                    ]),
                    _c("el-tag", { attrs: { round: "", size: "mini" } }, [
                      _vm._v("待二级审核"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "el-tag",
                  { attrs: { type: "primary", round: "", size: "mini" } },
                  [_vm._v("待二级审核")]
                ),
          ]
        : _vm._e(),
      _vm.statusCode == "7" || _vm.statusCode == "8" || _vm.statusCode == "9"
        ? [
            _vm.row && _vm.row.auditAuth2Operator
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "审核人：【" +
                          _vm._s(_vm.row.auditAuth2Operator.name) +
                          "】"
                      ),
                    ]),
                    _vm.statusCode == "7"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "success", round: "", size: "mini" },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "8"
                      ? _c(
                          "el-tag",
                          { attrs: { type: "info", round: "", size: "mini" } },
                          [_vm._v("二级审核不通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "9"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "warning", round: "", size: "mini" },
                          },
                          [_vm._v("二级审核返回修改")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : [
                  _vm.statusCode == "7"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", round: "", size: "mini" } },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "8"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "info", round: "", size: "mini" } },
                        [_vm._v("二级审核不通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "9"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "warning", round: "", size: "mini" } },
                        [_vm._v("二级审核返回修改")]
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
      _vm.statusCode == "10"
        ? [
            _vm.row && _vm.row.auditAuth3AgencyUserList
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "待【" +
                          _vm._s(
                            (_vm.row.auditAuth3AgencyUserList || [])
                              .map(function (x) {
                                return x.name
                              })
                              .join(",")
                          ) +
                          "】审核"
                      ),
                    ]),
                    _c("el-tag", { attrs: { round: "", size: "mini" } }, [
                      _vm._v("待三级审核"),
                    ]),
                  ],
                  1
                )
              : _c(
                  "el-tag",
                  { attrs: { type: "primary", round: "", size: "mini" } },
                  [_vm._v("待三级审核")]
                ),
          ]
        : _vm._e(),
      _vm.statusCode == "11" || _vm.statusCode == "12" || _vm.statusCode == "13"
        ? [
            _vm.row && _vm.row.auditAuth3Operator
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "审核人：【" +
                          _vm._s(_vm.row.auditAuth3Operator.name) +
                          "】"
                      ),
                    ]),
                    _vm.statusCode == "11"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "success", round: "", size: "mini" },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "12"
                      ? _c(
                          "el-tag",
                          { attrs: { type: "info", round: "", size: "mini" } },
                          [_vm._v("三级审核不通过")]
                        )
                      : _vm._e(),
                    _vm.statusCode == "13"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "warning", round: "", size: "mini" },
                          },
                          [_vm._v("三级审核返回修改")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : [
                  _vm.statusCode == "11"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", round: "", size: "mini" } },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "12"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "info", round: "", size: "mini" } },
                        [_vm._v("三级审核不通过")]
                      )
                    : _vm._e(),
                  _vm.statusCode == "13"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "warning", round: "", size: "mini" } },
                        [_vm._v("三级审核返回修改")]
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
      _vm.statusCode == "14"
        ? [
            _vm.row &&
            (_vm.row.auditAuth3Operator ||
              _vm.row.auditAuth2Operator ||
              _vm.row.auditAuth1Operator)
              ? _c(
                  "el-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        "审核人：【" +
                          _vm._s(
                            (
                              _vm.row.auditAuth3Operator ||
                              _vm.row.auditAuth2Operator ||
                              _vm.row.auditAuth1Operator
                            ).name
                          ) +
                          "】"
                      ),
                    ]),
                    _vm.statusCode == "14"
                      ? _c(
                          "el-tag",
                          {
                            attrs: { type: "success", round: "", size: "mini" },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : [
                  _vm.statusCode == "14"
                    ? _c(
                        "el-tag",
                        { attrs: { type: "success", round: "", size: "mini" } },
                        [_vm._v("审核通过")]
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }