export const FilterHeaderCheckedRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterString params={params}></FilterString>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }

}

export const FilterStringRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterString params={params}></FilterString>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({options,column}) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    // return true;
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }

}

export const FilterNumberRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterNumber params={params}></FilterNumber>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = {find:null,from:null,to:null};
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = {find:null,from:null,to:null};
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const { find,from,to}=data;
    const cellValue = row[column.property]

    if(!!find){
      return cellValue==find;
    }else{
        let isTrue=true;
        if(from) isTrue=isTrue&&(from<=cellValue);
        if(to)isTrue=isTrue&&(cellValue<=to);
        return isTrue;
    }

  }

}
export const FilterPayRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterPayStatus params={params}></FilterPayStatus>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = [];
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = [];
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      let isTrue=true;
      data.forEach(dataItem=>{
          isTrue=isTrue&&cellValue.find(x=>x==dataItem);
      })

      return isTrue;
    }
    return false
  }
}

export const FilterSelectRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterSelect params={params}></FilterSelect>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      return cellValue == data;
    }
    return false
  }
}

export const FilterCheckboxRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterCheckbox params={params}></FilterCheckbox>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = [];
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = [];
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      let isTrue=true;
      data.forEach(dataItem=>{
          isTrue=isTrue&&cellValue.find(x=>x==dataItem);
      })

      return isTrue;
    }
    return false
  }
}

export const FilterRegionRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterRegion params={params}></FilterRegion>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property];    
    // if(data.length==0) return true;
    if(!cellValue) return false;


    let isTrue = false;
    // 处理多选的选中

    // data.forEach(pcas => {
      //逐个条件检查
      let pcasIsTrue = true;
      for (let i = 0; i < cellValue.length; i++) {
        if (data.length > i) {
          pcasIsTrue = pcasIsTrue && (cellValue[i] == data[i]);
        }
      }
      //只要一个条件符合就是true
      isTrue = isTrue || pcasIsTrue;
    // })
    return isTrue
  }
}



export const FilterAddressRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterAddress params={params}></FilterAddress>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property];    
    if(!cellValue) return false;


    let isTrue = false;
    // 处理多选的选中

    // data.forEach(pcas => {
      //逐个条件检查
      let pcasIsTrue = true;
      for (let i = 0; i < cellValue.location.length; i++) {
        if (data.length > i) {
          pcasIsTrue = pcasIsTrue && (cellValue.location[i] == data[i]);
        }
      }
      //只要一个条件符合就是true
      isTrue = isTrue || pcasIsTrue;
    // })
    return isTrue
  }
}


export const FilterNationalityRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterNationality params={params}></FilterNationality>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property];
    if(data.length==0) return true;
    if(!cellValue) return false;

    let isTrue = false;
    // 处理多选的选中

    data.forEach(filterItem => {
      isTrue = isTrue || cellValue == filterItem;
    })
    return isTrue
  }
}


export const FilterUniversityRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterUniversity params={params}></FilterUniversity>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.property];
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }
}


export const FilterTimeRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterTime params={params}></FilterTime>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    
    let moment = require("moment");
    options.forEach((option) => {
      // option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)];
      option.data =[moment().add(-7,'days'), moment()];      
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    
    let moment = require("moment");
    // option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)];
    option.data =[moment().add(-7,'days'), moment()];     
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const moment =require('moment');
    const { data } = option
    const from =moment(moment().format('YYYY-MM-DD ')+ moment(data[0]).format("HH:mm:ss"));
    const to =moment(moment().format('YYYY-MM-DD ')+ moment(data[1]).format("HH:mm:ss"));
    const cellValue = row[column.property]
    
    if(cellValue){
      let cellValueDay=moment(moment().format('YYYY-MM-DD ')+cellValue);
      return from.isSameOrBefore(cellValueDay) && cellValueDay.isSameOrBefore(to);
    }
    return false;
  }

}


export const FilterDateRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterDate params={params}></FilterDate>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    const moment =require('moment');
    options.forEach((option) => {
      // option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)];
      option.data =[moment().add(-7,'days'), moment()];     
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    const moment =require('moment');
    // option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)];
    option.data =[moment().add(-7,'days'), moment()];     
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const moment =require('moment');
    const { data } = option
    const from =moment(moment().format('YYYY-MM-DD ')+ moment(data[0]).format("HH:mm:ss"));
    const to =moment(moment().format('YYYY-MM-DD ')+ moment(data[1]).format("HH:mm:ss"));
    const cellValue = row[column.property]
    
    if(cellValue){
      let cellValueDay=moment(moment().format('YYYY-MM-DD ')+cellValue);
      return from.isSameOrBefore(cellValueDay) && cellValueDay.isSameOrBefore(to);
    }
    return false;
  }

}


export const FilterRateRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterSelect params={params}></FilterSelect>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options,column }) {
    options.forEach((option) => {
      option.data = "";
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = 0;
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option    
    const cellValue = row[column.property]
    
    if(cellValue){      
      return data==column.params.optionsItem[cellValue-1];
    }
    return false;
  }

}



export const FilterStatusRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterSelect params={params}></FilterSelect>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = "";
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = "";
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.params.column]
    // if (cellValue) {

    let filterStatus=[];
    // 查询类型：1待审核、2已通过、3已驳回、4返回修改

    data.forEach(item => {
      switch(item){
        case '1':filterStatus.push(2,6); break;
        case '2':filterStatus.push(3,7); break;
        case '3':filterStatus.push(4,8); break;
        case '3':filterStatus.push(5,9); break;
        case '-1':filterStatus.push(1);  break;
      }
    });
    return filterStatus.indexOf(cellValue)!=-1;
      // let isTrue=false;

      // data.forEach(dataItem=>{
      //     isTrue=isTrue||cellValue==dataItem;
      // })

      // return isTrue;
    // }
    return false
  }
}


export const FilterDepartRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterDept params={params}></FilterDept>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = '';
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = '';
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row['agencyDeptList'];

    for(let i=0;i<cellValue.length;i++){
      if(cellValue[i].agencyDeptId==data||cellValue[i].parents.indexOf(data.toString())>=0){
        return true
      }
    }
    // if (cellValue) {

    // console.log("----",data,cellValue)

      // return filterStatus.indexOf(cellValue)!=-1;
    return false
  }
}


export const FilterUserSelectRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterString params={params}></FilterString>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({options,column}) {
    options.forEach((option) => {
      option.data = ''
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = ''
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    // return true;
    const { data } = option
    const cellValue = row[column.property]
    if (cellValue) {
      return cellValue.indexOf(data) > -1
    }
    return false
  }

}


export const FilterSignRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    let selectOptions = [
      {value: '1',label:'已签'},
      {value: '0',label:'未签'},
    ];
    return [
      <FilterOptions params={params} selectOptions={selectOptions}></FilterOptions>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = "";
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = "";
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.params.column]
    // if (cellValue) {

    let filterStatus=[];
    return filterStatus;
  }
}


export const FilterPictureUploadRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    let selectOptions = [
      {value: '1',label:'已上传'},
      {value: '0',label:'未上传'},
    ];
    return [
      <FilterOptions params={params} selectOptions={selectOptions}></FilterOptions>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = "";
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = "";
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.params.column]
    // if (cellValue) {

    let filterStatus=[];
    return filterStatus;
  }
}

export const FilterCascaderRender = {
  // 筛选模板
  renderFilter(h, renderOpts, params) {
    return [
      <FilterCascader params={params}></FilterCascader>
    ]
  },
  showFilterFooter: false,
  // 重置数据方法
  filterResetMethod({ options }) {
    options.forEach((option) => {
      option.data = "";
    })
  },
  // 重置筛选复原方法（当未点击确认时，该选项将被恢复为默认值）
  filterRecoverMethod({ option }) {
    option.data = "";
  },
  // 筛选方法
  filterMethod({ option, row, column }) {
    const { data } = option
    const cellValue = row[column.params.column]
    // if (cellValue) {

    let filterStatus=[];
    return filterStatus;
  }
}
