<template>
  <!-- 数据关联 列表的渲染 -->
  <!-- <el-card class="box-card" shadow="hover"> -->
  <div class="list-wrapper" :class="[active?'active':'',isSelected(cardData)?'selected':'', selectedMark(cardData.fillId)?'alreadySelected':'']" @click="select">
    <!-- 管理员：  {{isAdmin}} -->
    <div slot="header" class="list-header clearfix">
      <div class="header-content box-card-item">
        <!-- {{ cardData.fillId }} --{{selectedMark(cardData.fillId)}} -->
        <template v-if="isUseRender(fields[0])">
          <span>
            <!-- 需要渲染的标题 -->
            <cardCompRender v-bind="renderField(fields[0])"></cardCompRender>
          </span>
        </template>
        <template v-else>
          <span>
            <!-- 非渲染标题 -->
            <span v-if="cardData[fields[0].name]">{{cardData[fields[0].name].toString()}}</span>
          </span>
        </template>
      </div>
      <!-- <div class="header-buttons">
            <el-button class="title-button" type="text" @click="select" v-if="isSelect">选择</el-button>
            <!- - 只有管理员有查看功能 （弹出选择不能查看） - ->
            
            </div> -->
      <el-button class="title-button" type="text" style="margin-right:15px" @click.stop="view" v-if="isAdmin">查看</el-button>
    </div>
    <div v-for="o in fields.length>filterMaxRow?filterMaxRow: (fields.length-1)" :key="o" class="box-card-item">
      <template v-if="isUseRender(fields[o])">
        <span class="box-card-item-label">{{fields[o].displayName}}</span>
        <cardCompRender v-if="fields[o]" v-bind="renderField(fields[o])"></cardCompRender>
        <!-- 渲染的 -->
      </template>
      <template v-else>
        <span class="box-card-item-label">{{fields[o].displayName}}</span><span v-if="cardData[fields[o].name]">{{cardData[fields[o].name].toString()}}</span>
        <!-- 非渲染 -->
      </template>
    </div>

    <div class="selectedMark">
      <el-tag type="info" size="mini">个人填报中已选过此数据</el-tag>
    </div>
  </div>
  <!-- </el-card> -->
</template>
<script>
import cardCompRender from './fill-select-card-component-render.vue'
import { showPicturePreview } from "@/utils/formUtil";
import { getSessionStorageObj } from '@/utils/db'
import { renderProvider } from '@/utils/gridrender/cellRender'
export default {
  components: { cardCompRender },
  name: 'fillSelectCard',
  componentName: 'fillSelectCard',
  props: {
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },
    cardData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 定义的所有cellRender,要从中找到需要的
    // renders: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // },
    isShowView: {
      type: Boolean,
      default: false
    },
    maxCount: Number,
    filterMaxRow: {
      type: Number,
      default: 3
    },
    selectedCount: Function,
    //只能选择的窗口
    isSelected: Function,
    checkMaxSelected: Function,
    viewItemHandler: Function,
    selectItemHandler: Function,
    selectedMark: Function
  },
  data () {
    return {
      active: false,
      renders: renderProvider,
      isAdmin: getSessionStorageObj("users").type > 1
    }
  },
  mounted () {
    this.active = this.isSelected(this.cardData)
  },
  methods: {
    view () {
      if (this.isAdmin) {
        this.viewItemHandler(this.cardData)
      } else {
        console.debug("非管理员，不能打开");
      }
    },
    remove () {
      this.$emit('removeItem')
    },
    renderField (field) {
      let { renderDefault } = renderProvider[field.type]
      // select 需要判断一下renderDefault用哪个
      if (field.type == 'select') {
        renderDefault = renderProvider[field.filterType].renderDefault
      }

      return {
        widgetRender: renderDefault,
        field: field,
        row: this.cardData,
        column: {
          ...field,
          property: field.name,
          params: field.options
        }
      }
    },
    select () {
      if (!this.active) {
        if (this.checkMaxSelected && this.checkMaxSelected()) {
          this.selectItemHandler(this.cardData)
          this.active = !this.active
        }
      } else {
        this.selectItemHandler(this.cardData)
        this.active = !this.active
      }
    },
    isUseRender (field) {
      // 以分数展示的评分组件不使用渲染,评分表单配置数据(widgetList)的filterType存在问题，始终为filterType=rate,(fieldList)无问题
      if (field && field.type == 'rate' && field.filterType == 'number') {
        return false
      } else {
        console.info('未找到render:',field)
        // 格式有点错误
        // field.options = { optionItems: field.optionItems||field.filterOptions }
      }
      return this.renders[field.type]
    },
    showPictureView (field, file, fileList, index) {
      showPicturePreview(file, fileList);
    },
  }
}
</script>

<style scoped lang="scss">
// .box-card-container {
//     max-height: 700px;
//     padding-right: 10px;
//     overflow-y: auto;
//     box-sizing: border-box;
//     margin-bottom: 10px;
// }
.box-card {
  margin-bottom: 10px;

  .title-button {
    float: right;
    padding: 3px 0;
    margin-left: 10px;
  }

  .box-card-title-label {
    font-weight: bold;
    &:after {
      content: ':';
      margin-right: 5px;
    }
  }
  &-item {
    line-height: 25px;
    // height:25px;
    overflow: hidden;
    &-label {
      font-weight: bold;
      display:inline-block;
      min-width: 100px;
      margin-right: 10px;

      &:after {
        content: ':';
        margin-right: 5px;
      }
    }
  }
}

::v-deep .ico-file {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  display: inline-block;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

::v-deep .picture-view {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #e8e8e8;
  &.picture-view:hover {
    cursor: pointer;
    border: solid 1px #409eff;
  }
}

// .box-card-container .item{
$borderColor: #d0deee;

//   &:first-child .list-wrapper{
//     border:solid 1px  $borderColor;
//   }

.list-wrapper {
  width: 100%;
  border: solid 1px $borderColor;
  // border-top:none;
  padding: 4px 10px;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #f2f8ff;
  }

  &.active {
    $borderColor: #4696ec;
    border: solid 1px $borderColor;
    position: relative;

    &::before {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 18px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: inline-block;
      font-size: 12px;
      content: '✔';
      color: white;
      z-index: 10;
    }
    &::after {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 30px;
      height: 30px;
      display: inline-block;
      box-sizing: border-box;
      content: '';

      border: solid 15px $borderColor;
      border-left: solid 15px transparent;
      border-bottom: solid 15px transparent;
    }
  }

  &.selected {
    background-color: #f2f8ff;
  }

  &.alreadySelected {
    .selectedMark {
      display: block;
    }
  }

  .selectedMark {
    position: absolute;
    right: 20px;
    bottom: 10px;
    display: none;
  }
}

.list-wrapper {
  .list-header {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-content {
    display: inline-block;
    flex-grow: 1;
    font-weight: bold;
  }
  .header-buttons {
    display: inline-block;
    flex-grow: 0;
  }
}
// }
</style>