import router from './router'
import store from './store'
import NProgress from 'nprogress' //NProgress是页面跳转是出现在浏览器顶部的进度条
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth';
import { isBlank } from "@/utils/whale";
import { setSessionStorageObj, getSessionStorageObj } from '@/utils/db'


NProgress.configure({ showSpinner: false })

// 在免登录白名单，直接进入
const whiteList = ['/login','/admin_login','/404','/login_redirect','/401']

router.beforeEach((to, from, next) => {

  //如果是通过token登录
  if(to.path === '/login_redirect'||to.path=='/401'){
    next();
    NProgress.done();
    return;
  }
  
  NProgress.start()
  if (getToken()) {
    //如果有token
    if (to.path === '/user/wechat') {
      next()
      NProgress.done()
    } else if (
      to.path.indexOf('/h5Detail') !== -1 || 
      to.path.indexOf('/h5Render') !== -1 || 
      to.path.indexOf('/h5') !== -1 || 
      to.path.indexOf('/sign') !== -1|| 
      to.path.indexOf('/blankDetail') !== -1 || 
      to.path.indexOf('/blank') !== -1|| 
      to.path.indexOf('/largeScreen') !== -1
    
    ) {
      next()
      NProgress.done()
    } else {
      let sessionStorageUser = getSessionStorageObj('users')
      if (!isBlank(sessionStorageUser)) {
        // if (isBlank(sessionStorageUser.openId)) {
        //   next('/user/wechat')
        // } else {
        //   if (to.path === '/login') {
        //     next({ path: '/index' })
        //   } else {
            next()
            NProgress.done()
        //   }
        // }
      } else {
        store.dispatch('GetUserInfo').then(res => {
          if (res.code === 200) {
            setSessionStorageObj('users', res.data)
            // if (isBlank(res.data.openId)) {
            //   next('/user/wechat')
            // } else {
              next()
              NProgress.done()
            // }
          }
        })
      }
    }

  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      next()
      NProgress.done()
    } else if (
       to.path.indexOf('/h5Detail') !== -1 || 
       to.path.indexOf('/h5Render') !== -1|| 
       to.path.indexOf('/h5') !== -1|| 
       to.path.indexOf('/sign') !== -1 ||
       to.path.indexOf('/blankDetail') !== -1 || 
       to.path.indexOf('/projectBlankDetail') !== -1 || 
       to.path.indexOf('/share') !== -1|| 
       to.path.indexOf('/largeScreen') !== -1 ||
       to.path.indexOf('/sso/nzxy') !== -1)
      {
      next()
      NProgress.done()
    } else {
      next(`/login?redirect=${to.fullPath}`)
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
