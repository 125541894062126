var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-card-item-wrapper",
      staticStyle: { "padding-right": "5px" },
    },
    [
      _c("filleCard", {
        attrs: {
          fields: _vm.fields,
          isActive: _vm.isActive,
          cardData: _vm.source,
          maxCount: _vm.maxCount,
          checkMaxSelected: _vm.checkMaxSelected,
          selectedMark: _vm.selectedMark,
          selectItem: _vm.select,
          isSelected: _vm.isSelected,
          viewItemHandler: _vm.viewItemHandler,
          selectItemHandler: _vm.selectItemHandler,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }