<template>
    <div class="my-filter-input">
         <!-- <el-checkbox-group v-model="option.data">
            <el-checkbox v-for="item in column.params.options" :key="item" :label="item.value||item">{{item.label||item}}</el-checkbox>
        </el-checkbox-group> -->
         <el-select v-model="option.data" size="mini" placeholder="请选择状态" @change.native="confirmFilter">
            <el-option v-for="item in column.params.optionsItem" :key="item" :value="item.value||item" :label="item.label||item"></el-option>
        </el-select>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterStatus',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
        };
    },
    created() {
        this.load();

    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;
            $panel.changeOption(null, checked, option);
        },
        clearFilter() {
            this.option.data = [];
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data;

            //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter(this.params);
        },
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
}
</style>