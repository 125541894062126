<template>
    <div class="my-filter-input">
        <div class="filter-data">
            <el-input-number  v-model="option.data.find" placeholder="请输入筛选内容" size="mini" style="width:220px" @keyup.enter.native="confirmFilter" @input="changeOptionEvent" controls-position="right"></el-input-number ><br/>
        </div>
        <div class="filter-data">
            <el-input-number v-model="option.data.from" placeholder="≥" size="mini" style="width:100px;display:inline-block;" controls-position="right"></el-input-number>
             <span> ~ </span>
            <el-input-number v-model="option.data.to" placeholder="≤" size="mini" style="width:100px;display:inline-block;" controls-position="right"></el-input-number>
        </div>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterNumber',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
        };
    },
    created() {
        this.load();
        console.log("data",this.option);
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!this.option.data.find||!!this.option.data.from||!!this.option.data.to;
            $panel.changeOption(null, checked, option);
        },
        clearFilter(){
            this.option.data={find:null,from:null,to:null};
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data.find||!!this.option.data.from||!!this.option.data.to;

            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
.my-filter-input {
    padding: 10px;
}

.my-filter-input {
    .filter-data{
        width:220px;
        display:flex;
        justify-content: space-between;
        align-items: center;

        span{
            display: inline-block;
        }

        &:first-of-type{
            margin-bottom:5px;
        }
    }
}
</style>