import {
  checkFill,
  editFill,
  fill,
  getFillLogList,
  formDetail,
  deleleteFile,
  getCustomTip,
  orderCreat,
  formAuth,
} from "@/api/system/form";
import {
  beforeBackConfirm,
  showPicturePreview,
  changedValue,
} from "@/utils/formUtil";

import { saveDraft, updateDraft } from "@/api/system/draft";
import { aliUploader } from "@/utils/oss";
import { getSessionStorageObj } from "@/utils/db";
import { httpGetFormFieldV2, fillDetail } from "@/api/system/form";
import { getAgency, getAgencyUser, agencyUserList } from "@/api/system/agency";
import { deepClone } from "../../../../../utils";
import useMobileSignTipVue from "@/views/system/form/components/sign/useMobileSignTip";
import QRCode from "qrcodejs2";
import viewPayMixin from "@/views/system/form/dataView/viewPayMixin.js";
import saveDataChooseMixin from "../../mixins/saveDataChooseMixin";

export default {
  mixins: [viewPayMixin, saveDataChooseMixin],
  props: {
    quite: {
      type: Boolean,
      default: false,
    },
    login: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShow: false,
      isShowInside: false,
      isEdit: false,
      isView: true,
      formMode: "",
      listType: "fill",
      fillId: "",
      formDetail: {},
      formJson: {},
      formData: {},
      logs: [],
      title: "",
      allowSave: true,
      isShowAuditButton: false,
      auditButtonCallback: null,
      customTip: {},
      fillInfo: null,
      fillSuccessShow: false,
      draftSuccessShow: false,
      draftSuccessData: [],
      userAgency: {},
      optionData: {},
      viewActions: [],
      // 弹出新增表单数据对话框
      showAddNew: false,
      fromDraft: false, // 标记是否是从草稿箱进入，默认不是
      draftFillId: "",
      addNewSaveCallbackHandler: null, //弹出新增数据的回调
    };
  },
  created() {
    const sessionStorageUser = getSessionStorageObj("users");
    const userToken = this.cookie.get("User-Token");

    this.optionData.userToken = userToken;
    this.optionData.apiUrl = `${process.env.VUE_APP_BASE_API_URL}`;
    this.optionData.formId = this.formId;
    this.optionData.fillId = this.fillId;
    this.optionData.fileViewUrl = process.env.VUE_APP_BASE_FILE_PREVIEW;

    if (!this.login) return;

    // 调用上级页面的 数据关联填充、用户选择填充，如果上级页面没有提供对应的方法，使用本页面的
    this.optionData.formDataSelectHandler =
      this.$parent.showDataSelect || this.showDataSelect;
    this.optionData.formDataSelectAutoFillHandler =
      this.$parent.showDataAutoSelect || this.showDataAutoSelect;
    this.optionData.formDataSelectMultiFillHandler =
      this.$parent.showDataMultiSelect || this.showDataMultiSelect;
    this.optionData.isUserAdmin = getSessionStorageObj("users")?.type > 1;

    // 打开嵌套表单
    this.optionData.formDataViewHandler = this.showDataView;
    this.optionData.formPicturePreviewHandler = showPicturePreview;
    this.optionData.userSelectHandler = this.showUserSelect;
    this.optionData.formCreateUser = sessionStorageUser;
    this.optionData.mobileSign = useMobileSignTipVue;

    this.optionData.formDataCardRender = this.dataCardRender();

    this.userAgency = getSessionStorageObj("agencyUser");
  },
  computed: {
    formAgencyId() {
      return this.formDetail?.agencyId;
    },
    disableEditFill: function () {
      return (
        // 表单到期了并且不允许修改 返回true
        !this.formDetail.isStop ||
        (this.formAuth.currentEditFillAuth == true ? false : true)
      );
    },
    isFormCreator() {
      const sessionStorageUser = getSessionStorageObj("users");
      // if(this.formDetail)
      return this.formDetail.userId === sessionStorageUser?.userId;
    },
    // 判断是不是表单子管理员
    isFormSubAdmin: function () {
      if (this.formDetail.managerUserId && this.userAgency) {
        // 判断字符串是否包含this.userAgency.userId
        return (
          this.formDetail.managerUserId.indexOf(this.userAgency.userId) !== -1
        );
      } else {
        return false;
      }
    },
    isCreateUser: function () {
      return this.formData.currentIsCreateUser !== false;
    },
    isDataManager: function () {
      return this.formData.currentIsDataManager !== false;
    },
  },
  methods: {
    //填报窗口 数据选择组件弹出选择数据
    showDataSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.show(fillSelectOption, callback);
    },
    showDataAutoSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect(fillSelectOption, callback, search, searchObject) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.autoFillMulti(
        fillSelectOption,
        callback,
        search,
        searchObject
      );
    },

    show({
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets /* 审核列表和管理列表显示隐藏字段 */,
      showFields /* 控制显示的字段（批量编辑等） */,
      hideFields,
      readonlyFields,
      enableReadonly /* 强制启用只读字段（管理列表的编辑模式）*/,
      agencyId,
      formDetail,
      formMode,
      listType,
      fillInfo = null,
      viewActions,
      callback,
    }) {
      this.title = "表单详情";
      this.isView = false;
      this.formData = formData;
      this.formDetail = formDetail;
      let allowEdit = this.checkIsAllowEdit(formMode);
      if (allowEdit) {
        this._show({
          fillId,
          formId,
          formJson,
          formData,
          showHiddenWidgets,
          edit: true,
          enableForm: "enableForm",
          showFields,
          hideFields,
          readonlyFields,
          enableReadonly,
          agencyId,
          formDetail,
          formMode,
          listType,
          fillInfo,
          viewActions,
          callback,
        });

        if (formMode === "edit") {
          // this.checkEditDraft();
        }
      }
    },
    // showAdd( formId, formJson, showHiddenWidgets, showFields, agencyId ) {
    showAdd({
      fillId, // 填报id
      formId, // 表单id
      formJson, // vform表单json
      formData, // 表单填报数据
      formMode, // new: 新增，edit: 编辑, view: 查看, audit: 审核
      formDetail, // 表单详情
      title,
      listType,
      callback,
      fromDraft = false, // 是否是从草稿箱进入的，默认不是
      draftFillId,
    }) {
      this.title = title || "表单填报";
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        formMode,
        formDetail,
        listType,
        callback,
        fromDraft,
        draftFillId,
      });
      // this.checkDraft();
      this.getCustomTip();
    },
    showView({
      fillId,
      formId,
      formJson,
      formData,
      showFields,
      hideFields,
      readonlyFields,
      agencyId,
      formDetail,
      formMode = "view",
      listType = "FILL",
      showHiddenWidgets,
      enableReadonly = false,
      fillInfo = null,
      viewActions = [],
      callback,
    }) {
      this.title = "表单详情";
      this.isView = true;
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        showFields,
        hideFields,
        readonlyFields,
        edit: true,
        enableForm: "disableForm",
        enableReadonly,
        agencyId,
        formDetail,
        formMode,
        listType,
        fillInfo,
        viewActions,
        callback,
      });
    },
    showViewReadonly(
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets,
      showFields,
      hideFields,
      readonlyFields,
      enableReadonly,
      agencyId,
      formDetail
    ) {
      this.title = "表单详情";
      this.isView = true;
      this.formDetail = formDetail;
      this._show({
        fillId,
        formId,
        formJson,
        formData,
        showHiddenWidgets,
        edit: false,
        enableForm: "disableForm",
        showFields,
        hideFields,
        readonlyFields,
        enableReadonly,
        agencyId,
        formDetail,
      });
    },
    onCloseFillSuccessPop() {
      this.fillSuccessShow = false;
    },
    checkIsAllowEdit(formMode) {
      const notAllowEditAndReturnBack = () => {
        this.$message({
          message: "当前填报状态已发生改变，禁止修改，请刷新列表",
          type: "error",
          center: true,
          duration: 2000,
          customClass: "global-messageZIndex",
          onClose: () => {},
        });
      };

      // 如果表单开启了审核，需要判断修改条件
      // 当前用户不为管理员时：不允许修改：
      // 1：审核完成
      // 2: 一级审核通过，一级审核驳回，2级审核通过，2级审核驳回，3级审核通过，3级审核驳回。
      if (formMode === "edit") {
        if (
          !this.isDataManager &&
          !this.isFormSubAdmin &&
          !this.isFormCreator
        ) {
          if (
            !(
              this.formData["status"] == 1 ||
              this.formData["status"] == 2 ||
              this.formData["status"] == 5 ||
              this.formData["status"] == 9 ||
              this.formData["status"] == 13
            )
          ) {
            // 不允许填报
            notAllowEditAndReturnBack();
            return false;
          }

          // 表单到期不允许修改
          if (this.disableEditFill) {
            // 不允许填报
            notAllowEditAndReturnBack();
            return false;
          }
        }
      }
      return true;
    },
    // showFields, // 指定显示的字段
    async _show({
      fillId,
      formId,
      formJson,
      formData,
      showHiddenWidgets,
      formMode, // new: 新增，edit: 编辑, view: 查看, audit: 审核
      enableForm,
      showFields,
      hideFields,
      readonlyFields,
      enableReadonly = false,
      agencyId,
      formDetail,
      isAdd = false,
      listType, //FILL, AUDIT,MANAGE
      fillInfo = null,
      fromDraft = false, //判断是否是草稿箱进入的，默认不是
      draftFillId,
      viewActions = [],
      callback,
    }) {
      const _this = this;
      const sessionStorageUser = getSessionStorageObj("users") || {};

      _this.formJson = formJson;
      _this.formData = formData;
      _this.formDetail = formDetail;
      _this.formId = formId;
      _this.fillId = fillId;
      _this.isShow = true; // 显示表单填报弹窗
      _this.fillInfo = fillInfo;
      _this.listType = listType;
      _this.formMode = formMode;
      _this.fromDraft = fromDraft;
      _this.draftFillId = draftFillId;
      _this.viewActions = viewActions;

      if (formMode == "new") {
        this.isAdd = true;
        this.isEdit = false;
        this.isView = false;
      } else if (formMode == "edit") {
        this.isAdd = false;
        this.isEdit = true;
        this.isView = false;
      } else if (formMode == "audit") {
        this.isAdd = false;
        this.isEdit = true;
        this.isView = false;
      } else if (formMode == "view") {
        this.isAdd = false;
        this.isEdit = false;
        this.isView = true;
      } else if (formMode == "data") {
        this.isAdd = true;
        this.isEdit = false;
        this.isView = false;
      }
      this.optionData.loadAgencyUserExtendFieldsHandler = getAgency;
      this.optionData.loadAgencyUserListHandler = agencyUserList;
      this.optionData.loadAgencyUserHandler = getAgencyUser;
      this.optionData.aliOSSUpload = aliUploader;
      this.optionData.formAgencyId = this.formAgencyId;
      this.optionData.formDetail = this.formDetail;
      this.optionData.formId = this.formDetail.formId;
      this.optionData.formData = this.formData;
      this.optionData.fillId = this.fillId || "";
      this.optionData.listType = this.listType;
      this.optionData.fillInfo = this.fillInfo;
      this.optionData.showAddNew = this.showAddNewDialog;
      this.optionData.showEdit = this.showEditDialog;

      this.optionData.aliOSSUploadConfig = {
        // OK
        formId: formId || "formId",
        fillId: fillId || "fillId",
        agencyId: this.formAgencyId,
        userId: sessionStorageUser.userId || "userId",
        uploadPath: "fillFile/{agencyId}/{formId}/{userId}/{fieldName}/",
      };
      this.optionData.fileDelete = this.fileDelete;

      // 如果指定了显示哪些字段，挑选出来，否则显示全部字段。（数据关联的查看详情功能）

      if (showFields) {
        const showNames = showFields.map((x) => x.name);

        const newFormWidgetList = [];
        const i = 0;
        for (let i = 0; i < formJson.widgetList.length; i++) {
          if (showNames.indexOf(formJson.widgetList[i].options.name) >= 0) {
            let tmpWidget = deepClone(formJson.widgetList[i]);
            if (showHiddenWidgets) {
              tmpWidget.options.hidden = false;
            }
            newFormWidgetList.push(tmpWidget);
          }
        }
        formJson.widgetList = newFormWidgetList;
      }

      this.$nextTick(() => {
        this.$refs.vformRender.setFormJson(
          formJson,
          { formMode, listType },
          () => {
            // 必须确保setFormJson完成后再设置数据，这样才不会影响设置了逻辑显示的字段
            // 提示: setFormJson里含有nextTick异步操作
            // if (callback) callback();
            this.$refs.vformRender.setFormData(
              formData,
              this.formId,
              this.fillId
            );
            this.$nextTick(() => {
              if (callback) callback();
            });
          }
        );
      });

      if (this.login && formMode != "new") {
        getFillLogList(fillId, this.formId).then((res) => {
          // 日志排序
          this.logs = res.data;
        });
      }
    },
    actionExists(action) {
      return !!(this.viewActions || []).find((x) => x == action);
    },

    //填报窗口 数据选择组件弹出选择数据
    showDataSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      fillSelectOption.formId = this.formId;
      this.$refs.fillSelector.show(fillSelectOption, callback);
    },
    showDataAutoSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFill(fillSelectOption, callback);
    },
    showDataMultiSelect(fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.autoFillMulti(fillSelectOption, callback);
    },
    // 检查新增时的草稿
    checkDraft() {
      var draftFormId = localStorage.getItem("draft_formId");
      if (
        draftFormId != this.formId ||
        draftFormId == "" ||
        draftFormId == null
      ) {
        return false;
      }

      this.$confirm("有未保存的草稿，是否使用草稿数据", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          const fromData = localStorage.getItem("draft_formModel");
          this.$refs.vformRender.setFormData(
            JSON.parse(fromData),
            null,
            null,
            true
          );
          // localStorage.removeItem("draft_formId");
          // localStorage.removeItem("draft_formModel");
        })
        .catch(() => {
          // localStorage.removeItem("draft_formId");
          // localStorage.removeItem("draft_formModel");
        });
    },
    // 检查编辑时的草稿
    checkEditDraft() {
      var draftFormId = localStorage.getItem("draft_e_formId");
      var draftFillId = localStorage.getItem("draft_e_fillId");
      if (
        draftFormId != this.formId ||
        draftFormId == "" ||
        draftFormId == null ||
        draftFillId != this.fillId
      ) {
        return false;
      }

      this.$confirm("有未保存的草稿，是否使用草稿数据", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          const fromData = localStorage.getItem("draft_e_formModel");
          this.$refs.vformRender.setFormData(
            JSON.parse(fromData),
            null,
            null,
            true
          );
          // localStorage.removeItem("draft_e_formId");
          // localStorage.removeItem("draft_e_formModel");
        })
        .catch(() => {
          // localStorage.removeItem("draft_e_formId");
          // localStorage.removeItem("draft_e_formModel");
        });
    },
    // 关联数据表单在查看、修改表单的时候，点击查看按钮加载所关联的表单数据
    showDataView(fillSelectOption, fillData, callback) {
      // 同时加载，提升加载速度
      const loadField = new Promise((resolve, reject) => {
        httpGetFormFieldV2(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadData = new Promise((resolve, reject) => {
        fillDetail(fillData.fillId, fillSelectOption.formId)
          .then((res) => {
            resolve(res.data.fillJson);
          })
          .catch((err) => {
            reject(err);
          });
      });
      const loadFormDetail = new Promise((resolve, reject) => {
        formDetail(fillSelectOption.formId)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
      Promise.all([loadField, loadData, loadFormDetail]).then((values) => {
        this.showInside(
          fillData.fillId,
          fillSelectOption.formId,
          values[0],
          values[1],
          this.isShowHiddenWidgets || true,
          fillSelectOption.fields,
          values[2]
        );
      });
    },
    getCustomTip() {
      const _this = this;
      // 获取表单配置(表单设计器)数据
      getCustomTip(_this.formId).then((response) => {
        this.customTip = response.data;
      });
    },
    dataCardRender() {
      const renders = require("@/utils/gridrender/cellRender");
      return renders.renderProvider;
    },
    setAllowSave(flag = true) {
      this.allowSave = flag;
    },
    disableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.disableForm();
      });
    },
    enableForm() {
      this.$nextTick(() => {
        this.$refs.vformRender.enableForm(this.optionData.isUserAdmin);
      });
    },
    showHiddenWidgets() {
      this.$refs.vformRender.showHiddenWidgets();
    },
    hideHiddenWidgets() {
      this.$refs.vformRender.hideHiddenWidgets();
    },
    /**
     * 更新逻辑控件
     *
     * @returns 无返回值
     */
    updateLogicalWidgets() {
      this.$refs.vformRender.showInitLogicalWidgetsChanged();
    },

    setHiddenWidgets(widgets) {
      this.$refs.vformRender.hideWidgets(widgets);
    },
    setNotHiddenWidgets(widgets) {
      this.$refs.vformRender.showWidgets(widgets);
    },
    //ForSubWidgets 标识如果字段为容器是，是否对子字段生效默认生效
    setReadonlyWidgets(widgets, forSubWidgets = true) {
      this.$refs.vformRender.disableWidgets(widgets, forSubWidgets);
    },
    setNotReadonlyWidgets(widgets, forSubWidgets = true) {
      this.$refs.vformRender.enableWidgets(widgets, forSubWidgets);
    },
    enableAllWidgets() {
      this.$refs.vformRender.enableAllWidgets();
    },

    clone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    addRelativeLink(fillId) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type === "fill-select" ||
          (x.field.subtype &&
            x.field.subtype == "relative" &&
            !(
              x.field.type === "sub-form" &&
              x.field.options.reference === "copy"
            ))
        // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
      );

      relativeWidgets.forEach(async (x) => {
        const widget = this.$refs.vformRender.getWidgetRef(
          x.field.options.name
        );
        await widget.syncRelativeChange(fillId);
      });
    },
    save(callback, quite) {
      // quite 参数表示不弹出提示
      const _this = this;

      _this.$refs.vformRender
        .getFormData()
        .then((fillJson) => {
          this._saveEdit(fillJson, quite, () => {
            if (callback && typeof callback === "function") {
              callback();
            }

            localStorage.removeItem("draft_e_fillId");
            localStorage.removeItem("draft_e_formId");
            localStorage.removeItem("draft_e_formModel");
            this.$emit("on-save", fillJson);
          });
        })
        .catch((err) => {
          console.log("err", err);
          _this.msgError(err);
        });
    },

    async _saveEdit(fillJson, quite, callback) {
      const changed = changedValue(this.formJson, this.formData, fillJson);

      // await this.editDataChoose(fillJson, this.formId, this.fillId);
      editFill({
        formId: this.formId,
        fillId: this.fillId,
        fillJson: fillJson,
        fillChange: changed,
      }).then((response) => {
        if (response.code == 200) {
          if (!quite) {
            this.msgSuccess("提交成功");
            this.closeQuite();
          }
          this.addRelativeLink(this.fillId);

          if (callback && typeof callback === "function") {
            callback();
          }
        }
      });
    },
    saveAndAudit() {
      this.save(this.auditButtonCallback, "quite");
    },
    saveAdd(callback, quite = false) {
      // 检查表单是否填写，如果未填写,且没有设置默认值，需要提示用户()
      // 这里只有创建一种情况，没有修改页面了。
      if (
        !this.$refs.vformRender.isFormDataEdit() &&
        !this.$refs.vformRender.isFormFillWithDefault() &&
        !this.fromDraft // 如果不是从草稿箱进入的，进行检查
      ) {
        this.msgError("请填写完表单信息后再提交");
        return;
      }

      this.loading.submit = true;

      const getFormFillData = (cb) => {
        this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const fillJsonCopy = JSON.parse(JSON.stringify(fillJson));
            const param = {
              formId: this.formId,
              fillJson: fillJson,
              formAgreementId: this.formAgreementId,
              formAgreementSignature: "",
            };
            this.fillJson = fillJsonCopy;
            if (cb) cb(param);
          })
          .catch((err) => {
            this.msgError(err);
            this.loading.submit = false;
          });
      };

      // 检查表单信息填报规则校验
      checkFill(this.formId).then((response) => {
        if (response.code == 200) {
          // 获取表单填写的数据
          getFormFillData(async (param) => {
            // 如果表单的模式是仅获取填报数据
            if (this.formMode == "data") {
              this.$emit("on-save", param);
              this.isShow = false;
            } else {
              // 先保存一下子表单的数据，把id组成正确的数据
              // 表单中可能存在多个子表单，因此构建一个map
              // {widgetId: [fillId1,fillId2] }
              // let fillIdMaps = await this.saveAddSubformDatas(param);
              // let subformDatas = this.getSubformWidgetData(param);

              fill(param)
                .then(async (response) => {
                  if (response.code == 200) {
                    this.formSubmitFillId = response.data;

                    // 这里增加保存数据选择引用的数据
                    await this.addRelativeLink(this.formSubmitFillId);
                    // 注意这里会引起提交提交后再次修改的问题
                    // await this.addDataChoose(
                    //   param,
                    //   this.formId,
                    //   this.formSubmitFillId
                    // );

                    // 如果当前表单id和草稿id一致，清空草稿
                    if (this.formId === localStorage.getItem("draft_formId")) {
                      setTimeout(() => {
                        localStorage.removeItem("draft_formId");
                        localStorage.removeItem("draft_formModel");
                      }, 2000);
                    }

                    this.$refs.vformRender.resetForm(() => {
                      this.isShow = false;
                      // 如果不是后退才弹出支付窗口
                      if (!quite) {
                        this.fillSuccessShow = true;
                        if (!this.formDetail.pay) {
                        }
                      }

                      if (this.$refs.vformRender.stopDraft)
                        this.$refs.vformRender.stopDraft();
                    });

                    this.loading.submit = false;
                    this.$emit("on-save", this.formId, this.formSubmitFillId);
                  }
                })
                .catch((e) => {
                  this.msgError("表单提交遇到错误，请稍候重试");
                  console.error(e);
                  this.loading.submit = false;
                });
            }
          });
        }
      });

      setTimeout(() => {
        this.loading.submit = false;
      }, 20 * 1000);
    },
    getSubformWidgetData(param) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const subformWidgetFields = fieldWidgets.filter(
        (x) =>
          x.field.type == "data-choose" &&
          x.field.subtype == "subform-choose" &&
          x.field.options.isBoth == true
      );
      const subformWidgetData = {};

      subformWidgetFields.forEach(({ field }) => {
        subformWidgetData[field.options.name] = deepClone(
          param[field.options.name]
        );
        param[field.options.name] = { fillIds: [] };
      });

      return subformWidgetData;
    },
    async saveAddSubformDatas(mainFormData) {
      // 构建一个对象 { chooseFieldName:{ options: {options},fillIds: [{填报数据1},{填报数据2}]} }
      const subformDataMap = {};
      subformfields.forEach((fieldWidget) => {
        console.log("fieldWidget", fieldWidget, param.fillJson);
        subformDataMap[fieldWidget.options.name] = {
          options: fieldWidget.options,
          fillIds: param.fillJson[fieldWidget.options.name] || [],
        };
      });

      const saveDataActions = [];

      Object.keys(subformDataMap).foreach((fieldName) => {
        for (let i = 0; i < subformDataMap[fieldName].fillIds.length; i++) {
          // 子表单每一行的填报数据
          let rowFillData = {
            fomrId: subformDataMap[fieldName].options.from,
            fillJson: subformDataMap[fieldName].fillIds[i],
          };

          saveDataActions.push({
            subformName: fieldName,
            fillData: subformDataMap[fieldName].fillIds[i],
            func: () => {
              return new Promise((resolve, reject) => {
                fill(rowFillData)
                  .then((res) => {
                    resolve(res.data);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            },
          });
        }
      });

      Promise.all(saveDataActions.map((x) => x.func))
        .then((resList) => {
          resList.forEach((res, index) => {});
        })
        .catch((errs) => {});
    },
    onlyAudit() {
      this.auditButtonCallback();
    },
    addRelativeLink(fillId) {
      const fieldWidgets = this.$refs.vformRender.getFieldWidgets();
      const relativeWidgets = fieldWidgets.filter(
        (x) =>
          x.field.type == "fill-select" ||
          x.field.type === "fill-select" ||
          (x.field.subtype &&
            x.field.subtype == "relative" &&
            !(
              x.field.type === "sub-form" &&
              x.field.options.reference === "copy"
            ))
        // 因为考虑历史数据里都是copy,所以暂时允许非 子表单的copy(复制)模式的字段以link(引用)模式运行
      );
      
      return new Promise((resolve, reject) => {
        let promises=[];
        relativeWidgets.forEach(async (x) => {
          const widget = this.$refs.vformRender.getWidgetRef(
            x.field.options.name
          );
          if (x.field.category && x.field.category === "container") {
            if (widget.$parent.syncRelativeChange) {
              promises.push(widget.$parent.syncRelativeChange(fillId));
            }
          } else {
            if (widget.syncRelativeChange) {
              promises.push( widget.syncRelativeChange(fillId));
            }
          }
        });
        Promise.all(promises).then((res)=>{
          console.log('res', res);
          resolve();
        })
      });
    },

    close() {
      if (this.isView) {
        this.closeQuite();
        return;
      }
      beforeBackConfirm(
        this.isEdit,
        this.$refs.vformRender,
        // this.isEdit,
        (formId, res) => {
          if (res) {
            this.draftSuccessData = [formId, res.data];
            this.draftSuccessShow = true;
            this.$emit("on-save", [this.formId, res.data]);
          }
          this.closeQuite();
        },
        this.closeQuite,
        {
          formId: this.formId,
          fillId: this.fillId,
          message: this.$confirm,
          draftFillId: this.draftFillId,
        }
      );
    },
    closeQuite() {
      if (this.$refs.vformRender) {
        this.$refs.vformRender.setFormJson({
          widgetList: [],
          formConfig: {
            modelName: "formData",
            refName: "vForm",
            rulesName: "rules",
            labelWidth: 80,
            labelPosition: "top",
            size: "",
            labelAlign: "label-left-align",
            cssCode: "",
            customClass: "",
            functions: "",
            layoutType: "PC",

            onFormCreated: "",
            onFormMounted: "",
            onFormDataChange: "",
            onFormValidate: "",
          },
        });
      }
      this.$nextTick(() => {
        this.isShow = false;
      });
    },
    // 表单开启支付功能，暂不支付
    onCancelPayFillClick() {
      this.fillSuccessShow = false;
      this.$emit("on-save");
    },
    // 表单开启支付功能，立即支付
    // onConfirmPayFillClick() {
    //   const _this = this;
    //   const dto = {
    //     fillId: this.formSubmitFillId,
    //     formId: this.formId,
    //     amount: this.fillJson.__amount
    //       ? Number(this.fillJson.__amount || "1")
    //       : undefined,
    //   };

    //   orderCreat(dto).then((response) => {
    //     if (response.code == 200) {
    //       const codeUrl = response.data.codeUrl;
    //       _this.formPayOpen = true;
    //       _this.orderPrice = response.data.orderPrice;
    //       _this.$nextTick(() => {
    //         new QRCode(_this.$refs["qrCodeUrl"], {
    //           text: codeUrl,
    //           width: 200,
    //           height: 200,
    //           colorDark: "#000000",
    //           colorLight: "#ffffff",
    //           correctLevel: QRCode.CorrectLevel.H,
    //         });
    //       });
    //       _this.setTimer(1000, response.data.orderNo);
    //     }
    //   });
    // },

    showUserSelect(selectedUserList, fieldOptions, callback) {
      this.$refs.userSelect.showDialog(selectedUserList, fieldOptions);
      this.userSelectCallback = callback;
    },
    hideWidgets(widgets) {
      widgets.forEach((widget) => {
        this.$refs.vformRender.getWidgetRef(widget).setDisabled(true);
      });
    },
    showAuditButton(isShowButton, callback) {
      this.isShowAuditButton = isShowButton;
      this.auditButtonCallback = callback;
    },
    fileDelete(file) {
      deleleteFile(file.fileId).then((res) => {});
      if (file.thumbFileId) {
        deleleteFile(file.thumbFileId).then((res) => {});
      }
    },
    onHandlePreviewImg(file) {
      this.previewImg = file.url;
      this.previewImgShow = true;
    },
    saveDraftAndClose() {
      let fillJson = this.$refs.vformRender.getFormData(false);
      if (!this.draftFillId) {
        // 新增草稿
        saveDraft({
          formId: this.formId,
          fillJson,
          draft: true,
        }).then((res) => {
          this.draftSuccessData = [this.formId, res.data];
          this.draftSuccessShow = true;
          this.$refs.vformRender.resetForm();
          // this.$emit('on-save',[this.formId, res.data]);
        });
      } else {
        // 更新草稿
        try {
          updateDraft({
            formId: this.formId,
            fillId: this.draftFillId,
            fillJson,
            fillChange: [],
          }).then(() => {
            this.$emit("on-save", [this.formId, this.draftFillId]);

            this.$refs.vformRender.resetForm();
            this.closeQuite();
          });
        } catch (e) {}
      }
    },
    saveDraftSuccess() {
      this.$emit("on-save", this.draftSuccessData);
      this.closeQuite();
      this.draftSuccessShow = false;
    },
    showAddNewDialog(param, callback) {
      this.addNewSaveCallbackHandler = callback;

      let formId = param.options.from;
      Promise.all([
        formDetail(formId),
        httpGetFormFieldV2(formId),
        formAuth(formId),
      ]).then(([formDetailRes, formJsonRes, formAuthRes]) => {
        let formAuth = formAuthRes.data;
        let formDetail = formDetailRes.data;
        let formJson = formJsonRes.data;

        // isStop 是反的，true的时候没有stop，false的时候是stop
        let isStop = !formDetail.isStop;
        if (!isStop && formAuth.currentInsertFillAuth === true) {
          this.showAddNew = true;
          this.$nextTick(() => {
            // this.$refs.vformNewRender.setFormJson(formJson);
            this.$refs.dlgvNewFormViewer.showAdd({
              title: "新增数据",
              formDetail,
              formData: {},
              formJson,
              formId,
              formMode: "data",
              listType: "FILL",
              callback: () => {
                // 回调 隐藏对应的字段
                this.$refs.dlgvNewFormViewer.setHiddenWidgets(
                  param.options.bothRelativeField
                );
              },
            });
          });
        } else {
          this.$message.error("表单已停止填报，或您没有填报权限");
        }
      });
    },
    showEditDialog(param, row, callback) {
      this.addNewSaveCallbackHandler = callback;

      let formId = param.options.from;
      Promise.all([
        formDetail(formId),
        httpGetFormFieldV2(formId),
        formAuth(formId),
      ]).then(([formDetailRes, formJsonRes, formAuthRes]) => {
        let formAuth = formAuthRes.data;
        let formDetail = formDetailRes.data;
        let formJson = formJsonRes.data;

        // isStop 是反的，true的时候没有stop，false的时候是stop
        let isStop = !formDetail.isStop;
        if (!isStop && formAuth.currentInsertFillAuth === true) {
          this.showAddNew = true;
          this.$nextTick(() => {
            // this.$refs.vformNewRender.setFormJson(formJson);
            this.$refs.dlgvNewFormViewer.show({
              title: "编辑数据",
              formDetail,
              formData: row,
              formJson,
              formId,
              fillId: row.fillId,
              formMode: "edit",
              listType: "FILL",
              hideFields: [],
              callback: () => {
                // 回调 隐藏对应的字段
                this.$refs.dlgvNewFormViewer.setHiddenWidgets(
                  param.options.bothRelativeField
                );
              },
            });
          });
        } else {
          this.$message.error("表单已停止填报，或您没有填报权限");
        }
      });
    },
    closeNewData() {
      this.$refs.vformNewRender.resetForm();
      this.$nextTick(() => {
        this.showAddNew = false;
      });
    },
    onHandleDataChooseAddData(formData) {
      // fillDetail(fillId, formId).then((newData) => {
      if (this.addNewSaveCallbackHandler) {
        this.addNewSaveCallbackHandler(formData);
      }
    },
    saveNewData() {
      const getFormJson = (cb) => {
        this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const param = {
              formId: _this.formId,
              fillJson: fillJson,
              formAgreementId: this.formAgreementId,
              formAgreementSignature: "",
            };
            if (cb) cb(param);
          })
          .catch((err) => {
            _this.msgError(err);
          });
      };

      checkFill(this.formId).then((response) => {
        if (response.code == 200) {
          getFormJson((param) => {
            fill(param).then((response) => {
              if (response.code == 200) {
                // 如果不是后退才弹出支付窗口
                // if (!callback) {
                //   _this.fillSuccessShow = true;
                //   if (!_this.formDetail.pay) {
                //   }
                // } else {
                //   setTimeout(function () {
                //     if (callback) callback();
                //   }, 1000);
                // }
              }
            });
          });
        }
      });

      this.$refs.vformNewRender.getFormData().then((data) => {
        checkFill(this.formId).then((response) => {
          if (response.code == 200) {
          }
        });

        console.log("data", data);
      });
    },
  },
};
