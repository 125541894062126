<template>
  <el-dialog title="用户选择" :visible.sync="deptSelect.open" :width="deptSelect.width" append-to-body @close="onSelectClose">
    <div class="fx_member_select">
      <div class="select-menu">
        <div class="select-btn select">成员</div>
        <div class="select-search-pane" :style="{'width':(isFocus ? '100%' : '114px')}">
          <div style="margin-top:6px;">
          <el-input size="mini" type="text" v-model="memberParams.searchTxt"  placeholder="搜索" @keyup.native="onChangeSearchTxt" @focus="onFocusSearchTxt" @blur.native="onBlurSearchTxt">
            <el-button slot="append" @click="onChangeSearchTxt" icon="el-icon-search" v-if="isFocus"></el-button>
          </el-input>
          </div>
          <!-- <svg-icon icon-class="del" style="width: 12px; height: 12px; position: absolute; right: 20px; top: 12px; cursor: pointer" @click="onClearSearchTxt" v-if="memberParams.searchTxt != ''" /> -->
        </div>
      </div>
      <div class="select-pane">
        <div class="member-pane">          
          <div class="member-wrapper" v-if="memberList && memberList.length > 0">
            <div class="select-member" @scroll="memberListScroll">
              <ul>
                <li v-for="(item,index) in memberList" :key="index" @click="onRadioSelect(item)">
                  <div class="user-name fl" :class="{mobile: isMobile}">
                    <template v-if="!isMobile">
                      <span style="display:inline-flex;width:120px;align-items:center">
                        <span class="user-card-avatar" v-if="item.avatarUrl" :style="{backgroundImage:'url('+item.avatarUrl+'?x-oss-process=image/resize,m_fixed,h_100,w_100)'}"></span>
                        <span class="user-card-avatar" v-else>{{item.name.substring(0,1)}}</span>
                        <el-tooltip effect="light" placement="top" :open-delay="300">
                          <div slot="content">
                            <div>编号：{{item.number}}</div>
                            <div v-if="item.agencyUserDeptNames && item.agencyUserDeptNames.length > 0">所属部门：
                              <div v-for="(dpt,dptIdx) in item.agencyUserDeptNames" :key="dptIdx">{{dptIdx+1}}.{{dpt}}</div>
                            </div>
                          </div>
                          <span class="user-card-name" style="width:240px">{{item.name}}</span>
                        </el-tooltip>
                      </span>
                      <span style="margin-left:15px;width:200px;display:inline-block;overflow:none;">编号：{{item.number}}</span>
                      <span style="margin-left:15px;width:200px;display:inline-block;overflow:none;" v-if='item.agencyUserDeptNames&&item.agencyUserDeptNames.length>0'>部门：{{item.agencyUserDeptNames[0]}}</span>
                    </template>
                    <template v-else>
                      <div style="display:inline-flex;width:100%;align-items:center;">
                        <span class="user-card-avatar" v-if="item.avatarUrl" :style="{backgroundImage:'url('+item.avatarUrl+'?x-oss-process=image/resize,m_fixed,h_100,w_100)'}"></span>
                        <span class="user-card-avatar" v-else>{{item.name.substring(0,1)}}</span>
                        <el-tooltip effect="light" placement="top" :open-delay="300">
                          <template slot="content">
                            <div>编号：{{item.number}}</div>
                            <div v-if="item.agencyUserDeptNames && item.agencyUserDeptNames.length > 0">所属部门：
                              <div v-for="(dpt,dptIdx) in item.agencyUserDeptNames" :key="dptIdx">{{dptIdx+1}}.{{dpt}}</div>
                            </div>
                          </template>
                          <div style="display:inline-block;height:30px;line-height:30px;margin-left:15px;display:inline-block;width:100px">{{item.name}}</div>
                        </el-tooltip>
                        <div class="user-card-code">
                          编号：{{item.number}}
                        </div>
                      </div>

                      <div class="user-card-dept">部门：{{item.agencyUserDeptNames&&item.agencyUserDeptNames.length>0?item.agencyUserDeptNames[0]:'--'}}</div>
                    </template>
                  </div>
                  <div class="select-check fr" :class="{select:isItemSelected(item)}">
                    <i class="icon-blank"></i>
                  </div>

                </li>
              </ul>
            </div>
          </div>
          <div class="member-wrapper" v-else>
            <span class="empty-tips">{{this.fieldOptions && this.fieldOptions.scopeType===''?'请搜索用户信息':'没有可选成员'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer" style="margin-top: -20px;">
      <el-button @click="onSelectClose">取 消</el-button>
      <el-button type="primary" @click="onSelectConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getSessionStorageObj, getLocalStorageObj } from '@/utils/db'
import { agencyUserList } from "@/api/system/agency";
import { isMobile } from '@/utils/index'
export default {
  data () {
    return {
      deptSelect: {
        open: false,
        width: isMobile() ? '360px' : '800px'
      },
      memberParams: {
        agencyId: null,
        searchTxt: ''
      },
      isFocus: false,
      isMobile: isMobile(),
      pageNum: 1,
      pageSize: 13,
      agencyDept: {},
      memberList: [],
      userList: [],
      fieldOptions: {}
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    callback: {
      type: Function
    },
    formDetail:{
      type:Object,
      default: ()=>{}
    }
  },
  created () {
  },
  mounted () {
   
  },
  watch: {
    value:{
      deep: true,
      immediate: true,
      handler: function(){
        this.userList = this.value.filter(x=>x!=null && x!=undefined);
      }
    }
  },
  methods: {
    initUserDeptAndRole(){
      return new Promise((resolve)=>{
         let sessionStorageUser = getSessionStorageObj('users')
          if(!sessionStorageUser || !sessionStorageUser.userId)
              resolve();
          agencyUserList({
            pageNum: 1,
            pageSize: 1,
            userId: sessionStorageUser.userId
          }).then(res => {
            if (res.rows && res.rows.length > 0) {
              if (res.rows[0].agencyUserDeptIds != null)
                this.userDeptId = res.rows[0].agencyUserDeptIds.shift();

              if (res.rows[0].agencyUserRoleIds != null)
                this.userRoleId = res.rows[0].agencyUserRoleIds.shift();

            }
              resolve();
          })
      })
    },
    // onDeptSelectClick(data) {
    async showDialog (selectedUserList, fieldOptions={}) {
      let _this = this
      _this.pageNum = 1;
      _this.memberList = [];
      _this.fieldOptions = fieldOptions;   
      
      await this.initUserDeptAndRole();

      if (selectedUserList) {
        _this.userList = [...selectedUserList].filter(x=>x!=null && x!=undefined);
      } else {
        _this.userList = [];
      }
      _this.getListMember(false)
      _this.$nextTick(function () {
        _this.deptSelect.open = true
      })
    },
    getListMember (append = false) {
      let _this = this

      const fieldOptions = this.fieldOptions;
      let scope = {
        agencyDeptId: (() => {
          if (fieldOptions.scopeType === 'DEPT') {
            return fieldOptions.scopeId
          } else if (fieldOptions.scopeType === 'USERDEPT') {
            return this.userDeptId
          } else {
            return undefined
          }
        })(),
        agencyRoleId: (() => {
          if (fieldOptions.scopeType === 'ROLE') {
            return fieldOptions.scopeId
          } else if (fieldOptions.scopeType === 'USERROLE') {
            return this.userRoleId
          } else {
            return undefined
          }
        })()
      }
      
      agencyUserList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        agencyId: this.fieldOptions.agencyId||this.formDetail.agencyId,
        // name: (fieldOptions.scopeType === 'SEARCHUSER' && !_this.memberParams.searchTxt) ? 'SEARCHUSER' : _this.memberParams.searchTxt,
        searchText: (fieldOptions.scopeType === 'SEARCHUSER' && !_this.memberParams.searchTxt) ? 'SEARCHUSER' : _this.memberParams.searchTxt,
        ...scope
      }).then(response => {
        if (response.code == 200) {
          if (append) {
            let memberLen = this.memberList.length;
            let respLen = response.rows.length;
            if (memberLen > 0 && _this.memberList[memberLen - 1].agencyUserId != response.rows[respLen - 1].agencyUserId)
              _this.memberList = [..._this.memberList, ...response.rows];
          } else {
            _this.memberList = response.rows;
          }
        } else {
          //   _this.msgError(response.msg);
        }
      });
    },
    onRadioSelect (item) {
      if(this.fieldOptions.single){
        this.userList.splice(0)
      }
      if (this.isItemSelected(item)) {
        let idx = this.userList.findIndex(v => v.agencyUserId == item.agencyUserId)
        this.userList.splice(idx, 1);
      } else {
        this.userList.push(item)
      }
    },

    onFocusSearchTxt () {
      this.isFocus = true
    },
    onBlurSearchTxt () {
      this.isFocus = false
    },
    onChangeSearchTxt () {
      this.pageNum = 1;
      this.getListMember()
    },
    onSelectClose () {
      this.radioIndex = null
      this.deptSelect.open = false
    },
    onClearSearchTxt () {
      this.memberParams.searchTxt = ""

      this.isFocus = false
      this.getListMember();
    },
    onSelectConfirm () {
      let _this = this
      //   if (_this.userList.length == 0) {
      //     this.$message({
      //       type: 'warning',
      //       message: '请选择用户'
      //     })
      //     return
      //   }
      this.$emit("on-change", this.userList)
      this.$emit("input", this.userList)

      //   let selectedMemberList=[];
      //   this.memberList.forEach(member=>{
      //     let m= this.userList.find(x=>x==member.agencyUserId)
      //     if(m) selectedMemberList.push(member);
      //   })
      if (this.callback) this.callback(this.userList);
      this.deptSelect.open = false
    },
    memberListScroll () {
      const clientHeight = event.target.clientHeight;
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      if (clientHeight + scrollTop === scrollHeight) {
        this.pageNum++;
        this.getListMember(true);
      }
    },
    isItemSelected (item) {
      return !!this.userList.find(v => v.agencyUserId == item.agencyUserId);
    }

  }

}
</script>
<style lang="scss" scoped>
.fx_member_select {
  margin-top: -30px;
  .select-menu {
    position: relative;
    height: 40px;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
    .select-btn {
      text-align: center;
      display: inline-block;
      cursor: pointer;
      line-height: 38px;
      height: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 5px;
      margin: 0 10px;
      &.select {
        color: #3476F0;
        border-bottom: solid 4px #3476F0;
      }
    }
    .select-search-pane {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 114px;
      background: #fff;
      padding: 0 10px;
      box-sizing: border-box;
      input {
        line-height: 26px !important;
        height: 26px !important;
        width: 100%;
        border: 0;
        background: #f4f4f4;
        border-radius: 13px;
        margin-top: 6px;
        padding: 0 10px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        &:focus {
          border: none;
          outline: 0;
        }
      }
    }
  }
  .select-pane {
    border: 1px solid #e0e0e0;
    border-top: none;
    height: 320px;
    .member-pane {
      .member-wrapper {
        width: 100%;
        .empty-tips {
          display: inline-block;
          margin: 10px;
          color: #989898;
          width: 100%;
          text-align: center;
        }
        .select-all-item {
          cursor: pointer;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-box-pack: space-between;
          -ms-flex-pack: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center;
          padding: 0 8px 0 12px;
          border-bottom: solid 1px #e9e9e9;
          .count-label {
            color: #3476F0;
          }
        }
        .select-member {
          height: 320px;
          overflow-y: auto;
          ul {
            margin: 0;
            padding: 0;
            li {
              cursor: pointer;
              padding: 2px 10px;
              position: relative;
              // height: 40px;
              line-height: 40px;
              width: 100%;
              box-sizing: border-box;
              display: inline-flex;
              justify-content: space-between;
              border-bottom: solid 1px #e9e9e9;
              &:hover {
                background: #ebf8fb;
              }

              &.mobile {
                line-height: 30px;
              }
              .user-name {
                width: calc(100% - 20px);
                // overflow: hidden;
                height: 40px;
                line-height: 40px;
                display: inline-flex;
                align-items: center;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                vertical-align: middle;

                &.mobile {
                  height: 60px;
                  line-height: 30px;
                  flex-direction: column;
                }
              }
              .select-check {
                cursor: pointer;
                width: 16px;
                .icon-blank {
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  border: 1px solid #91a1b7;
                  position: relative;
                  background: #fff;
                  -webkit-transition: all 218ms ease-in-out;
                  -moz-transition: all 218ms ease-in-out;
                  -o-transition: all 218ms ease-in-out;
                  transition: all 218ms ease-in-out;
                  &::before {
                    content: '';
                    display: block;
                    opacity: 0;
                    -webkit-transition: opacity 218ms ease-in-out;
                    -moz-transition: opacity 218ms ease-in-out;
                    -o-transition: opacity 218ms ease-in-out;
                    transition: opacity 218ms ease-in-out;
                    height: 6px;
                    width: 10px;
                    border-left: solid 2px #fff;
                    border-bottom: solid 2px #fff;
                    position: absolute;
                    top: 2px;
                    left: 1px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                  }
                }
                &.select {
                  .icon-blank {
                    border-color: #3476F0;
                    background: #3476F0;
                    &::before {
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .tree-wrapper {
        width: 100%;
        height: 320px;
        box-sizing: border-box;
        overflow-y: auto;
        padding-top: 10px;
      }
    }
  }
}

.user-card-name {
  margin-left: 15px;
  display: inline-block;
  height: 30px;
  overflow: hidden;
  line-height: 30px;
}

.user-card-avatar {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 3px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background-color: #5b2bf6;
  background-size: cover;
  background-position: center center;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  vertical-align: middle;
}

.user-card-code {
  display: inline-block;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 1;
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-card-dept {
  display: inline-block;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  padding-left: 40px;
  height: 30px;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
