<template>
    <div class="my-filter-input">
        <el-input type="text" v-model="option.data" placeholder="请输入筛选内容" size="small" @keyup.enter.native="confirmFilter" @input="changeOptionEvent"></el-input>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterString',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;
            
            $panel.changeOption(null, checked, option);
        },
        clearFilter(){
            const { $panel } = this.params;
            this.option.data= undefined;
            this.$nextTick(()=>{
                $panel.resetFilter();
            })
        },
        // filterMethod({ value, row, column }){
        //      console.log("filter String filterMethod",value, row, column );
        // },
        confirmFilter() {
            const { $panel } = this.params;
            // console.log("confirmFilter",this.params,this.option,this.column)

            // this.params.$table.onFilterChanged();

            // let filterObj={
            //     name: this.column.field,
            //     value: this.option.data,
            //     label:this.column.params.title
            // }
            $panel.confirmFilter();
        },
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
}
</style>