<template>
  <div class="my-filter-input">
    <div style="max-height:200px;overflow:hidden;margin:10px 0px;" v-if="column && column.params.optionsItem">
      <el-cascader v-model="option.data" :options="column.params.optionsItem" :props="{ checkStrictly: true }" class="cascader-filter">
      </el-cascader>
    </div>
    <div style="text-align:center;margin-top:5px;">
      <el-button size="mini" @click="clearFilter">清空</el-button>
      <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterCascader',
  props: {
    params: Object,
  },
  data () {
    return {
      column: null,
      option: null,
      filterText: ''
    };
  },
  mounted () {
    this.load();
  },
  watch: {
    'params.column.field': {
      handler () {
        this.load();
      },
      deep: true,
    },
  },
  methods: {
    load () {
      const { column } = this.params;
      const option = column.filters[0];
      this.column = JSON.parse(JSON.stringify(column));
      this.option = option;

      // 在this.option最前面加上未选择
      console.log('optionItems', this.column.params.optionsItem)
      this.column.params.optionsItem.unshift({
        label:'未选择',
        value: ''
      })

      console.log('column', column.field)
    },
    changeOptionEvent () {
      const { params, option } = this;
      const { $panel } = params;
      const checked = !!option.data;
      console.log(option.data);
      $panel.changeOption(null, checked, option);
    },
    clearFilter () {
      this.option.data = [];
      const { $panel } = this.params;
      $panel.resetFilter();
    },
    confirmFilter () {
      const { $panel } = this.params;
      const checked = !!this.option.data;

      //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
      $panel.changeOption(null, checked, this.option);
      $panel.confirmFilter(this.params);
    },
  },
};
</script>

<style scoped>
.my-filter-input {
  padding: 10px;
}

.cascader-filter{
::v-deep .el-cascader-node__label{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
}
</style>