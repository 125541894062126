<template>
     <!-- 审核结果查看对话框 -->
    <el-dialog title="审核结果" :visible.sync="showAuditRemarkDialog" v-if="showAuditRemarkDialog" width="600px" append-to-body>
      <div style="margin-top:-20px;">
        <div class="audit-view-remark" style="margin-bottom:10px;">
          当前状态：<span> 
            <StatusCode :statusCode="clickRow.status"></StatusCode>
          </span>
        </div>
        <template v-if="auditLog.length>0">
        <div style="max-height:450px;overflow-y:auto;">
          <div class="signResult-view-item" v-for="(audit,index) in auditLog" :key="index">
            <div class="signResult-view-item-title" style="display:flex;justify-content:space-between">
              <div style="display:flex">
                <!-- <template v-if="getAuditLevel(audit.oldStatus)==1">
                  一级审核
                </template>
                <template v-if="getAuditLevel(audit.oldStatus)==2">
                  二级审核
                </template>
                <template v-if="getAuditLevel(audit.oldStatus)==3">
                  三级审核
                </template> -->
                <!-- <div v-if="getAuditLevel(audit.oldStatus)<getAuditLevel(audit.newStatus)">提交到</div> -->
                <!-- <div v-if="getAuditLevel(audit.oldStatus)>=getAuditLevel(audit.newStatus)">返回到</div> -->
               <span style="font-weight:normal;">{{moment(audit.time).format('YYYY-MM-DD HH:mm:ss')}}</span>
               <span style="font-weight:normal;margin-left:10px">{{audit.userName}}</span>
              </div>
              <div style="display:inline-flex">
                {{statusText(audit.oldStatus,audit.newStatus)}}
              </div>
            </div>
            <div class="audit-view-remark" v-if="audit.remark">
              <div>意见: {{audit.remark}}</div>
            </div>
            <div class="audit-view-sign" v-if="audit.signUrl">
              <span class="audit-sign-view" style="width:120px;height:64px; margin: 0px 2px;" 
                :style="{ backgroundImage: 'url(\''+audit.signUrl+'\')'}"></span>
            </div>
          </div>
        </div>
        </template>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="showAuditRemarkDialog=false">关闭</el-button>
      </div>
    </el-dialog>
</template>
<script>
import moment from "moment";
import StatusCode from "../statusCode.vue";
import { auditLog } from "@/api/system/form";
import { getAuditLevelByStatus }from "@/utils/formUtil";
export default {
    components: { StatusCode },
    data(){
        return {
            showAuditRemarkDialog: false,
            auditLog:[],
            clickRow:null,
            moment: moment
        }
    },
    methods:{
        show(formId,fillId,fillInfo){
            let row = fillInfo;
            this.clickRow= row;
            auditLog(formId,fillId).then(res=>{
                this.auditLog = res.data;
                this.showAuditRemarkDialog = true;

                let auditLevelAudit = [2,6,10]
                let auditLevelAuditText= ['待一级审核','待二级审核','待三级审核']

                for(let i=1;i<=3;i++){
                  // 处理历史数据， 通过审核时间判断是否有新增数据
                if(row[`auditAuth${i}Time`]!=null &&!this.auditLog.find(x=>moment(x.time).isSame(row[`auditAuth${i}Time`],'second'))){
                    this.auditLog.push({
                    userName: row[`auditAuth${i}Operator`]?.name,
                    oldStatus: auditLevelAudit[i-1], // 完成了 待一级审核
                    newStatus: -1,
                    newStatusText: auditLevelAuditText[i-1],
                    time: row[`auditAuth${i}Time`],
                    signUrl: row[`auditAuth${i}Sign`]
                    })
                }
                }

                this.auditLog.sort((x,y)=>{
                return moment(x.time).diff(moment(y.time),'second')
                })

            })
        },
        getAuditLevel(status){
          return getAuditLevelByStatus(status);
        },
        statusText:function(oldStatus,newStatus){
          let auditLevel = this.getAuditLevel(oldStatus);
          let text='';
          text+=auditLevel==1?'一级审核：':
                auditLevel==2?'二级审核：':
                auditLevel==3?'三级审核：':
                auditLevel==100?'审核完成':'';

          if(newStatus< oldStatus && newStatus!=-1){
            // isEdit
            text+= [4,5,8,9,12,13,14].indexOf(oldStatus)>-1? ' 修改为':' 返回到'
            text+=newStatus==2? ' 待一级审核':
                  newStatus==6?'  待二级审核':
                  newStatus==10?'  待三级审核':
                  '';
          }else{
          switch(newStatus){
            case 2: 
            case 3: 
            case 6:
            case 7:
            case 10:
            case 11:
              text+=' 审核通过'; break;
            case 4:             
            case 8:
            case 12:
              text+=' 审核不通过'; break;
            case 5: 
            case 9:
            case 13:
              text+=' 返回修改'; break;
            
            case 14:
              text+=' 审核完成'; break;
            case -1: // 根据oldSataus 自动推算到 下一级的待审核
              text+=' 完成';break;
          }
          }
          return text;
          
        }
    }
}
</script>

