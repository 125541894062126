var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c(
      "div",
      {
        staticClass: "main-container",
        class: { hasTagsView: _vm.needTagsView },
        staticStyle: { "margin-left": "0" },
      },
      [
        this.$route.meta.flag
          ? _c(
              "div",
              { class: { "fixed-header": _vm.fixedHeader } },
              [_c("navbar")],
              1
            )
          : _vm._e(),
        _c("app-main"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCustomer,
                expression: "showCustomer",
              },
            ],
            staticClass: "customer-service",
          },
          [
            _c("a", { staticClass: "icon-chat", attrs: { href: "#" } }, [
              _c("div", { staticClass: "chat-tips" }, [
                _c("i"),
                _c("img", { attrs: { src: _vm.chatImg, alt: "微信公众号" } }),
                _vm._m(0),
                _c("p", [_vm._v("工作时间：工作日09:00-18:00")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "chat-close", on: { click: _vm.onCloseService } },
              [
                _c("svg-icon", {
                  staticStyle: { width: "14px", height: "14px" },
                  attrs: { "icon-class": "close" },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("如果有疑问或建议，"),
      _c("br"),
      _vm._v("请绑定公众号并直接公众号沟通"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }