import { fillList, fillListSearch } from "@/api/system/form";
import {
  getAgencyUser,
  agencyUserList,
  getAgencyUserInfo,
} from "@/api/system/agency";
import { getSessionStorageObj } from "@/utils/db";

import {
  payInfo,
  statusInfo,
  fillStatusInfo,
  agencyInfo,
  extraFillInfo,
} from "@/utils/grid.js";


const formId=(fillSelectOption)=>{
  
  if(fillSelectOption.options.fillSource){
    return fillSelectOption.options.fillSource.formId
  }else {
    return fillSelectOption.options.from;
  }
}

export const fillSearch = async (
  fillSelectOption,
  formData,
  search,
  searchObject,
  pager,
  showField
) => {
  const express = fillSelectOption.express;

  const queryValueBuilder = async (expressItem) => {
    const myUserInfoInAgency = async () => {
      //取formAgencyId 是因为只能从表单的机构中获取用户信息 , 机构外部用户可能没有机构用户信息，所以查不出数据
      var agencyId = myAgencyId(); // getSessionStorageObj("agencyId");

      const userInfo = await getAgencyUserInfo(agencyId);
      return userInfo.data;
    };

    const myAgencyId = () => {
      // const userInfo = await myUserInfoInAgency();
      // return userInfo.agencyId;
      return fillSelectOption.formAgencyId;
    };

    return new Promise(async (resolve) => {
      if (expressItem.valueType === "formField") {
        //字段
        //判断是否是系统字段
        // if(列表是个人填报，取当前用户信息，否则取填报人信息)
        // let useUserInfo = // 使用当前用户信息
        //   fillSelectOption.listType == "FILL" ||
        //   fillSelectOption.listType == null ||
        //   fillSelectOption.listType == undefined ||
        //   fillSelectOption.listType == "undefined";
        const sessionStorageUser = getSessionStorageObj("users");

        //如果界面没有fillId是新填报使用当前用户的userId，否则使用填报人userId
        let useUserInfo = !fillSelectOption.fillId; 

        let fillUserId = useUserInfo==true //使用当前用户信息
          ? Number(sessionStorageUser.userId).toString():
            fillSelectOption.fillInfo.fillUserId;
            
        let agencyUserRes = await agencyUserList({
            pageNum:1,
            pageSize:1,
            agencyId: myAgencyId(),
            userId: fillUserId,
        })

        const agencyUserInfo = agencyUserRes.rows[0];

        if (expressItem.value === "userId") {
          resolve(fillUserId.toString());
        } else if (expressItem.value === "agencyUserId") {
          resolve(agencyUserInfo.agencyUserId.toString());
        } else if (expressItem.value === "userNumber") {
          resolve(agencyUserInfo.number.toString());
        } else if (expressItem.value === "userDeptId") {
          const userInfo = await myUserInfoInAgency();
          resolve((agencyUserInfo.agencyUserDeptNames || []).toString());
        } else if (expressItem.value === "userRoleId") {
          const userInfo = await myUserInfoInAgency();
          resolve((agencyUserInfo.agencyUserRoleNames || []).toString());
        } else if (/.*\[.*\]/.test(expressItem.value)) {
          const reg = /\b(\w+-\w+|\w+)/g;
          // 提取正则表达式reg的内容
          const matches = expressItem.value.match(reg); // 使用match()方法匹配

          if (!formData) resolve();
          let searchValue = "";
          if (matches[0] === "fillselect") {
            if (
              formData[matches[1]] &&
              formData[matches[1]].selectList &&
              formData[matches[1]].selectList.length > 0
            ) {
              searchValue = formData[matches[1]].selectList[0][matches[2]];
            }
            resolve(searchValue);
          } else if (matches[0] === "subform") {
            if (
              formData[matches[1]] &&
              formData[matches[1]].length > fillSelectOption.subFormRowIndex
            ) {
              searchValue =
                formData[matches[1]][fillSelectOption.subFormRowIndex][
                  matches[2]
                ];
            }
            resolve(searchValue);
          } else if (matches[0] === "user-select") {
            if (formData[matches[1]] && formData[matches[1]].length > 0) {
              resolve(
                formData[matches[1]]
                  .map((x) => x[matches[2] || "userId"])
                  .join(",")
              );
            }
          }else{
            if (formData && formData[matches[1]])
              resolve(formData[matches[1]]);
          }
        } else {
          if (formData && formData[expressItem.value])
            resolve(formData[expressItem.value]);
        }
      } else {
        //固定值
        if(Array.isArray(expressItem.value)){
          resolve(expressItem.value.join(","));
        }else{
          resolve(expressItem.value);
        }
      }
    });
  };

  const queryKeyBuilder = (expressItem) => {
    let key = "";
    ["radio", "checkbox", "select", "cascader"].forEach((x) => {
      if (
        expressItem.key.indexOf(x) >= 0 &&
        expressItem.operator == "in" &&
        expressItem.valueType == "fixedValue"
      ) {
        key = expressItem.key //+ "__label";
      }
      if (
        expressItem.key.indexOf(x) >= 0 &&
        expressItem.operator == "bic" &&
        (expressItem.value == "userDeptId" || expressItem.value == "userRoleId")
      ) {
        key = expressItem.key //+ "__label";
      }
    });
    if (key) return key;
    return expressItem.key;
  };

  const searchCriteriaBuilder = () => {
    let searchArray = [];
    // searchObject 转换成 searchArrray, key,value
    for (let key in searchObject) {
      if (searchObject[key] && searchObject[key].length > 0) {
        var searchObjectValue
        if(Array.isArray(searchObject[key])){
          searchObjectValue = searchObject[key].join(",");
        }else{
          searchObjectValue = searchObject[key];
        }
        searchArray.push({
          key: key,
          value: searchObjectValue
        });
      }
    }
    return searchArray;
  };

  const searchOrdersBuilder = () => {
    return fillSelectOption.sortData;
  };

  const queryParams = async () => {
    if (express) {
      let rcsList = [];

      for (let i = 0; i < express.list.length; i++) {
        let x = express.list[i];
        if (!x.key) continue;
        rcsList.push({
          value: await queryValueBuilder(x),
          operator: x.operator,
          key: queryKeyBuilder(x), //x.key,
          type: x.type,
        });
      }

      return {
        pageSize: pager.pageSize,
        pageNo: pager.pageNo,
        search: search,
        connector: (express.connector || "and").toLowerCase(),
        rcs: rcsList,
        orders: searchOrdersBuilder(),
        searchCriteria: searchCriteriaBuilder(),
        showField
      };
    } else {
      return {
        search: search || "",
        connector: "and",
        rcs: [],
        searchCriteria: searchCriteriaBuilder(),
      };
    }
  };

  let param = await queryParams();

  return new Promise((resolve) => {
    if ( !formId(fillSelectOption) || formId(fillSelectOption) == "0" ) {
      resolve({ data: [], total: 0 });
      return;
    }

    fillListSearch(formId(fillSelectOption), param).then(
      (response) => {
        const data = [];
        for (let i = 0; i < response.data.rows.length; i++) {
          const item = response.data.rows[i];
          const obj = {
            ...item,
            ...item.fillJson,
            ...payInfo(item), // 从item 获取支付信息
            ...statusInfo(item), // 从item获取状态信息
            ...fillStatusInfo(item, false), // 从item获取填报确认状态 导入待确认/导入已确认 参数2:是否显示批量填报的状态
            ...agencyInfo(item), // 从item获取机构信息
            ...extraFillInfo(item),
          };
          data.push(obj);
        }

        resolve({ data, total: response.data.total });
      }
    );
  });
};
