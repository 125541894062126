import request from '@/utils/request'
import crypto from "@/utils/crypto";

// 登录方法
export function login(userInfo) {
  return request({
    url: '/login',
    method: 'post',
    data: crypto.encrypt(JSON.stringify(userInfo))
  })
}

// 手机号登录方法
export function loginByMobile(userInfo) {
  return request({
    url: '/login/mobile',
    method: 'post',
    data: crypto.encrypt(JSON.stringify(userInfo))
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/user',
    method: 'get'
  })
}


// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'post'
  })
}

//获取微信小程序码进行登录
export function getQrImg() {
  return request({
    url: '/loginQrImg',
    method: 'get'
  })
}

//微信小程序登录状态查询
export function qrLoginStatus(uuid) {
  return request({
    url: '/qrLoginStatus/'+uuid,
    method: 'get'
  })
}

// 手机号登录验证码
export function sendMobileCode(param) {
  return request({
    url: '/sendLoginCode',
    method: 'post',
    data: param
  })
}

// 忘记、重置密码 获取短信验证码
export function getPwdMobileCaptcha(param) {
  return request({
    url: '/forgetPwdMobileCaptcha',
    method: 'post',
    data: param
  })
}

// 重置密码
export function resetPwd(param) {
  return request({
    url: '/security/resetPwd',
    method: 'post',
    data: param
  })
}

// 修改密码
export function updatePwd(param) {
  return request({
    url: '/security/updatePwd',
    method: 'post',
    data: JSON.stringify(param)
  })
}

// 获取注册验证码
export function sendRegisterCode(sendRegisterCodeParam) {
  return request({
    url: "/sendRegisterCode",
    method: "post",
    data: JSON.stringify(sendRegisterCodeParam)
  });
}

// 注册
export function handleRegister(data) {
  return request({
    url: "/register",
    method: "post",
    data: data
  });
}

// 绑定手机号、邮箱获取验证码
export function sendBindingCode(sendBindingCodeParam) {
  return request({
    url: "/sendBindingCode",
    method: "post",
    data: JSON.stringify(sendBindingCodeParam)
  });
}

// 更换手机号、邮箱 验证身份 点击获取已绑定手机、邮箱验证码
export function sendChangeBindingCode(sendChangeBindingParam) {
  return request({
    url: "/sendChangeBindingCode",
    method: "post",
    data: JSON.stringify(sendChangeBindingParam)
  });
}

// 换绑手机或邮箱验证码校验
export function checkChangeBindingCode(checkChangeBindingCodeParam) {
  return request({
    url: "/security/checkChangeBindingCode",
    method: "post",
    data: JSON.stringify(checkChangeBindingCodeParam)
  });
}

// 确认绑定手机或者邮箱
export function securityBinding(securityBindingParam) {
  return request({
    url: "/security/securityBinding",
    method: "post",
    data: JSON.stringify(securityBindingParam)
  });
}

// 强制绑定手机号
export function bindingPhone(param) {
  return request({
    url: "/security/bindingPhone",
    method: "post",
    data: JSON.stringify(param)
  });
}

