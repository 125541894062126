import QRCode from "qrcodejs2";
import { orderCreat, orderStatus } from "@/api/system/form";
import * as formApi from "@/api/system/form";
export default {
  data() {
    return {
      formPayOpen: false,
      orderPrice: 0,
      fillSuccessShow: false,
      paySuccessShow: false,
      customTip:{}
    };
  },
  methods: {
    setTimer(intMilliecond, orderNo) {
      const _this = this;
      let timeSeconds = 0;
      _this.qrTimer = setInterval(function () {
        timeSeconds += 1;
        if (timeSeconds <= 300) {
          _this.getQrPayState(orderNo);
        } else {
          clearInterval(_this.qrTimer);
        }
      }, intMilliecond);
    },
    getQrPayState(orderNo) {
      const _this = this;
      orderStatus(orderNo).then((response) => {
        if (response.code == 200) {
          console.log('here')
          if (response.data == true) {
            // _this.msgSuccess('支付成功')

            // 清空支付相关
            clearInterval(_this.qrTimer);
            document.getElementById("qrCode").innerHTML = "";
            _this.formPayOpen = false;
            // 清空表单提交成功弹框
            _this.fillSuccessShow = false;
            _this.paySuccessShow = true;
            if (_this.getFormViewListData) {
                _this.getFormViewListData();
            }
          }
        } else {
          clearInterval(_this.qrTimer);
          document.getElementById("qrCode").innerHTML = "";
        }
      });
    },
    clearFormDetail() {
      this.$refs.vformRender.resetForm();
    },
    //批量支付
    onBatchPayHandler(selectedRowKeys) {
      let _this = this;
      let paySuccess = true;
      let fillIds = [];
      selectedRowKeys.forEach((row) => {
        fillIds.push(row.fillId);
        if (row.payStatus != 2) {
          paySuccess = paySuccess && false;
        }
      });

      if (!paySuccess) {
        this.$message({
          message:
            "选中的数据中，存在“已支付”或“支付确认”，请选择未支付的数据重试",
          type: "warning",
        });
        return;
      }

      const dto = {
        fillId: fillIds.join(","),
        formId: this.formId,
      };
      formApi.orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.formPayOpen = true;
          _this.orderPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["qrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.setTimer(1000, response.data.orderNo);
        }
      });
    },
    // 表单开启支付功能，立即支付
    onConfirmPayFillClick() {
      const _this = this;
      const dto = {
        fillId: _this.formSubmitFillId,
        formId: _this.formId,
        amount: this.fillJson.__amount
          ? Number(this.fillJson.__amount || "1")
          : undefined,
      };

      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.formPayOpen = true;
          _this.orderPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["qrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.setTimer(1000, response.data.orderNo);
        }
      });
    },
    onClosePaySuccessPop(){
      this.paySuccessShow = false;
    },
     // 表单开通支付，我的填报列表，列中支付btn支付
     onPayFillClick (fillId) {
      const _this = this;
      const dto = {
        fillId: fillId,
        formId: this.formId,
      };
      orderCreat(dto).then((response) => {
        if (response.code == 200) {
          const codeUrl = response.data.codeUrl;
          _this.formPayOpen = true;
          _this.orderPrice = response.data.orderPrice;
          _this.$nextTick(() => {
            new QRCode(_this.$refs["qrCodeUrl"], {
              text: codeUrl,
              width: 200,
              height: 200,
              colorDark: "#000000",
              colorLight: "#ffffff",
              correctLevel: QRCode.CorrectLevel.H,
            });
          });
          _this.setTimer(1000, response.data.orderNo);
        }
      });
    },
    onClosePay() {
      clearInterval(this.qrTimer);
      document.getElementById("qrCode").innerHTML = "";
      // 关闭支付弹框，同时需要关闭表单提交成功弹框，跳转我的填报列表
      this.fillSuccessShow = false;
      this.formSubmitFillId = null;
    },
  },
};
