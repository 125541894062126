<template>
    <div class="my-filter-input">
        <el-select v-model="option.data" class="full-width-input" size="mini" :clearable="true" :filterable="true" :multiple="true">
            <el-option value="汉族" label="汉族"></el-option>
            <el-option value="满族" label="满族"></el-option>
            <el-option value="蒙古族" label="蒙古族"></el-option>
            <el-option value="回族" label="回族"></el-option>
            <el-option value="藏族" label="藏族"></el-option>
            <el-option value="维吾尔族" label="维吾尔族"></el-option>
            <el-option value="苗族" label="苗族"></el-option>
            <el-option value="彝族" label="彝族"></el-option>
            <el-option value="壮族" label="壮族"></el-option>
            <el-option value="布依族" label="布依族"></el-option>
            <el-option value="侗族" label="侗族"></el-option>
            <el-option value="瑶族" label="瑶族"></el-option>
            <el-option value="白族" label="白族"></el-option>
            <el-option value="土家族" label="土家族"></el-option>
            <el-option value="哈尼族" label="哈尼族"></el-option>
            <el-option value="哈萨克族" label="哈萨克族"></el-option>
            <el-option value="傣族" label="傣族"></el-option>
            <el-option value="黎族" label="黎族"></el-option>
            <el-option value="傈僳族" label="傈僳族"></el-option>
            <el-option value="佤族" label="佤族"></el-option>
            <el-option value="畲族" label="畲族"></el-option>
            <el-option value="高山族" label="高山族"></el-option>
            <el-option value="拉祜族" label="拉祜族"></el-option>
            <el-option value="水族" label="水族"></el-option>
            <el-option value="东乡族" label="东乡族"></el-option>
            <el-option value="纳西族" label="纳西族"></el-option>
            <el-option value="景颇族" label="景颇族"></el-option>
            <el-option value="柯尔克孜族" label="柯尔克孜族"></el-option>
            <el-option value="土族" label="土族"></el-option>
            <el-option value="达斡尔族" label="达斡尔族"></el-option>
            <el-option value="仫佬族" label="仫佬族"></el-option>
            <el-option value="羌族" label="羌族"></el-option>
            <el-option value="布朗族" label="布朗族"></el-option>
            <el-option value="撒拉族" label="撒拉族"></el-option>
            <el-option value="毛南族" label="毛南族"></el-option>
            <el-option value="仡佬族" label="仡佬族"></el-option>
            <el-option value="锡伯族" label="锡伯族"></el-option>
            <el-option value="阿昌族" label="阿昌族"></el-option>
            <el-option value="普米族" label="普米族"></el-option>
            <el-option value="朝鲜族" label="朝鲜族"></el-option>
            <el-option value="塔吉克族" label="塔吉克族"></el-option>
            <el-option value="怒族" label="怒族"></el-option>
            <el-option value="乌孜别克族" label="乌孜别克族"></el-option>
            <el-option value="俄罗斯族" label="俄罗斯族"></el-option>
            <el-option value="鄂温克族" label="鄂温克族"></el-option>
            <el-option value="德昂族" label="德昂族"></el-option>
            <el-option value="保安族" label="保安族"></el-option>
            <el-option value="裕固族" label="裕固族"></el-option>
            <el-option value="京族" label="京族"></el-option>
            <el-option value="塔塔尔族" label="塔塔尔族"></el-option>
            <el-option value="独龙族" label="独龙族"></el-option>
            <el-option value="鄂伦春族" label="鄂伦春族"></el-option>
            <el-option value="赫哲族" label="赫哲族"></el-option>
            <el-option value="门巴族" label="门巴族"></el-option>
            <el-option value="珞巴族" label="珞巴族"></el-option>
            <el-option value="基诺族" label="基诺族"></el-option>
        </el-select>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>


<script>
export default {
    name: 'FilterNationality',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;
            console.log(option.data);
            $panel.changeOption(null, checked, option);
        },
        clearFilter() {
            this.option.data = '';
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data;

            // this.dispatch('detail', 'hello', [{ 'this is': 'p' }]);

            //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter(this.params);
        },
        dispatch(componentName, eventName, params) {
            var parent = this.$parent || this.$root;
            var name = parent.$options.componentName;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.componentName;
                }
            }
            if (parent) {
                parent[eventName].apply(parent,params);
            }
        },
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
}
</style>