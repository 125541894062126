<template>
    <div class="my-filter-input">
        <div class="filter-data">
            <el-date-picker v-model="option.data" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FilterDate',
  props: {
    params: Object
  },
  data() {
    return {
      column: null,
      option: null
    }
  },
  created() {
    this.load()
    console.log('data', this.option)
  },
  methods: {
    load() {
      const { column } = this.params
      const option = column.filters[0]
      this.column = column
      this.option = option
    },
    changeOptionEvent() {
      const { params, option } = this
      const { $panel } = params
      const checked = !!this.option.data
      $panel.changeOption(null, checked, option)
    },
    clearFilter() {
      // this.option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59, 59)]
      this.option.data = [moment().add(-7,'days'), moment()]
      const { $panel } = this.params
      $panel.resetFilter()
    },
    confirmFilter() {
      const { $panel } = this.params
      const checked = !!this.option.data

      $panel.changeOption(null, checked, this.option)
      $panel.confirmFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-filter-input {
    padding: 10px;
}
</style>