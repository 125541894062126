<template>
  <div class="my-filter-input" v-if="option && option.data">
    <div>
      <el-input v-model="filterText" placeholder="搜索"></el-input>
    </div>
    <div style="max-height:200px;overflow-y:auto;margin:10px 0px;">
      <el-checkbox-group v-model="option.data">
        <el-checkbox label="">未填写</el-checkbox>
        <el-checkbox v-for="(item,index) in column.params.optionsItem" :key="index" v-show="filterText? item.label.indexOf(filterText) > -1 : true" :label="item.value||item">{{item.label||item}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="text-align:center;margin-top:5px;">
      <el-button size="mini" @click="clearFilter">清空</el-button>
      <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterCheckbox',
  props: {
    params: Object,
  },
  data () {
    return {
      column: null,
      option: null,
      filterText: ''
    };
  },
  mounted () {
    this.load();
  },
  watch: {
    'params.column.field': {
      handler () {
        this.load();
      },
      deep: true,
    },
  },
  methods: {
    load () {
      const { column } = this.params;
      const option = column.filters[0];
      this.column = column;
      this.option = option;
      console.log('column', column.field)
    },
    changeOptionEvent () {
      const { params, option } = this;
      const { $panel } = params;
      const checked = !!option.data;
      console.log(option.data);
      $panel.changeOption(null, checked, option);
    },
    clearFilter () {
      this.option.data = [];
      const { $panel } = this.params;
      $panel.resetFilter();
    },
    confirmFilter () {
      const { $panel } = this.params;
      const checked = !!this.option.data;

      //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
      $panel.changeOption(null, checked, this.option);
      $panel.confirmFilter(this.params);
    },
  },
};
</script>

<style scoped>
.my-filter-input {
  padding: 10px;
}
</style>