let moment = require("moment");
const fieldHeader = (field) => {
  if (field.sortType) {
  }
  let header = {
    field: field.name,
    title: field.displayName,
    sortable: field.sortable,
    sortType: field.sortType,
    fieldType: field.type,
    fixed: field.left? 'left': field.right? 'right': undefined,
    headerAlign: "left",
    align: "left",
    width: field.columnWidth || 150,
  };

  if (field.filterType) {
    header.filters = [{ data: "" }];
    header.filterRender = { name: "filter-" + field.filterType };
  }

  return header;
};

const filedColumnMap = {
  index: {
    type: "checkbox",
    width: 50,
    sortable: false,
    align: "center",
    fixed: "left",
    cellRender: { name: "cell-checkbox" },
  },
  pay: (item) => {
    let filterOptions = [
      { value: 1, label: "无需支付" },
      { value: 2, label: "待支付" },
      { value: 3, label: "已支付" },
      { value: 4, label: "确认支付" },
    ];
    return [
      {
        field: "payStatus",
        title: "支付信息",
        width: 120,
        cellRender: { name: "cell-payStatus" },

        fieldType: "payStatus",
        isSysField: true,
        optionsItem: filterOptions,
        filterRender: { name: "filter-pay" },
        filters: [{ data: "" }],
      },
      // {
      //     field: 'payStatusTxt',
      //     title: '支付状态',
      //     width: 100,
      // },{
      //     field: 'orderPriceTxt',
      //     title: '支付金额',
      //     sortable: true,
      //     width: 100,
      // },{
      //     field: 'payTime',
      //     title: '支付时间/确认时间',
      //     sortable: true,
      //     width: 150,
      // },
      // {
      //     field: 'payStatusTxt',
      //     title: '收款主体',
      //     sortable: false,
      //     width: 200,
      // }
    ];
  },
  audit: [
    // {
    //   field: "statusTxt",
    //   title: "审核状态",
    //   width: 200,
    //   slots: { default: "status_txt" },
    // },
    {
      field: "reviewRemark",
      title: "审核意见",
      width: 200,
      cellRender: { name: "cell-auditRemark" },
    },
    // {
    //   field: "reviewSign",
    //   title: "审核签名",
    //   width: 100,
    //   cellRender: { name: "cell-auditSign" },
    // },
  ],
  ownUser: [
    {
      title: "拥有者",
      width: 150,
      className: "sysfield-g",
      headerClassName: "sysfield-g",
      cellRender: { name: "cell-ownUser" },
    },
  ],
  createUser: [
    {
      title: "创建者",
      width: 150,
      className: "sysfield-g",
      headerClassName: "sysfield-g",
      cellRender: { name: "cell-createUser" },
    },
  ],
  fillUser: (item) => {
    return [
      {
        title: "填报人",
        width: 100,
        className: "sysfield-g",
        headerClassName: "sysfield-g",
        fieldType: "fillUser",
        sortType: "string",
        field: "name",
        sortable: true,
        cellRender: { name: "cell-fillUser" },
        filters: [{ data: [] }],
        filterRender: { name: "filter-userselect" },
        // fixed: 'right'
      },
      {
        field: "createTime",
        title: "填报时间",
        className: "sysfield-g",
        headerClassName: "sysfield-g",
        fieldType: "createTime",
        sortType: "string",
        sortable: true,
        isSysField: true,
        filterRender: { name: "filter-date" },
        cellRender: { name: "cell-systime" },
        filters: [{ data: [moment().add(-7, "days"), moment()] }],
        width: 100,
        // fixed: 'right'
      },
      {
        field: "updateTime",
        title: "修改时间",
        // className: "sysfield-g",
        headerClassName: "sysfield-g",
        fieldType: "updateTime",
        sortType: "string",
        sortable: true,
        isSysField: true,
        filterRender: { name: "filter-date" },
        cellRender: { name: "cell-systime" },
        filterType: "updateTime",
        filters: [{ data: [moment().add(-7, "days"), moment()] }],
        width: 110,
        // fixed: 'right'
      },
    ];
  },
  viewFillUser: (item) => {
    return {
      title: "填报人",
      className: "sysfield-g",
      headerClassName: "sysfield-g",
      fieldType: "fillUser",
      sortType: "string",
      field: "name",
      sortable: true,
      cellRender: { name: "cell-fillUser" },
      filters: [{ data: [] }],
      filterRender: { name: "filter-userselect" },
      width: 110,
      // fixed: 'right'
    };
  },
  viewCreateTime: (item) => {
    return {
      field: "createTime",
      title: "填报时间",
      className: "sysfield-g",
      headerClassName: "sysfield-g",
      fieldType: "createTime",
      sortType: "string",
      sortable: true,
      isSysField: true,
      filterRender: { name: "filter-date" },
      cellRender: { name: "cell-systime" },
      filters: [{ data: [moment().add(-7, "days"), moment()] }],
      width: 100,
      // fixed: 'right'
    };
  },
  viewUpdateTime: (item) => {
    return {
      field: "updateTime",
      title: "修改时间",
      // className: "sysfield-g",
      headerClassName: "sysfield-g",
      fieldType: "updateTime",
      sortType: "string",
      sortable: true,
      isSysField: true,
      filterRender: { name: "filter-date" },      
      cellRender: { name: "cell-systime" },
      filterType: "updateTime",
      filters: [{ data: [moment().add(-7, "days"), moment()] }],
      width: 100,
      // fixed: 'right'
    };
  },
  viewPay: (item) => {
    return {
      field: "payStatus",
      title: "支付信息",
      cellRender: { name: "cell-payStatus" },

      fieldType: "payStatus",
      isSysField: true,
      optionsItem: [
        { value: 1, label: "无需支付" },
        { value: 2, label: "待支付" },
        { value: 3, label: "已支付" },
        { value: 4, label: "确认支付" },
      ],
      filterRender: { name: "filter-select" },
      filters: [{ data: "" }],
      width: 130,
    };
  },

  dataFillStatus: (item) => {
    return {
      field: "fillStatusTxt",
      title: "批量导入状态",
      fieldType: "fillStatusTxt",
      className: "sysfield-r",
      headerClassName: "sysfield-r",
      width: 200,
    };
  },
  agencyDept: [
    {
      field: "agencyDeptLevel1",
      title: "所属一级部门",
      width: 200,
    },
    {
      field: "agencyDeptLevel2",
      title: "所属二级部门",
      width: 200,
    },
  ],
  agencyDeptCell: {
    title: "所属部门",
    width: 200,
    align: "left",
    className: "sysfield-g",
    headerClassName: "sysfield-g",
    fieldType: "sysDept",
    // filters: [{data:{ }}],
    // filterRender:{name:'filter-depart'},

    filters: null,
    filterRender: null,
    cellRender: { name: "cell-depart" },
    optionsItem: {},
  },
  status: {
    title: "当前状态",
    width: 150,
    className: "sysfield",
    fieldType: "sysStatus",

    headerClassName: "sysfield",
    slots: { default: "status_text" },
  },
  operate: [
    {
      title: "当前状态",
      width: 150,
      className: "sysfield",
      fieldType: "sysStatus",

      headerClassName: "sysfield",
      slots: { default: "status_text" },
    },
    {
      title: "操作",
      width: 300,
      className: "sysfield",
      headerClassName: "sysfield",
      slots: { default: "operate" },
      fixed: "right",
    },
  ],
  viewOperate: {
    title: "操作",
    // width: 160, // 操作列的宽度直接在页面里设置
    // minWidth: 110,
    className: "sysfield",
    type: "operator",
    headerClassName: "sysfield",
    slots: { default: "operate" },
    fixed: "right",
  },
  remove: [
    {
      title: "操作",
      width: 100,
      className: "sysfield",
      headerClassName: "sysfield",
      slots: { default: "operate" },
      fixed: "right",
    },
  ],
  name: (item) => {
    return {
      ...fieldHeader(item),
      width: 120,
    };
  },
  gender: (item) => {
    return {
      optionsItem: item.filterOptions,
      ...fieldHeader(item),
      width: 100,
    };
  },
  mail: (item) => {
    return {
      ...fieldHeader(item),
      width: 160,
    };
  },
  mobile: (item) => {
    return {
      ...fieldHeader(item),
      width: 100,
    };
  },
  idcard: (item) => {
    return {
      ...fieldHeader(item),
      width: 160,
    };
  },
  region: (item) => {
    return {
      filterOptions: item.filterOptions,
      ...fieldHeader(item),
      cellRender: { name: "cell-region" },
    };
  },
  address: (item) => {
    return {
      ...fieldHeader(item),
      filterOptions: item.filterOptions,
      cellRender: { name: "cell-address" },
      width: 250,
    };
  },
  nationality: (item) => {
    return {
      ...fieldHeader(item),
      width: 140,
    };
  },
  university: (item) => {
    return {
      ...fieldHeader(item),
      filterRender: { name: "filter-university" },
      cellRender: { name: "cell-university" },
    };
  },
  input: (item) => {
    return {
      ...fieldHeader(item),
      // editRender: {name: 'edit-input'}
    };
  },
  textarea: (item) => {
    return {
      ...fieldHeader(item),
    };
  },
  number: (item) => {
    return {
      ...fieldHeader(item),
      filters: [{ data: {} }],
      width: 140,
    };
  },
  radio: (item) => {
    return {
      ...fieldHeader(item),
      isOpenColor: item.isOpenColor,
      optionsItem: item.filterOptions,
      cellRender: { name: "cell-radio" },
      width: 140,
    };
  },
  checkbox: (item) => {
    return {
      ...fieldHeader(item),
      isOpenColor: item.isOpenColor,
      filters: [{ data: [] }],
      optionsItem: item.filterOptions,
      cellRender: { name: "cell-checkbox" },
    };
  },
  select: (item) => {
    let data = [{ data: [] }];
    if (item.filterType == "radio") {
      data = [{ data: {} }];
    }

    return {
      ...fieldHeader(item),
      isOpenColor: item.isOpenColor,
      filters: data,
      optionsItem: item.filterOptions,
      cellRender: { name: "cell-" + item.filterType },
      width: 140,
    };
  },
  time: (item) => {
    let moment = require("moment");
    return {
      ...fieldHeader(item),
      // filters: [{data:[new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)]}],
      filters: [{ data: [moment().add(-7, "days"), moment()] }],
      width: 140,
    };
  },
  "time-range": (item) => {
    return {
      ...fieldHeader(item),
      filterRender: null,
      filters: null,
      width: 280,
    };
  },
  date: (item) => {
    let moment = require("moment");
    return {
      ...fieldHeader(item),
      filters: [{ data: "" }],
      width: 140,
    };
  },
  "date-range": (item) => {
    return {
      ...fieldHeader(item),
      filterRender: null,
      filters: null,
      width: 220,
    };
  },
  rate: (item) => {
    let cellRender = {};
    let prop = {
      ...fieldHeader(item),
      optionsItem: item.filterOptions,
      cellRender,
      width: 120,
    };
    if (item.filterType == "rate") {
      cellRender.name = "cell-rate";
      prop.cellRender = cellRender;
    } else {
      prop.filters = [{ data: { find: 0, from: 0, to: 0 } }];
    }
    return prop;
  },
  "rich-editor": (item) => {
    return {
      ...fieldHeader(item),
      cellRender: { name: "cell-rich-editor" },
      filterRender: null,
      filters: null,
    };
  },
  "sub-form": (item) => {
    item.subWidgets = [];
    if (item.widgetList) {
      item.widgetList.forEach((wgt) => {
        item.subWidgets.push(filedColumnMap[wgt.type](wgt));
      });
    } else {
      console.debug("渲染sub-form但没有字段列表信息");
    }
    // delete item.widgetList;

    return {
      ...fieldHeader(item),
      filters: null,
      filterRender: null,
      params: item,
      cellRender: { name: "cell-sub-form" },
    };
  },
  "matrix-form": (item) => {
    item.subWidgets = [];
    if (item.widgetList) {
      item.widgetList.forEach((wgt) => {
        item.subWidgets.push(filedColumnMap[wgt.type](wgt));
      });
    } else {
      console.debug("渲染matrix-form但没有字段列表信息");
    }
    // delete item.widgetList;

    return {
      ...fieldHeader(item),
      filters: null,
      filterRender: null,
      params: item,
      cellRender: { name: "cell-sub-form" },
    };
  },
  "picture-upload": (item) => {
    return {
      ...fieldHeader(item),
      filters: [{ data: "" }],
      filterRender: { name: "filter-picture-upload" },
      cellRender: { name: "cell-picture-upload" },
    };
  },
  sign: (item) => {
    return {
      ...fieldHeader(item),
      filters: [{ data: "" }],
      filterRender: { name: "filter-sign" },
      cellRender: { name: "cell-sign" },
    };
  },
  "picture-select": (item) => {
    return {
      ...fieldHeader(item),
      filters: null,
      filterRender: null,
      cellRender: {
        name: "cell-picture-select",
        maxSelect: item.maxSelect,
        minSelect: item.minSelect,
        total: item.total,
      },
    };
  },
  ocrcode: (item) => {
    return {
      ...fieldHeader(item),
      filters: null,
      filterRender: null,
      cellRender: { name: "cell-ocrcode" },
    };
  },
  "fill-select": (item) => {
    return {
      ...fieldHeader(item),
      // filters: null,
      filters: [{ data: { fields: item.fields } }],
      // filterRender:null,x
      filters: null,
      titleFieldName: item.titleFieldName,
      filterRender: null,
      titleFieldName: item.titleFieldName,
      cellRender: { name: "cell-fill-select" },
    };
  },
  "data-choose": (item) => {
    return {
      ...fieldHeader(item),
      // filters: null,
      // filters: [{ data: {fields:item.fields} }],
      // filterRender:null,
      fields: item.fields,
      formId: item.formId,
      filters: null,
      filterRender: null,
      titleFieldName: item.titleFieldName,
      cellRender: { name: "cell-data-choose" },
    };
  },
  "user-select": (item) => {
    return {
      ...fieldHeader(item),
      // filters: null,
      // filterRender: null,
      filters: [{ data: "" }],
      filterRender: { name: "filter-userselect" },
      cellRender: { name: "cell-user-select" },
    };
  },
  "file-upload": (item) => {
    return {
      ...fieldHeader(item),
      waterMark: item.waterMark,
      exportName: item.exportName,
      exportAfterAudit: item.exportAfterAudit,
      filters: [{ data: "" }],
      filterRender: { name: "filter-picture-upload" },
      cellRender: { name: "cell-file-upload" },
    };
  },
  cascader: (item) => {
    return {
      ...fieldHeader(item),
      cellRender: { name: "cell-cascader" },
      filters: null,
      sortable: true,
      filters: [{ data: "" }],
      optionsItem: item.filterOptions,
      filterRender: { name: "filter-cascader" },
    };
  },
};

export default filedColumnMap;
