var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAuditRemarkDialog
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: "审核结果",
            visible: _vm.showAuditRemarkDialog,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAuditRemarkDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "-20px" } },
            [
              _c(
                "div",
                {
                  staticClass: "audit-view-remark",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _vm._v(" 当前状态："),
                  _c(
                    "span",
                    [
                      _c("StatusCode", {
                        attrs: { statusCode: _vm.clickRow.status },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.auditLog.length > 0
                ? [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "max-height": "450px",
                          "overflow-y": "auto",
                        },
                      },
                      _vm._l(_vm.auditLog, function (audit, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "signResult-view-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "signResult-view-item-title",
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "normal",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .moment(audit.time)
                                              .format("YYYY-MM-DD HH:mm:ss")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "normal",
                                          "margin-left": "10px",
                                        },
                                      },
                                      [_vm._v(_vm._s(audit.userName))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-flex" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.statusText(
                                            audit.oldStatus,
                                            audit.newStatus
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            audit.remark
                              ? _c(
                                  "div",
                                  { staticClass: "audit-view-remark" },
                                  [
                                    _c("div", [
                                      _vm._v("意见: " + _vm._s(audit.remark)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            audit.signUrl
                              ? _c("div", { staticClass: "audit-view-sign" }, [
                                  _c("span", {
                                    staticClass: "audit-sign-view",
                                    staticStyle: {
                                      width: "120px",
                                      height: "64px",
                                      margin: "0px 2px",
                                    },
                                    style: {
                                      backgroundImage:
                                        "url('" + audit.signUrl + "')",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showAuditRemarkDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }