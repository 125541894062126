import { MessageBox, Message } from "element-ui";
import picViewer from "@/utils/imagePreview.js";
import { saveDraft, updateDraft } from "@/api/system/draft";
const beforeBackConfirm = (
  isEdit,
  vformRender,
  saveCallback,
  cancelCallback,
  { formId, fillId, message, draftFillId }
) => {
  // 如果表单数据有变化
  if (vformRender.isFormDataEdit()) {
    // 如果是修改产生的变化
    if (isEdit) {
      //调用保存事件，并传入清空表单的方法
      saveCallback(vformRender.resetForm);
    } else {
      // 如果是新增产生的变化
      message("您尚未保存表单数据，是否确定退出？", "提示", {
        confirmButtonText: "保存草稿并关闭",
        cancelButtonText: "不保存关闭",
        type: "warning",
        distinguishCancelAndClose: true, // 区分取消与关闭
      })
        .then(async () => {
          //调用保存事件，并传入清空表单的方法

          // 调用保存草稿事件
          let fillJson = vformRender.getFormData(false);
          if (!draftFillId) {
            const res =  await saveDraft({
              formId,
              fillJson,
              draft: true,
            })
              saveCallback(formId, res);
          } else {
            try {
              const res = await updateDraft({
                formId,
                fillId: draftFillId,
                fillJson,
                draft: true,
                fillChange: [],
              });
              saveCallback(formId, res);
              vformRender.resetForm();
            } catch (e) {}
          }

          
        })
        .catch((actionS) => {
          localStorage.removeItem("draft_formModel");
          localStorage.removeItem("draft_formId");
          if (actionS == "cancel") {
            vformRender.resetForm();
            cancelCallback();
          }
        });
    }
  } else {
    vformRender.resetForm();
    cancelCallback();
  }
};

const showPicturePreview = (file, fileList) => {
  if (file.mediaType && file.mediaType.indexOf("video") >= 0) {
    let base64FileName = btoa(file.url);
    let url = `${
      process.env.VUE_APP_BASE_FILE_PREVIEW
    }/onlinePreview?url=${encodeURIComponent(base64FileName)}`;
    window.open(url);
  } else {
    let index = fileList.findIndex((x) => x.url === file.url);
    const viewer = new picViewer();
    viewer.show(
      fileList.map((x) => {
        return { url: x.url, src: x.thumb };
      }),
      index < 0 ? 0 : index
    );
  }
};

const changedValue = (formJson, oldFormData, newFormData) => {
  let changedValue = [];
  let fieldConfig = (name) => {
    let f = formJson.fieldList.find((x) => x.name == name);
    return f;
  };

  // 判断数据类型
  let getDataType = (data) => {
    const temp = Object.prototype.toString.call(data);
    const type = temp.match(/\b\w+\b/g);
    return type.length < 2 ? "Undefined" : type[1];
  };
  let isObjectChanged = (source, comparison) => {
    // 由于'Object','Array'都属于可遍历的数据类型，所以我们提前定义好判断方法，方便调用

    const iterable = (data) => ["Object", "Array"].includes(getDataType(data));
    // 如果源数据不是可遍历数据，直接抛错，主要用于判断首次传入的值是否符合判断判断标准。
    if (!iterable(source)) {
      let sourceType = getDataType(source);
      if (sourceType == "String" || sourceType == "Number") {
        return source != comparison;
      } else if (sourceType == "Undefined") {
        return true;
      }
      throw new Error(
        `source should be a Object or Array , but got ${sourceType},source:${source}`
      );
    }

    // 如果数据类型不一致，说明数据已经发生变化，可以直接return结果
    if (getDataType(source) !== getDataType(comparison)) {
      return true;
    }

    // 提取源数据的所有属性名
    const sourceKeys = Object.keys(source);

    // 将对比数据合并到源数据，并提取所有属性名。
    // 在这里进行对象合并，首先是要保证 对比数据>=源数据，好处一：后边遍历的遍历过程就不用做缺省判断了。
    const comparisonKeys = Object.keys({ ...source, ...comparison });

    // 好处二：如果属性数量不一致说明数据必然发生了变化，可以直接return结果
    if (sourceKeys.length !== comparisonKeys.length) {
      return true;
    }

    // 这里遍历使用some，some的特性一旦找到符合条件的值，则会立即return，不会进行无意义的遍历。完美符合我们当前的需求

    return comparisonKeys.some((key) => {
      // 如果源数据属于可遍历数据类型，则递归调用
      if (iterable(source[key])) {
        return isObjectChanged(source[key], comparison[key]);
      } else {
        return source[key] !== comparison[key];
      }
    });
  };

  Object.keys(newFormData).forEach((x) => {
    let field = fieldConfig(x);
    if (!field) {
      return;
    }
    if (!newFormData[x] && !oldFormData[x]) return;

    if (Array.isArray(newFormData[x]) || Array.isArray(oldFormData[x])) {
      if (
        (!newFormData[x] || newFormData[x].length == 0) &&
        (!oldFormData[x] || oldFormData[x].length == 0)
      )
        return;
    }

    //特殊处理一下地址组件，因为打开表单后，会产生默认值，而旧值为undefeind,会误判为更改
    if (field.type === "address") {
      if (
        newFormData[x].location.length == 0 &&
        newFormData[x].address == "" &&
        !oldFormData[x]
      )
        return;
    }

    if (
      (!newFormData[x] && !!oldFormData[x]) ||
      (!!newFormData[x] && !oldFormData[x])
    ) {
      changedValue.push({
        name: x,
        label: field.displayName,
        fieldType: field.type,
        oldValue: oldFormData[x],
        value: newFormData[x],
      });
      return;
    }

    if (isObjectChanged(newFormData[x], oldFormData[x])) {
      if (oldFormData[x] || newFormData[x]) {
        changedValue.push({
          name: x,
          label: field.displayName,
          fieldType: field.type,
          oldValue: oldFormData[x],
          value: newFormData[x],
        });
      }
    }
  });

  return changedValue;
};

// 判断审核的界别
const getAuditLevelByStatus = (status)=>{
  if(status==1){
    return 0
  }else if([2,3,4,5].indexOf(status)>-1){
    return 1
  }else if([6,7,8,9].indexOf(status)>-1){
    return 2
  }else if([10,11,12,13].indexOf(status)>-1){
    return 3
  } else if(status==14){
    return 100
  }
}
export { beforeBackConfirm, showPicturePreview, changedValue, getAuditLevelByStatus};
