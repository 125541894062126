var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.subformTitle,
            visible: _vm.show,
            width: _vm.isMobile ? "95%" : "1000px",
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "600px" } },
            [
              _c(
                "vxe-table",
                _vm._b(
                  {
                    ref: "xSubformGrid",
                    attrs: {
                      id: "toolbar_demo_2",
                      "seq-config": { start: 1 },
                      data: _vm.subData,
                      "cell-class-name": _vm.rowTitleNameLeft,
                    },
                  },
                  "vxe-table",
                  _vm.tableConfig,
                  false
                ),
                [
                  this.tableConfig.showRowNumber
                    ? _c("vxe-column", {
                        attrs: { type: "seq", title: "序号", width: "60" },
                      })
                    : _vm._e(),
                  _vm._l(_vm.subColumns, function (col, idx) {
                    return [
                      !col.slots
                        ? _c(
                            "vxe-column",
                            _vm._b(
                              {
                                key: idx,
                                attrs: {
                                  filters: col.filters,
                                  "filter-render": col.filterRender,
                                  params: col,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function (ref) {
                                        var $table = ref.$table
                                        var column = ref.column
                                        return [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                title: JSON.stringify(col),
                                              },
                                            },
                                            [_vm._v(_vm._s(column.title))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "vxe-column",
                              col,
                              false
                            )
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }