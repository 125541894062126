var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "list-wrapper",
      class: [
        _vm.active ? "active" : "",
        _vm.isSelected(_vm.cardData) ? "selected" : "",
        _vm.selectedMark(_vm.cardData.fillId) ? "alreadySelected" : "",
      ],
      on: { click: _vm.select },
    },
    [
      _c(
        "div",
        {
          staticClass: "list-header clearfix",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c(
            "div",
            { staticClass: "header-content box-card-item" },
            [
              _vm.isUseRender(_vm.fields[0])
                ? [
                    _c(
                      "span",
                      [
                        _c(
                          "cardCompRender",
                          _vm._b(
                            {},
                            "cardCompRender",
                            _vm.renderField(_vm.fields[0]),
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("span", [
                      _vm.cardData[_vm.fields[0].name]
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.cardData[_vm.fields[0].name].toString()
                              )
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ],
            ],
            2
          ),
          _vm.isAdmin
            ? _c(
                "el-button",
                {
                  staticClass: "title-button",
                  staticStyle: { "margin-right": "15px" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.view($event)
                    },
                  },
                },
                [_vm._v("查看")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._l(
        _vm.fields.length > _vm.filterMaxRow
          ? _vm.filterMaxRow
          : _vm.fields.length - 1,
        function (o) {
          return _c(
            "div",
            { key: o, staticClass: "box-card-item" },
            [
              _vm.isUseRender(_vm.fields[o])
                ? [
                    _c("span", { staticClass: "box-card-item-label" }, [
                      _vm._v(_vm._s(_vm.fields[o].displayName)),
                    ]),
                    _vm.fields[o]
                      ? _c(
                          "cardCompRender",
                          _vm._b(
                            {},
                            "cardCompRender",
                            _vm.renderField(_vm.fields[o]),
                            false
                          )
                        )
                      : _vm._e(),
                  ]
                : [
                    _c("span", { staticClass: "box-card-item-label" }, [
                      _vm._v(_vm._s(_vm.fields[o].displayName)),
                    ]),
                    _vm.cardData[_vm.fields[o].name]
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.cardData[_vm.fields[o].name].toString())
                          ),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          )
        }
      ),
      _c(
        "div",
        { staticClass: "selectedMark" },
        [
          _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
            _vm._v("个人填报中已选过此数据"),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }