import {
  checkFill,
  editFill,
  fill,
  getFillLogList,
  formDetail,
  deleleteFile,
  getCustomTip,
  orderCreat,
  formAuth,
  httpGetFormFieldV2,
  fillDetail,
  fillDel,
} from "@/api/system/form";
export default {
  computed: {
    vformRender: function () {
      return this.$refs.vformRender || this.$refs.vformDesigner.$refs.vRender;
    },
  },
  methods: {
    /**
     * AI： 向表单中添加数据选择的关联信息。
     * @param {Object} mainFormData 主表单数据，包含已选择的关联字段信息。
     * @param {String} mainFormId 主表单的表单ID。
     * @param {String} mainFillId 主表单的填充ID。
     * @param {Array} subformDatas 子表单数据数组,(如果是数据关联字段，值为空)。
     * @returns {Promise} 当所有关联表单数据保存完成时解析的Promise。
     */
    addDataChoose(mainFormData, mainFormId, mainFillId) {
      // 如果双相关联字段未选择就继续
      if (!mainFormData) return;
      // 获取表单中的关联字段
      const fieldWidgets = this.vformRender.getFieldWidgets();

      // 找到所有的 关联字段
      const dataChooseFields = fieldWidgets.filter(
        (x) =>
          x.field.type == "data-choose" &&
          !x.field.subtype &&
          x.field.options.isBoth == true
      );
      // 找到所有的 子表单字段
      const subformFields = fieldWidgets.filter(
        (x) =>
          x.field.type == "data-choose" &&
          x.field.subtype == "subform-choose" &&
          x.field.options.isBoth == true
      );

      // 关联字段要修改
      let relativeFillDatas = [];
      // 先读取出来保存的数据
      let loadDataPromiseArrays = [];
      // 保存修改的关联数据
      let editDataPromiseArrays = [];
      // 新增子表单
      let addDataPromiseArrays = [];

      // 表单中所有的数据关联字段
      dataChooseFields.forEach((datachoose) => {
        let datachooseName = datachoose.field.options.name;

        // dataChooseFillIds 获取到是关联的id { fillIds: []}
        let dataChooseFillIds = mainFormData.fillJson[datachooseName];
        if (!dataChooseFillIds) {
          resolve();
          return;
        }
        dataChooseFillIds.fillIds?.forEach((dataChooseFillId) => {
          loadDataPromiseArrays.push({
            formId: datachoose.field.options.from,
            fillId: dataChooseFillId,
            widget: datachoose.field.options.bothRelativeField,
            func: fillDetail(dataChooseFillId, datachoose.field.options.from),
          });
        });
      });

      subformFields.forEach((subform) => {
        let subformName = subform.field.options.name;
        let subformDatas = mainFormData.fillJson[subformName + "__data"];
        if (!subformDatas) {
          resolve();
          return;
        }
        subformDatas.forEach((subformRowData) => {
          subformRowData[subform.field.options.bothRelativeField] = {
            fillIds: [mainFillId],
          };
          addDataPromiseArrays.push({
            formId: subform.field.options.from,
            bothRelativeField: subform.field.options.bothRelativeField,
            fillJson: subformRowData,
            widget: subform.field.options.name,
            func: fill({
              formId: subform.field.options.from,
              fillJson: subformRowData,
            }),
          });
        });
      });

      return new Promise(async (resolve, reject) => {
        // ---------------------------------
        // 保存数据关联的选择项

        // 逐个字段处理 给填写数据增加选择的数据
        const loadDataRes = await Promise.all(
          loadDataPromiseArrays.map((x) => x.func)
        );
        loadDataRes.forEach((res, index) => {
          let widget = loadDataPromiseArrays[index].widget;
          let fillDataChoose = res.data.dataChoose;
          let fillJsonWidget = res.data.fillJson;

          if (!fillDataChoose[widget]) fillDataChoose[widget] = [];
          if (!fillJsonWidget[widget]) fillJsonWidget[widget] = {};

          // 给已有的填报数据添加一个新的id
          fillDataChoose[widget].push({ fillId: mainFillId });
          // 重新整理填报fillIds
          fillJsonWidget[widget].fillIds = fillDataChoose[widget].map(
            (x) => x.fillId
          );

          editDataPromiseArrays.push(
            editFill({
              formId: loadDataPromiseArrays[index].formId,
              fillId: loadDataPromiseArrays[index].fillId,
              fillJson: res.data.fillJson,
              fillChange: [],
            })
          );
        });
        // 保存数据关联字段的关联关系
        await Promise.all(editDataPromiseArrays);

        // ---------------------------------
        // 保存子表单的选择项
        let addRowRes = await Promise.all(
          addDataPromiseArrays.map((x) => x.func)
        );
        let subformNeweFillIds = {};
        addRowRes.forEach((res, index) => {
          let fillId = res.data;
          let dataChooseName = addDataPromiseArrays[index].widget;
          // 判断subformNeweFillIds 有没有值
          if (!subformNeweFillIds[dataChooseName]) {
            subformNeweFillIds[dataChooseName] = { fillIds: [] };
          }

          subformNeweFillIds[dataChooseName].fillIds.push(fillId);
        });

        Object.assign(mainFormData.fillJson, subformNeweFillIds);
        // 在本表单中添加子表单的数据
        editFill({
          formId: mainFormId,
          fillId: mainFillId,
          fillJson: mainFormData.fillJson,
          fillChange: [],
        })
          .then((response) => {
            if (response.code == 200) {
              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            resolve();
          });
      });
    },
    editDataChoose(mainFillJson, mainFormId, mainFillId) {
      const fieldWidgets = this.vformRender.getFieldWidgets();
      // 找到所有的 关联字段
      const dataChooseFields = fieldWidgets.filter(
        (x) =>
          x.field.type == "data-choose" &&
          // !x.field.subtype &&
          x.field.options.isBoth == true
      );
      // // 找到所有的 子表单字段
      // const subformFields = fieldWidgets.filter(
      //   (x) =>
      //     x.field.type == "data-choose" &&
      //     x.field.subtype == "subform-choose" &&
      //     x.field.options.isBoth == true
      // );

      return new Promise(async (resolve, reject) => {
        // 获取关联表单选择的数据
        let dataPromiseArrays = [];

        let setPromise = (linkInfo) => {
          linkInfo.deleteItems?.forEach((x) => {
            if (x) {
              if (linkInfo.type === "subform-choose") {
                // 直接删除数据
                dataPromiseArrays.push({
                  formId: linkInfo.formId,
                  fillId: x, //fillId 是当前表单id  x.fillId 是关联项的id
                  widget: linkInfo.bothRelativeField,
                  action: "delete",
                  func: new Promise((resolve, reject) => {
                    fillDel(x, linkInfo.formId).then((res) => {
                      let delIndex = mainFillJson[
                        linkInfo.widget
                      ].fillIds.findIndex((y) => y == x);
                      while (delIndex > -1) {
                        mainFillJson[linkInfo.widget].fillIds.splice(
                          delIndex,
                          1
                        );
                        delIndex = mainFillJson[
                          linkInfo.widget
                        ].fillIds.findIndex((y) => y == x || y == null);
                      }
                      resolve(res);
                    });
                  }),
                });
              } else {
                dataPromiseArrays.push({
                  formId: linkInfo.formId,
                  fillId: x, //fillId 是当前表单id  x.fillId 是关联项的id
                  widget: linkInfo.bothRelativeField,
                  action: "delete",
                  func: new Promise((resolve, reject) => {
                    fillDetail(x, linkInfo.formId).then((res) => {
                      let fillJson = res.data.fillJson;
                      if (fillJson[linkInfo.bothRelativeField]) {
                        fillJson[linkInfo.bothRelativeField].fillIds.splice(
                          fillJson[
                            linkInfo.bothRelativeField
                          ].fillIds.findIndex((id) => id === x),
                          1
                        );
                      }

                      editFill({
                        formId: linkInfo.formId,
                        fillId: x,
                        fillJson: fillJson,
                        fillChange: [],
                      }).then((res) => {
                        resolve(res);
                      });
                    });
                  }),
                });
              }
            }
          });

          linkInfo.addItems?.forEach((row) => {
            let func = null;
            if (linkInfo.type === "subform-choose") {
              func = new Promise((resolve, reject) => {
                // 写入数据，获取写入后的id，补充道数据中
                fill({
                  formId: linkInfo.formId,
                  fillJson: row,
                })
                  .then((fillRes) => {
                    row.fillId = fillRes.data;
                    if (!mainFillJson[linkInfo.widget].fillIds) {
                      mainFillJson[linkInfo.widget].fillIds = [];
                    }
                    mainFillJson[linkInfo.widget].fillIds.push(row.fillId);
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              });
            } else {
              func = new Promise((resolve, reject) => {
                // 读取关联表内容，把本表id写到关联表里。
                fillDetail(row.fillId, linkInfo.formId).then((fillRes) => {
                  let fillJson = fillRes.data.fillJson;
                  if (!fillJson[linkInfo.bothRelativeField]) {
                    fillJson[linkInfo.bothRelativeField] = { fillIds: [] };
                  }

                  if (!fillJson[linkInfo.bothRelativeField].fillIds) {
                    fillJson[linkInfo.bothRelativeField].fillIds = [];
                  }
                  fillJson[linkInfo.bothRelativeField].fillIds.push(mainFillId);
                  editFill({
                    formId: linkInfo.formId,
                    fillId: row.fillId,
                    fillJson: fillJson,
                    fillChange: [],
                  }).then((res) => {
                    resolve(res);
                  });
                });
              });
            }

            dataPromiseArrays.push({
              formId: linkInfo.formId,
              fillId: row.fillId,
              widget: linkInfo.bothRelativeField,
              action: "add",
              func,
            });
          });

          linkInfo.editItems?.forEach((x) => {
            dataPromiseArrays.push({
              formId: linkInfo.formId,
              fillId: x.fillId,
              widget: linkInfo.bothRelativeField,
              action: "edit",
              func: new Promise((resolve, reject) => {
                fillDetail(x.fillId, linkInfo.formId).then((res) => {
                  Object.assign(res.data.fillJson, x);

                  editFill({
                    formId: linkInfo.formId,
                    fillId: x.fillId,
                    fillJson: res.data.fillJson,
                    fillChange: [],
                  }).then((res) => {
                    resolve(res);
                  });
                });
              }),
            });
          });
        };

        dataChooseFields.forEach((fieldName) => {
          const field = this.vformRender.getWidgetRef(fieldName.name);
          if (field.dataChooseChangedInfo) {
            let linkInfo = field.dataChooseChangedInfo();
            setPromise(linkInfo);
          }
        });

        // 逐个字段处理 给填写数据增加选择的数据
        const loadDataRes = await Promise.all(
          dataPromiseArrays.map((x) => x.func)
        );

        // editFill({
        //   formId: mainFillId,
        //   fillId: mainFormData,
        //   fillJson: mainFillJson,
        //   fillChange: [],
        // }).then(() => {
        resolve();
        // });
      });
    },
  },
};
