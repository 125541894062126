import {setSessionStorageObj, getSessionStorageObj} from "@/utils/db";

const designView = {
  state: {
    viewName: 'baseView',
    agencyLog: ''
  },

  mutations: {
    SET_DESIGN_VIEW: (state, viewName) => {
      state.viewName = viewName
    },
    SET_DESIGN_LOGO:(state,logo)=>{
      state.agencyLog = logo;
    }
  },

  actions: {
    EnableDesignView({ commit },viewName) {
        commit('SET_DESIGN_VIEW', viewName||'')
    },
    SetDesignViewLogo({ commit },logo) {
      commit('SET_DESIGN_LOGO', logo)
  },
  }
}

export default designView
