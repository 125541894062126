<template>
  <div :class="classObj" class="app-wrapper">
    <div :class="{hasTagsView:needTagsView}" class="main-container" style="margin-left: 0">
      <div :class="{'fixed-header':fixedHeader}" v-if="this.$route.meta.flag">  <!--v-if="this.$route.meta.flag"-->
        <navbar/>
      </div>
      <app-main />
      <div class="customer-service" v-show="showCustomer"> <!--v-drag-->
        <a href="#" class="icon-chat">
          <div class="chat-tips">
            <i></i>
            <img :src="chatImg" alt="微信公众号">
            <p>如果有疑问或建议，<br/>请绑定公众号并直接公众号沟通</p>
            <p>工作时间：工作日09:00-18:00</p>
          </div>
        </a>
        <div class="chat-close" @click="onCloseService">
          <svg-icon icon-class="close" style="width: 14px; height: 14px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { AppMain, Navbar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader,
      designView: state=> state.settings.designView
    }),
    classObj() {
      return {
        mobile: this.device === 'mobile'
      }
    }
  },
  data() {
    return {
      userInfo: {},
      chatImg: require('../assets/images/code.jpeg'),
      showCustomer: true
    }
  },
  created() {},
  // 拖拽客服小图标(指令) 
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let odiv = el;
        el.onmousedown = e => {
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          let left = "";
          let top = "";
          document.onmousemove = e => {
            left = e.clientX - disX;
            top = e.clientY - disY;
            odiv.style.left = left + "px";
            odiv.style.top = top + "px";
          };
          document.onmouseup = e => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      }
    }
  },
  methods: {
    // 关闭客服
    onCloseService() {
      this.showCustomer = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    
    position: relative;
    height: 100%;
    width: 100%;
    @include clearfix;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }

  .customer-service{
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 100;
  }

  .customer-service .icon-chat{
    display: block;
    width: 50px;
    height: 50px;
    background: #FFFFFF url("../assets/icons/qr-icon.png") no-repeat center center;
    background-size: 30px 30px;
    border-radius: 50%;
    box-shadow: 0px 0px 8px #ccc;
    -webkit-box-shadow: 0px 0px 8px #ccc;
    -moz-box-shadow: 0px 0px 8px #ccc;
    -ms-box-shadow: 0px 0px 8px #ccc;
    margin-bottom: 55px;
  }

  .chat-tips {
    width: 240px;
    height: 240px;
    padding: 10px;
    border: 1px solid #d1d2d6;
    position: absolute;
    right: 70px;
    bottom: -55px;
    background-color: #ffffff;
    display: none;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 0px 8px #ccc;
  }

  .customer-service .icon-chat:hover .chat-tips {
    display: block;
  }

  .customer-service .icon-chat:hover{
    background-color: #d1d2d6;
  }

  .chat-tips i{
    width: 0px;
    height: 0px;
    border: 10px solid transparent;
    border-left-color: #d1d2d6;
    position: absolute;
    top: 95px;
    right: -20px;
  }

  .chat-tips i:before {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border: 10px solid transparent;
    border-left-color: #fff;
    position: absolute;
    left: -11px;
    bottom: -10px;
  }

  .chat-tips img{
    width: 160px;
    height: 160px;
    margin: auto;
    text-align: center;
    display: block;
  }

  .chat-tips p{
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    color: #999;
    margin: 0 auto;
  }

  .chat-close{
    position: absolute;
    right: -10px;
    top: -10px;
    display: none;
    cursor: pointer;
  }

  .customer-service:hover .chat-close {
    display: block;
  }

</style>
