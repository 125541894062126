import { api as viewerApi } from "v-viewer";
export default function () {
  var options = {
    inline: true,
    button: true,
    navbar: true,
    title: true,
    // toolbar: true,
    toolbar: {
      zoomIn: { show: true, size: "large" },
      zoomOut: { show: true, size: "large" },
      reset: { show: true, size: "large" },
      prev: { show: true, size: "large" },
      play: { show: true, size: "large" },
      next: { show: true, size: "large" },
      rotateLeft: { show: true, size: "large" },
      rotateRight: { show: true, size: "large" },
      flipHorizontal: { show: true, size: "large" },
      flipVertical: { show: true, size: "large" },
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    minWidth: 400,
    minHeight: 400,
    zIndex: 9999,
    url: "url",
  };

  let show = (fileList, index = 0, param) => {
    options.title=true;
    viewerApi({
      options: {
          ...{...options,...param},
        initialViewIndex: index,
      },
      images: Array.isArray(fileList) ? fileList : [fileList],
    });
  };
  
  let showSign = (fileList, index = 0, param) => {
    options.navbar=false;
    options.title=false;
    options.transition=false;
    viewerApi({
      options: {
          ...{...options,...param},
        initialViewIndex: index,
      },
      images: Array.isArray(fileList) ? fileList : [fileList],
    });
  };

  return {
    show,
    showSign
  };
}
