  <template>
  <div>
    <!-- 数据关联弹窗 -->
    <el-dialog :title="title" :visible.sync="isShow" :width="isMobile?'95%':'1220px'" custom-class="fill-selector" :before-close="close" :fullscreen="isMobile" :append-to-body="true" destroy-on-close :close-on-click-modal="false" :modal-append-to-body="true">
      <template #title>
        <div class="dialog-title">
          <div>{{title}}</div>
          <!-- <div v-if="!isMobile">
            <svg-icon class="view-icon" :class="[view=='list' ?'active':'']" @click="switchView('list')" icon-class="list-view" />
            <svg-icon class="view-icon" :class="[view=='table'?'active':'']" @click="switchView('table')" icon-class="table-view" />
          </div> -->
        </div>
      </template>
      <template v-if="fillSelectWidgetOptions.exactSearch == undefined || !fillSelectWidgetOptions.exactSearch">
        <div style="padding-right:5px;box-sizing:border-box">
          <div class="searchTip" v-if="fillSelectWidgetOptions.listAllData===false">说明：为保护数据安全，已设置为<strong>模糊检索</strong>，数据默认不显示。需在下方输入关键字检索后才能查询出相关数据</div>
          <el-input size="small" placeholder="搜索" :clearable="true" v-model="search" @keyup.enter.native="searchHandler">
            <el-button slot="append" @click="searchHandler" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </template>
      <template v-else>
        <div class="searchTip" v-if="fillSelectWidgetOptions.listAllData===false">说明：为保护数据安全，已设置为<strong>精确检索</strong>，数据默认不显示。需在下方输入关键字检索后才能查询出相关数据</div>
        <div class="search-container">
          <div v-for="(item,index) in fillSelectWidgetOptions.exactSearchField" :key="index" class="search-item">
            <div class="search-item-title">{{item.displayName}}</div>
            <div class="search-item-field">
              <el-input size="small" :clearable="true" v-model="searchObject[item.name]">
              </el-input>
            </div>
          </div>
          <div>
            <el-button size="mini" type="primary" @click="searchHandler">搜索</el-button>
          </div>
        </div>
      </template>
      <div class="box-card-container" v-show="isShow">
        <template v-if="table.data.length>0">
          <VirtualList style="height: 360px; overflow-y: auto;" :data-key="'fillId'" v-show="view=='list'" :data-sources="table.data" :data-component="filleCardItem" :extra-props="{
            maxCount,
            filterMaxRow,
            checkMaxSelected,
            selectItem:select,
            fields:(fillSelectOption.fillSource||{}).fields,
            isSelected:isSelected,
            isActive: isActive,
            viewItemHandler:this.viewItem,
            selectItemHandler:this.select,
            selectedMark:selectedMark
          }" :page-mode="false" :estimate-size="96" :keeps="50" v-on:totop="totop" @tobottom="tobottom" v-if="isMobile||fillSelectWidgetOptions.defaultView==undefined||view=='list'" />
        </template>
        <div class="empty-data" v-if="view=='list'&&table.data.length==0">
          {{dataEmptyText}}
        </div>
        <div style="height:400px;overflow:hidden;padding-right:5px;" v-show="view=='table'" v-if="!isMobile">
          <MyVxeTable ref="gridTable" :columns="viewTable.columns" :data="viewTable.data" :params="viewTable.params" :customTableConfig="{columnFilter:false,showToolbar:false}" :pageConfig="viewTable.pagerConfig" @page-change="handleDataPageChange" @view-picture="onHandlePreviewImg"
                      @filter-change="gridFilterChanged" :emptyText="dataEmptyText" @sort-change="gridSortChanged" :selectedMark="selectedMark" :extendTableConfig="tableExtendConfig" :formDetail="formDetail" :allowCheckAllData="false" style="width:100%">
            <template #operate>
              <vxe-column :field="statusTxt" title="操作" width="120" header-class-name="sysfield" :show-overflow="false" slots:default='operate' fixed="right" align="center">
                <template #default="{ row }">
                  <el-button type="text" icon="el-icon-view" status="primary" content="查看" @click="viewItem(row)">查看</el-button>
                  <!-- <el-button type="text" icon="el-icon-view" status="primary" content="选择" @click="tableRowSelect(row)">选择</el-button> -->
                </template>
              </vxe-column>
            </template>
          </MyVxeTable>
        </div>
        <VFormInsideViewer ref="dlgvFormInsideViewer" v-show="isShow"></VFormInsideViewer>
      </div>

      <span slot="footer" class="dialog-footer">
        <div class="footer-container">
          <div>
            <el-button type="primary" size="small" v-if="!!fillSelectWidgetOptions.allowAdd" @click="addNewData">+&nbsp;新增数据</el-button>
          </div>
          <div>
            <el-button type="primary" size="small" @click="confirm()">确 定</el-button>
            <el-button size="small" @click="isShow = false">关 闭</el-button>
          </div>
        </div>
      </span>

    </el-dialog>

    <el-dialog title="新增数据" :visible.sync="showAddNew" :width="isMobile?'350px':'1220px'" :before-close="closeNewData" :append-to-body="true" :modal-append-to-body="true">
      <template>
        <v-form-render ref="vformRender" :formJson="formJson" :formData="formData" :disableAutoFill="true" :optionData="optionData" :previewState="false">
          <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
            <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
          </template>
        </v-form-render>
      </template>
      <div slot="footer" class="dialog-footer">
        <div class="footer-container">
          <div>
          </div>
          <div>
            <el-button type="primary" size="small" @click="saveNewData">确 定</el-button>
            <el-button size="small" @click="closeNewData">关 闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import { formDetail, checkFill, fill, fillDetail, httpGetFormFieldV2, selectFillDataDetail } from '@/api/system/form'
import { fillList, fillListSearch } from '@/api/system/form'
import { fillSearch } from './fill-select-util'
import { getAgencyUser } from '@/api/system/agency'
import filleCard from './fill-select-widget-components/fill-select-card.vue'
import filleCardItem from './fill-select-widget-components/fill-select-card-item.vue'
import VirtualList from 'vue-virtual-scroll-list'
import { renderProvider } from '@/utils/gridrender/cellRender'
import { isMobile } from '@/utils/index'
import { getSessionStorageObj } from '@/utils/db'
import VFormInsideViewer from '../vFormViewer/inside.vue'


import MyVxeTable from '../tables/table.vue'
import gridConfig from '@/utils/grid.js'
// import { Container } from 'element-ui'
import { deepClone } from '@/utils/index'

import FillSelectTableView from '@/views/system/form/components/fillSelector/tableView'
export default {
  components: { filleCard, VFormInsideViewer, MyVxeTable, VirtualList, FillSelectTableView },
  componentName: 'FillSelectCardSelector',
  props: {
    optionData: {
      type: Object
    },
    onSaveHandler: {
      type: Function
    }
  },
  data () {
    return {
      // 表单关联字段的配置
      fillSelectOption: {},
      selectedItems: [], //已选列表
      maxCount: 0,
      filterMaxRow: 3,

      selectedItemsTmp: [], //点击确定前勾选的列表
      title: "",
      // 选择完成后的回调
      confirmSelectdHandler: null,
      isShow: false,
      formId: '',
      pageNum: 1,
      pageSize: 9999,
      renderProvider: renderProvider,
      isMobile: isMobile(),
      table: {
        data: [],
        total: 0
      },
      formJson: {
        formConfig: {},
        widgetList: [],
        fieldList: []
      },
      search: "",
      express: {},
      filleCardItem: filleCardItem,
      listProps: {
        data: [],
        total: 0
      },
      fillSelectWidgetOptions: {},
      dataQueryParams: {},
      formData: {},
      formDetail: {},
      view: '',
      viewTable: {
        border: true,
        align: 'center',
        resizable: true,
        keepSource: true,
        showOverflow: true,
        loading: false,
        columns: [],
        data: [],
        columnFilter: false,
        tableToolbar: {
          custom: true
        },
        params: {
          selectedRowKeys: []
        },
        pagerConfig: {
          total: 0,
          currentPage: 1,
          pageSize: 200,
          pageSizes: [20, 50, 100,200]
        }
      },
      searchObject: {},
      dataEmptyText: "",
      selectFillIds: [],
      showAddNew: false,
      statusTxt: '',
      tableExtendConfig: {
        cellStyle: this.cellStyleHandler,
        checkboxConfig: {
          trigger: 'row',
          checkMethod: ({ row }) => {
            return row.__disabled !== true;
          }
        }
      }
      // selectTableHeader:[]
    }
  },
  computed: {
    fillSourceFormId () {
      if (this.fillSelectWidgetOptions.fillSource) {
        return this.fillSelectWidgetOptions.fillSource.formId
      } else {
        return this.fillSelectWidgetOptions.from;
      }
    },
    fillSourceFields () {
      if (this.fillSelectWidgetOptions.fillSource) {
        return this.fillSelectWidgetOptions.fillSource.fields
      } else {
        return this.fillSelectWidgetOptions.fields;
      }
    }
  },
  methods: {
    totop () {
      console.log("tobottom")
    },
    tobottom () {
      console.log("tobottom")
      this.pageNum++;

      this.viewTable.pagerConfig.currentPage++;
      this.getTableData();
    },
    // 获取表单的其他配置
    // getFormDetail(formId) {
    //   return new Promise((resolve, reject) => {
    //     // 获取表单其他配置
    //     formDetail(formId).then((response) => {
    //       console.log("getFormDetail",response.data)
    //       this.formDetail = response.data
    //       this.isReserve = response.data.isReserve
    //       resolve()
    //     })
    //   })
    // },
    onHandlePreviewImg () { },
    // 打开选择窗口
    async show (fillSelectOption, confirmSelectdHandler) {

      this.fillSelectWidgetOptions = fillSelectOption.options // 这里面的formId是关联表的id

      this.fillSelectOption = fillSelectOption  // 这里面的formId是当前表单的id
      // this.fillSelectOption = fillSelectOption.fillSource
      this.selectedItems = fillSelectOption.selectedList || []
      this.maxCount = fillSelectOption.maxCount
      this.filterMaxRow = fillSelectOption.filterMaxRow
      this.formData = fillSelectOption.formData
      this.formId = this.fillSourceFormId // fillSelectOption.formId
      this.currentFillFormId = fillSelectOption.formId // 当前填报的表单id
      this.formAgencyId = fillSelectOption.formAgencyId
      this.title = fillSelectOption.title

      this.subFormRowIndex = fillSelectOption.subFormRowIndex
      this.subFormRowId = fillSelectOption.subFormRowId

      this.express = fillSelectOption.express
      this.table.data.splice(0);
      this.selectedItemsTmp.splice(0);
      this.confirmSelectdHandler = confirmSelectdHandler

      this.search = "";
      this.view = this.isMobile ? 'list' : 'table'
      this.viewTable.pagerConfig.currentPage = 1;

      // this.$refs.gridTable.

      if (fillSelectOption.maxCount) {
        this.tableExtendConfig.checkboxConfig.max = fillSelectOption.maxCount
      }

      //记录已选中到临时变量
      this.selectedItemsTmp = fillSelectOption.selectedList;

      // if(this.fillSelectWidgetOptions.filterMaxRow && this.fillSelectWidgetOptions.filterMaxRow>0){
      // this.viewTable.pagerConfig.pageSize = this.fillSelectWidgetOptions.filterMaxRow;
      // this.viewTable.pagerConfig.pageSizes = [this.viewTable.pagerConfig.pageSize];
      // }

      // this.view = this.fillSelectWidgetOptions.defaultView || 'list'
      // await this.getFormDetail(fillSelectOption.formId)
      // this.setSelectTableHeader();

      // 这里下载附件签名接口需要formId, table组件通过formDetail 获取formId
      // 这里没有必要读取完整的formDetail 信息，因此只传formId
      this.formDetail = {
        formId: this.fillSourceFormId
      }

      this.renderTableColumns(this.viewTable, this.fillSourceFields)
      if (this.viewTable.columns.length <= 8) {
        this.viewTable.columns = this.viewTable.columns.map(item => {
          if (item.width && item.type != 'checkbox') {
            item.minWidth = 160;
            delete item.width;
          }
          return item
        })
      }
      // this.renderAdminTableHeaderColumns(this.viewTable)

      // 用于标记已选择的数据
      await this.getMyFilledList()
      this.isShow = true

      this.table.data.splice(0);
      if(!this.selectedItemsTmp) this.selectedItemsTmp=[];
      this.selectedItemsTmp.splice(0);

      if (this.fillSelectWidgetOptions.exactSearch ===false ) {
        this.searchHandler();
      } else {
        this.dataEmptyText = "请输入查询内容后进行搜索"
      }

      if(this.fillSelectWidgetOptions.mustSearch ===false||this.fillSelectWidgetOptions.mustSearch===undefined ){
        this.searchHandler();
      }

      // 获取自己在这个表单中已选择的列表
    },

    autoFill (fillSelectOption, confirmSelectdHandler) {
      this.table.data.splice(0);
      this.selectedItemsTmp.splice(0);

      this.search = "";

      fillSearch(fillSelectOption, this.formData, this.search, this.searchObject, { pageSize: 1, pageNum: 1 })
        .then(({ data, total }) => {
          this.table.data = data;
          this.table.total = total;

          if (total == 1) {
            this.selectedItemsTmp = this.selectedItems;
            if (confirmSelectdHandler) confirmSelectdHandler(deepClone(data))
          }
        })
    },
    // 多选
    async autoFillMulti (fillSelectOption, confirmSelectdHandler, search = '', searchObject = {}) {
      this.table.data.splice(0);
      this.selectedItemsTmp.splice(0);

      this.search = search;
      this.searchObject = searchObject;

      fillSearch(fillSelectOption, this.formData, this.search, this.searchObject, { pageSize: 1000, pageNum: 1 })
        .then(({ data, total }) => {
          this.table.data = data;
          this.table.total = total;
          let fillData = deepClone(data);
          if (fillData.length > fillSelectOption.maxCount) {
            if (confirmSelectdHandler) confirmSelectdHandler(fillData.splice(0, fillSelectOption.maxCount))
          } else {
            if (confirmSelectdHandler) confirmSelectdHandler(fillData)
          }
        })
    },
    async searchHandler (callback) {
      if(this.fillSelectWidgetOptions.mustSearch ===true){
        if(this.fillSelectWidgetOptions.exactSearch=== true){
          let notEmpty=false;
          this.fillSelectWidgetOptions.exactSearchField.forEach(x=>{
            notEmpty=notEmpty || !!this.searchObject[x.name]
          })
          if(!notEmpty){
            this.dataEmptyText = "请输入查询内容后进行搜索"
            return;
          }
        }else{
          if(!this.search){
            this.dataEmptyText = "请输入查询内容后进行搜索"            
            return
          }
        }
      }
      this.table.data.splice(0);
      if(!this.selectedItemsTmp) this.selectedItemsTmp=[];
      this.selectedItemsTmp.splice(0);

      await this.getTableData();
      if (callback && (typeof(callback)==='function')) callback();
    },
    // 关闭
    close () {
      this.isShow = false
      this.table.data = [];
    },
    // switchView (view) {
    //   // this.fillSelectWidgetOptions.defaultView = view
    //   this.view = view
    //   this.$forceUpdate();
    //   this.getTableData();

    //   if (view === 'table') {
    //     this.setTableSelected()
    //   }


    // },
    // 读取表单填报数据
    async getTableData () {
      if (this.fillSelectWidgetOptions.listAllData !== undefined && !this.fillSelectWidgetOptions.listAllData) {
        // 如果不是直接列出所有数据，判断一下

        // 如果是精确查询
        if (this.fillSelectWidgetOptions.exactSearch) {

          // 所有的条件都填写了
          let isAllFill = true
          this.fillSelectWidgetOptions.exactSearchField.forEach((item) => {
            if (this.isBlank(this.searchObject[item.name])) isAllFill = isAllFill && false
          })

          if (!isAllFill) {
            console.log('isAllFille', isAllFill)
            this.$message('请输入完整的查询内容')
            return;
          }
        } else {
          if (this.isBlank(this.search)) return;
        }
      }
      const pager = {
        // pageSize: this.view == 'list' ? this.pageSize : this.viewTable.pagerConfig.pageSize,
        // pageNo: this.view == 'list' ? this.pageNum : this.viewTable.pagerConfig.currentPage,
        pageSize: this.viewTable.pagerConfig.pageSize,
        pageNo: this.viewTable.pagerConfig.currentPage
      }

      const showField = this.fillSourceFields.map(x => x.name).join(',');

      let maxSelect = this.fillSelectOption.maxCount; // 最大选择数
      let dataChooseFieldName = this.fillSelectOption.options.bothRelativeField;


      const { data, total } = await fillSearch(this.fillSelectOption, this.formData, this.search,
        {
          ...this.searchObject, ...this.dataQueryParams.filterData,
          filterData: this.dataQueryParams.filterData,
          fillStartTime: this.dataQueryParams.fillStartTime,
          fillEndTime: this.dataQueryParams.fillEndTime,
          updateStartTime: this.dataQueryParams.updateStartTime,
          updateEndTime: this.dataQueryParams.updateEndTime,
          queryStatus: this.dataQueryParams.queryStatus,
          payStatus: this.dataQueryParams.payStatus,
        },
        pager, showField)

      // 如果数据选择已经到达到了最大值，不能再选择了
      if (dataChooseFieldName) {
        data.forEach(item => {
          if(!item[dataChooseFieldName])
          {
            item[dataChooseFieldName] = {
              fillIds: []
            }
          }
          let realSelectCount = item[dataChooseFieldName].fillIds.length;
          if (realSelectCount >= maxSelect) {
            item.__disabled = true;
          }
        })
      }


      if (this.isMobile) {
        // 移动端选择采用下拉加载更多的方式
        data.forEach(item => {
          this.table.data.push(item);
          this.viewTable.data.push(item);
        })
      } else {
        this.table.data = data;
        this.viewTable.data = data;
      }
      this.table.total = total

      this.viewTable.pagerConfig.total = total
      this.$forceUpdate();

      if (this.isBlank(this.table.data)) {
        if(this.fillSelectWidgetOptions.listAllData!==undefined){
          this.dataEmptyText = '没有找到相关数据'
        }else{
          this.dataEmptyText = (this.fillSelectWidgetOptions.listAllData===false && !this.search )? '请输入查询内容':'没有找到相关数据'
        }
      }

      // 如果显示方式是table
      if (this.view == 'table') {
        this.$nextTick(() => {
          this.setTableSelected();
        })
      }

    },
    select (item) {
      let findIndex = this.selectedItemsTmp.findIndex(x => x.fillId == item.fillId);
      item.formId = this.formId;
      if (findIndex == -1) {
        this.selectedItemsTmp.push(item);
        return true;
      } else {
        this.selectedItemsTmp.splice(findIndex, 1);
        return false;
      }
    },
    setTableSelected () {
      let selectFillIds = this.selectedItems.map(x => x.fillId)
      let selectRows = this.viewTable.data.filter(x => selectFillIds.includes(x.fillId))
      if (this.$refs.gridTable) {
        this.$nextTick(() => {
          this.$refs.gridTable.setSelection(selectRows, true)
        })
      }
    },
    tableRowSelect (item) {
      this.select(item);
      this.confirm();
    },
    async viewItem (item) {
      //获取要显示的字段
      const res = await httpGetFormFieldV2(this.fillSourceFormId)
      const formDetailData = await formDetail(this.fillSourceFormId)
      const formJson = res.data
      const formData = item

      //显示指定的字段
      this.$refs.dlgvFormInsideViewer.showViewReadonly(
        item.fillId,
        this.fillSourceFormId,
        formJson,
        formData,
        this.$parent.cur != 3, //showHiddenWidgets
        this.fillSourceFields, //showFields
        true, //hideFields
        [], //readonlyFields
        false, //enableReadonly
        formDetailData.data.agencyId,
        formDetailData.data)
      this.$refs.dlgvFormInsideViewer.disableForm();
    },
    confirm (isNew=false) {
      if (!this.isMobile && this.view == 'table' && isNew===false) {
        let selectedFillIds = this.$refs.gridTable.selectedRowKeys.map(x => x.fillId)
        this.selectedItemsTmp = this.viewTable.data.filter(x => selectedFillIds.includes(x.fillId))
      }
      if (this.view == 'table' && isNew===false) {
        this.selectedItems = this.selectedItemsTmp;
      } else {
        this.selectedItems = this.selectedItemsTmp;
      }
      if (this.confirmSelectdHandler) this.confirmSelectdHandler(deepClone(this.selectedItems))


      this.$refs.gridTable && this.$refs.gridTable.clearSelected();
      this.close()
    },
    isSelected (item) {
      let findItem = this.selectedItems.find(x => x.fillId == item.fillId);
      return !!findItem;
    },
    checkMaxSelected () {
      if (this.maxCount < this.selectedItems.length + this.selectedItemsTmp.length + 1) {
        // if(this.maxCount<=this.selectedItemsTmp.length) {
        this.$message.error(`最多选择${this.maxCount}条记录`);
        return false;
      } else {
        return true;
      }
    },
    // 渲染表头
    renderTableColumns (refGrid, fieldList, hideHiddenField) {
      refGrid.columns = []
      gridConfig
        .renderHeaderColumns(
          fieldList,
          {
          },
          { hideHiddenField: hideHiddenField }
        )
        .forEach((c) => {
          refGrid.columns.push(c)
        })
    },
    // 渲染管理表头
    renderAdminTableHeaderColumns (refGrid) {
      const _this = this

      gridConfig
        .renderAdminHeaderColumns(_this.formJson.fieldList, {
          formDetail: { pay: false },
          dataFillStatus: false
        })
        .forEach((c) => {
          refGrid.columns.push(c)
        })
    },
    GetAllDataRows () {

    },
    handleDataPageChange ({ currentPage, pageSize }) {
      this.viewTable.pagerConfig.currentPage = currentPage
      this.viewTable.pagerConfig.pageSize = pageSize
      // this.gridOptions.columns = []
      this.viewTable.data.splice(0)

      this.getTableData();
    },
    onHandlePreviewImg () {

    },
    gridFilterChanged (filterObjects) {
      this.viewTable.pagerConfig.currentPage = 1;
      this.viewTable.data = [];
      let filterData = {};

      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.dataQueryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.dataQueryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "updateTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.updateStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.updateEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.dataQueryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.dataQueryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.fillQueryParams.fillStatus = x.value;
          }
          filterData[x.field] = x.value;
        } else {
          filterData[x.field] = x.value;
        }
      });

      if (filterObjects.length > 0) {
        this.dataQueryParams.filterData = filterData;
        this.dataQueryParams.filterObjects = filterObjects;
      } else {
        this.dataQueryParams.filterData = undefined;
        this.dataQueryParams.fillStartTime = undefined;
        this.dataQueryParams.fillEndTime = undefined;
        this.dataQueryParams.updateStartTime = undefined;
        this.dataQueryParams.updateEndTime = undefined;
        this.dataQueryParams.queryStatus = undefined;
        this.dataQueryParams.payStatus = undefined;
      }
      this.searchHandler(() => {
        let sortData = this.dataQueryParams.sortData;
        this.restoreFillter(this.$refs.gridTable, filterData, sortData);
      });

      this.noticeClickNum++;
    },
    gridSortChanged (sortObjects) {
      this.viewTable.pagerConfig.currentPage = 1;
      this.viewTable.data = [];

      this.fillSelectOption.sortData = sortObjects;
      this.searchHandler(() => {
        let sortData = this.dataQueryParams.sortData;
        this.restoreFillter(
          this.$refs.gridTable,
          this.dataQueryParams.filterObjects,
          sortData
        );
      });
    },
    restoreFillter (el, filterDataArray, sortData) {
      if (filterDataArray && Array.isArray(filterDataArray)) {
        filterDataArray.forEach((f) => {
          el.setFilter(f.field, f.value);
        });
      } else if (filterDataArray) {
        Object.keys(filterDataArray).forEach((f) => {
          el.setFilter(f, filterDataArray[f]);
        });
      }

      if (sortData) {
        sortData.forEach((field) => {
          el.setSort(field);
        });
      }
    },
    // 获取并记录个人填报列表
    getMyFilledList () {
      return new Promise((resolve, reject) => {
        // 查看当前填报表单所有的个人填报数据，并循环从里面找到关联表选择的id
        fillList(this.currentFillFormId, {}).then((response) => {
          if (response.code == 200) {
            // let validFills = response.data.filter(item => !!item.fillJson[this.fillSelectWidgetOptions.name])
            let validFills = response.data

            let selectFillIds = []
            validFills.forEach(item => {
              // 数据关联字段fill-select
              if (this.fillSelectWidgetOptions.name.indexOf('fillselect') > -1) {
                if (item.fillJson[this.fillSelectWidgetOptions.name]) {
                  let selectList = item.fillJson[this.fillSelectWidgetOptions.name].selectList
                  let tmp = selectList.map(x => x.fillId)
                  selectFillIds = selectFillIds.concat(tmp)
                }
              } else if (this.fillSelectWidgetOptions.name.indexOf('datachoose') > -1) {
                // 数据选择字段data-choose
                console.log('this.fillSelectWidgetOptions.name', this.fillSelectWidgetOptions.name)
                selectFillIds = item.fillJson[this.fillSelectWidgetOptions.name]?.fillIds || []
              }
            })
            this.selectFillIds = selectFillIds
          }
          resolve();
        })
      })

    },
    selectedMark (fillId) {
      return this.selectFillIds.indexOf(fillId) >= 0
    },
    isActive (data) {
      console.log('isActive', this.fillSelectOption.selectedList.some(x => x.fillId == data.fillId), this.fillSelectOption)
      return this.fillSelectOption.selectedList.some(x => x.fillId == data.fillId)
    },
    closeNewData () {
      this.showAddNew = false;
      this.isShow = true;
    },
    addNewData () {
      // 弹出新增窗口
      checkFill(this.fillSourceFormId).then((response) => {

        if (response.code == 200) {
          httpGetFormFieldV2(this.fillSourceFormId).then((response) => {

            this.formJson = response.data
            this.isReserve = response.data.isReserve
            this.isShow = false;
            this.showAddNew = true;

            this.$nextTick(() => {
              if(this.fillSelectOption.isBoth===true){
              this.$refs.vformRender.findWidgetAndSetHidden(this.fillSelectOption.bothRelativeField,true);
              }
            })
          })
        }
        else {
          _this.msgError("表单不允许填报")
        }
      })
    },
    saveNewData () {
      const _this = this
      if (!_this.$refs.vformRender.isFormDataEdit() && !_this.$refs.vformRender.isFormFillWithDefault()) {
        _this.msgError('请填写完表单信息后再提交')
        return
      }
      // 保存新数据

      const getFormJson = (cb) => {
        _this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const fillJsonCopy = JSON.parse(JSON.stringify(fillJson))
            const param = {
              formId: this.fillSourceFormId,
              fillJson: fillJson,
              formAgreementId: '',
              formAgreementSignature: ""
            }
            this.fillJson = fillJsonCopy
            this.confirmSelectdHandler([this.fillJson]);
            if (cb) cb(param)
          })
          .catch((err) => {
            _this.msgError(err)
          })
      }

      // 检查表单的是否允许填报
      checkFill(this.fillSourceFormId).then((response) => {
        if (response.code == 200) {
          getFormJson((param) => {

            fill(param).then((response) => {
              if (response.code == 200) {
                // _this.$refs.vformRender.resetForm();
                this.showAddNew = false;
                this.fillJson.fillId = response.data

                fillDetail(response.data, this.fillSourceFormId).then((fillDetail) => {
                  let fillDataTmp= {...fillDetail.data,...fillDetail.data.fillJson};
                  this.select(fillDataTmp);
                  this.confirm('new');
                })


              } else {
                _this.msgError('保存失败')
              }
            })
              .catch((err) => {
                _this.msgError(err)
              })
          })
        }
      })

    },
    cellStyleHandler ({ row, column, rowIndex, columnIndex }) {
      if (this.selectedMark(row.fillId)) {
        return {
          background: '#f3f3f3',
          color: '#aaaaaa',
          cursor: "pointer"
        }
      } else {
        return {
          cursor: 'pointer'
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.dialog-title {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;

  .view-icon {
    font-size: 16px;
    color: #888888;
    margin-right: 5px;
    border-radius: 3px;
    width: 22px;
    height: 22px;
    padding: 3px;

    &.active {
      color: #333333;
      background-color: #f3f3f3;
    }
    &:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
  }
}

.box-card-container {
  max-height: 500px;
  padding-right: 0px;
  overflow-y: auto;
  box-sizing: border-box;

  ::v-deep .myVxeTableContainer .el-row-table-bar {
    padding: 0px;
    padding-top: 10px;
    line-height: 20px;
  }
}
.box-card {
  margin-bottom: 10px;

  .box-card-title-label {
    font-weight: bold;
    &:after {
      content: ':';
      margin-right: 5px;
    }
  }
  &-item {
    height: 25px;
    line-height: 25px;
    display: inline-flex;
    background-color: red;

    &-label {
      font-weight: bold;
      &:after {
        content: ':';
        margin-right: 5px;
      }
    }
  }
}

.empty-data {
  height: 40px;
  line-height: 40px;
  width: 100%;
  margin-top: 20px;
  color: #888888;
  text-align: center;
}

// div一行两列的样式，每一列有个标题
div.search-item {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  min-width: 250px;
  margin: 0 10px 0px 0;
  width: 30%;
}
div.search-item-title {
  width: 100px;
  flex-grow: 1;
}
div.search-item-field {
  width: 150px;
  flex-grow: 1;
}
div.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
div.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

::v-deep .el-dialog {
  border-radius: 6px;
}

.searchTip {
  width: 100%;
  min-height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #888888;
}
</style>

<style lang="scss">
.el-dialog.is-fullscreen.fill-selector {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    padding-top: 0px;
    padding-bottom:0px;
    height: 100%;
  }
}
</style>