var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", position: "absolute", bottom: "-70px" } },
    [
      _c(
        "div",
        {
          staticClass: "qr-code-wrapper",
          staticStyle: {
            display: "inline-block",
            width: "100px",
            height: "100px",
            position: "relative",
          },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-top": "0px",
              position: "absolute",
              bottom: "0px",
              left: "0px",
            },
            attrs: { src: _vm.qrcode },
            on: { click: _vm.copyRandom },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "20px",
            cursor: "pointer",
            "line-height": "25px",
          },
        },
        [
          _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
            _vm._v("电脑端签字不好看，试试手机扫码完成签字~"),
          ]),
          _c("div", { staticStyle: { "margin-top": "5px", color: "red" } }, [
            _vm._v(" 请不要将此二维码分享给他人"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }