import {login, loginByMobile, logout, getInfo, qrLoginStatus} from '@/api/login'
import { getAgencyUser } from "@/api/system/agency";
import { getToken, setToken, removeToken } from '@/utils/auth'
import {setSessionStorageObj, getSessionStorageObj} from "@/utils/db";
// import crypto from "@/utils/crypto";

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    agencyUser: getSessionStorageObj('agencyUser')
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_AGENCYUSER: (state, agencyUser) => {
      state.agencyUser = agencyUser
      setSessionStorageObj('agencyUser', agencyUser)
    },
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    // SET_PERMISSIONS: (state, permissions) => {
    //   state.permissions = permissions
    // }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          // const token = crypto.decrypt(JSON.stringify(userInfo))
          setToken(res.data)
          commit('SET_TOKEN', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 手机号登录
    MobileLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginByMobile(userInfo).then(res => {
          setToken(res.data)
          commit('SET_TOKEN', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登录状态查询
    QrLoginStatus({ commit }, uuid) {
      return new Promise((resolve, reject) => {
        qrLoginStatus(uuid).then(res => {
          if(res.code === 200 && res.data !== 'wait'){
            setToken(res.data)
            commit('SET_TOKEN', res.data)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    GetUserInfo() {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if(res && res.code === 200){
            setSessionStorageObj('users', res.data)
            if(res.data.agencyIds && !getSessionStorageObj('agencyId')){
              let agencyIds = res.data.agencyIds.split(',')
              setSessionStorageObj('agencyId', agencyIds[0])
            }
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetCurrentAgencyUser({ commit }){
      return new Promise((resolve, reject) => {
        const agencyId = getSessionStorageObj('agencyId')
        if(agencyId)
        getAgencyUser(agencyId).then(res => {
          if(res.code === 200){
            commit('SET_AGENCYUSER', res.data)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    UpdateUserMobile({ commit }, mobile){
      return new Promise((resolve, reject) => {
        try{
          const session = getSessionStorageObj('users')
          session.mobile = mobile
          setSessionStorageObj('users', session)
          resolve();
        }catch (e) {
          reject(e)
        }
      })

    },
    // 退出系统
    LogOut() {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          sessionStorage.clear();
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

  }
}

export default user
