<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>

import store from '@/store';
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  mounted () {

    //预加载数据，并写到store里减少开销
    // store.dispatch("codes/GetUniversityCode",(university)=>{});
    // store.dispatch("codes/GetPCCode",(code)=>{});
    // store.dispatch("codes/GetPCACode",(code)=>{});
    // store.dispatch("codes/GetPCASCode",(code)=>{});
    window.onpopstate = function (event) {
      console.log(event.currentTarget.clientInformation);
      console.log(event);
    }

  },
  methods: {
    reload () {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    }
  }
}
</script>
<style lang="scss">
.global-messageZIndex{
  z-index:8000! important;
}
/* @import url('./lib/VFormDesigner.css') */
.el-scrollbar {
  //element-ui 下拉框在ios上 点击两次才能选中
  > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}

// .el-cascader-panel{
//     > .el-cascader-menu__hover-zone{
//       opacity: 1 !important;
//     }
//   }

.el-dialog-hide-header.el-dialog .el-dialog__header {
  border-bottom: 0px none;
  padding-top: 15px;
}
.el-dialog-no-header.el-dialog .el-dialog__header{
  display: none;
}
</style>