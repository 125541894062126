<template>
  <!-- fillStatus	填报状态：1个人填报，2导入待确认，3导入已确认，4导入无需确认 -->
  <!--status 1无需审核、2待一级审核、3一级审核通过、4、一级审核不通过、5一级审核返回修改、6待二级审核、7二级审核通过、8二级审核不通过、9二级审核返回修改 -->
  <div style="cursor:default">
    <!-- 导入已确认和无需确认的直接显示审核状态 -->
    <el-tag type="success" round v-if="statusCode=='1'" size="mini">无需审核</el-tag>

    <template v-if="statusCode=='2'">
      <el-tooltip placement="top" v-if="row">
        <div slot="content">待【{{(row.auditAuth1AgencyUserList||[]).map(x=>x.name).join(",")}}】审核</div>
        <el-tag round size="mini">待一级审核</el-tag>
      </el-tooltip>
      <el-tag type="primary" round size="mini" v-else>待一级审核</el-tag>
    </template>

    <template v-if="statusCode=='3'||statusCode=='4'||statusCode=='5'">
      <el-tooltip placement="top" v-if="row && row.auditAuth1Operator">
        <div slot="content">审核人：【{{row.auditAuth1Operator.name}}】</div>
        <el-tag type="success" round v-if="statusCode=='3'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='4'" size="mini">一级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='5'" size="mini">一级审核返回修改</el-tag>
      </el-tooltip>
      <template v-else>
        <el-tag type="success" round v-if="statusCode=='3'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='4'" size="mini">一级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='5'" size="mini">一级审核返回修改</el-tag>
      </template>
    </template>

    <template v-if="statusCode=='6'">
      <el-tooltip placement="top" v-if="row && row.auditAuth2AgencyUserList">
        <div slot="content">待【{{(row.auditAuth2AgencyUserList||[]).map(x=>x.name).join(",")}}】审核</div>
        <el-tag round size="mini">待二级审核</el-tag>
      </el-tooltip>
      <el-tag type="primary" round size="mini" v-else>待二级审核</el-tag>
    </template>

    <template v-if="statusCode=='7'||statusCode=='8'||statusCode=='9'">
      <el-tooltip placement="top" v-if="row && row.auditAuth2Operator">
        <div slot="content">审核人：【{{row.auditAuth2Operator.name}}】</div>
        <el-tag type="success" round v-if="statusCode=='7'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='8'" size="mini">二级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='9'" size="mini">二级审核返回修改</el-tag>
      </el-tooltip>
      <template v-else>
        <el-tag type="success" round v-if="statusCode=='7'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='8'" size="mini">二级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='9'" size="mini">二级审核返回修改</el-tag>
      </template>
    </template>

    <template v-if="statusCode=='10'">
      <el-tooltip placement="top" v-if="row && row.auditAuth3AgencyUserList">
        <div slot="content">待【{{(row.auditAuth3AgencyUserList||[]).map(x=>x.name).join(",")}}】审核</div>
        <el-tag round size="mini">待三级审核</el-tag>
      </el-tooltip>
      <el-tag type="primary" round size="mini" v-else>待三级审核</el-tag>
    </template>

    <template v-if="statusCode=='11'||statusCode=='12'||statusCode=='13'">
      <el-tooltip placement="top" v-if="row && row.auditAuth3Operator">
        <div slot="content">审核人：【{{row.auditAuth3Operator.name}}】</div>
        <el-tag type="success" round v-if="statusCode=='11'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='12'" size="mini">三级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='13'" size="mini">三级审核返回修改</el-tag>
      </el-tooltip>
      <template v-else>
        <el-tag type="success" round v-if="statusCode=='11'" size="mini">审核通过</el-tag>
        <el-tag type="info" round v-if="statusCode=='12'" size="mini">三级审核不通过</el-tag>
        <el-tag type="warning" round v-if="statusCode=='13'" size="mini">三级审核返回修改</el-tag>
      </template>
    </template>

    <template v-if="statusCode=='14'">
      <el-tooltip placement="top" v-if="row && (row.auditAuth3Operator||row.auditAuth2Operator||row.auditAuth1Operator)">
        <div slot="content">审核人：【{{(row.auditAuth3Operator||row.auditAuth2Operator||row.auditAuth1Operator).name}}】</div>
        <el-tag type="success" round v-if="statusCode=='14'" size="mini">审核通过</el-tag>
      </el-tooltip>
      <template v-else>
        <el-tag type="success" round v-if="statusCode=='14'" size="mini">审核通过</el-tag>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    statusCode: {
      type: Number,
      default: 1
    },
    row: {
      type: Object,
      default: null
    },
    fillStatusCode: {
      type: Number,
      default: 1
    },
  }
}
</script>
