<template>
  <div>
    <el-dialog title="查看相关表单数据" :visible.sync="isShow" :width="isMobile?'340px':'1000px'" append-to-body :before-close="close">
      <div class="container" v-if="formDetail">
        <div class="left" style="width:100%">
          <v-form-render ref="vformRender" :formJson="formJson" :formData="formData" :optionData="optionData" :previewState="false">
            <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
              <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
            </template>
          </v-form-render>
          <div v-if="isEdit" style="text-align:center">
            <el-button type="primary" size="small" @click="save">保存</el-button>
          </div>
        </div>
        <!-- <div class="right">
                <div class="log-title">日志：</div>
                <div>暂无信息</div>
            </div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { editFill } from '@/api/system/form'
import { beforeBackConfirm } from '@/utils/formUtil'
import { httpGetFormFieldV2 } from '@/api/system/form'
import { isMobile } from '@/utils/index'
import formViewerMixin from './formViewerMixin.js'

import FillSelectTableView from '@/views/system/form/components/fillSelector/tableView'
export default {
  name: 'vFormInsideViewer',
  componentName: 'vFormInsideViewer',
  components: { FillSelectTableView },
  mixins: [formViewerMixin],
  data () {
    return {
      isMobile: isMobile(),
    }
  },
  props:{
    // formDetail: {
    //   type: Object,
    //   default:()=>{}
    // }
  },
  methods:{
    disableForm(){
      this.$nextTick(()=>{
        this.$refs.vformRender.disableForm();
      })
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: inline-flex;
  position: relative;
}
.left {
  display: inline-block;
  height: 100%;
  width: 740px;
  flex-grow: 1;
  /* margin-right: 220px; */
}
.right {
  width: 200px;
  height: 100%;
  flex-shrink: 0;
  padding: 10px;
  border-left: solid 1px #efefef;
  box-sizing: border-box;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
}
.log-title {
  height: 30px;
  line-height: 30px;
}
</style>
