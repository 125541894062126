module.exports = {
  title: '鲸鱼填报'+(process.env.NODE_ENV === "development"?'[测试]':'') +'-智能数据管理平台',
  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: false,

  /**
   * 是否固定头部
   */
  fixedHeader: false,

  /**
   * 是否显示logo
   */
  sidebarLogo: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
  /* 页面样式 
   * baseView: 默认样式
   * webView: 主页样式
  */
  designView: '',
}
