<script>
import { jsApiTicket } from '@/api/system/wechat'
export default {
    methods:{
        getPosition(){
            return new Promise((resolve,reject)=>{

                var href = window.location.href;
                    jsApiTicket(href).then(res=>{
                        let {appId,noncestr,signature,timestamp}= res.data;

                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId, // 必填，公众号的唯一标识
                            timestamp, // 必填，生成签名的时间戳
                            nonceStr: noncestr, // 必填，生成签名的随机串
                            signature, // 必填，签名
                            jsApiList:['getLocation'],//必填，需要使用的JS接口列表，如我要用的是wx.getLocation，那么我的jsApiList里的就是getLocation
                        });
                        wx.ready(function() {
                            console.log('wx ready')
                        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，
                        // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，
                        // 则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
                        wx.getLocation({
                            type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                            success: function(res) {
                                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                                resolve({longitude,latitude});
                            },
                            fail: function(err) {
                                reject("获取定位位置信息失败！")
                            },
                            cancel: function (res) {
                                reject('用户拒绝授权获取地理位置');
                            }
                        });
                    });
                })

            })
        }
    }
}
</script>
<style scoped>
 
</style>