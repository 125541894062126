<template>
<div>
    <treeselect v-model="option.data" :options="deptOptions" :multiple="false" :flat="false" :show-count="true" :default-expand-level="1" :disable-branch-nodes="true" placeholder="所属部门" size="mini" @select="selected"/>
    </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { agencyDeptList, agencyRoleList, updateAgencyUser, agencyUserList, agencyUserExcelImport } from '@/api/system/agency'
export default {
  components: { Treeselect },
  data() {
    return {
      deptOptions: [{
        id:"",
        label:'全部'
      }], // 部门树形
      deptTreeProps: {
        children: 'children',
        label: 'deptName'
      }
    }
  },
  created() {
    this.getDeptTreeSelect()
  },
  props: {
    option: Object
  },
  methods: {
    getDeptTreeSelect() {
      agencyDeptList().then((response) => {
        const treeList = response.data
        this.deptOptions = this.handleTree(treeList, 'agencyDeptId')
        this.deptOptions = this.getDeptTree(this.deptOptions)
      })
    },
    getDeptTree(tree = []) {
      const arr = []
      if (tree.length !== 0) {
        tree.forEach((item) => {
          const obj = {}
          obj.label = item.deptName
          obj.id = item.agencyDeptId
          if (item.children) {
            obj.children = this.getDeptTree(item.children)
          }
          arr.push(obj)
        })
      }
      return arr
    },
    selected(node){
      this.$emit("selected",node);
    }
  }
}
</script>
<style scoped>
</style>
