<template>
  <div class="hah">
    <div class="navbar app-inner-container" v-if="$route.meta.flag">
      <router-link class="left-menu" to="/myForm">
        <img :src="logoImg ? logoImg : logoImgDefault" v-if="logoImg" />
        <img :src="logoImg ? logoImg : logoImgDefault" v-else style="height:30px;margin-top:10px"/>
      </router-link>
      <div class="center-menu">
        <ul class="menu-ul">
          <li :class="{'active': $route.path === '/index'}">
            <router-link to="/index" @click.native="saveDefaultRouer('/index')">
              <svg-icon icon-class="agencyHomeWhite"></svg-icon>
              <span>机构首页</span>
            </router-link>
          </li>
          <li :class="{'active': $route.path === '/myForm'}">
            <router-link to="/myForm" @click.native="saveDefaultRouer('/myForm')">
              <svg-icon icon-class="navbarMyForm"></svg-icon>
              <span>个人首页</span>
            </router-link>
          </li>
          <li v-if="userInfo.type == '2' || userInfo.type == '3'" :class="{'active':$route.path === '/mechan/index'}">
            <router-link to="/mechan/index">
              <svg-icon icon-class="navbarAgencyManage"></svg-icon>
              <span>机构管理</span>
            </router-link>
          </li>
        </ul>
      </div>
      <title-user-menu></title-user-menu>
    </div>
  </div>
</template>

<script>
// import { userAgencyList } from "@/api/system/agency"
import { getAgency } from '@/api/system/agency'
import { userAgencyMyList } from "@/api/system/user"
import TitleUserMenu from '@/components/TitleUserMenu/index.vue'
import { setSessionStorageObj, getSessionStorageObj } from "@/utils/db";

export default {
  data () {
    return {
      logoImgDefault: require('../../assets/images/logo-white.png'),
      logoImg: '',
      activeIndex: "1",
      agencyId: 0,
      userInfo: {},
      loading: false,
      // 总条数
      total: 0,
      agencyUser: {},
      // agencyList: [],
      agencyIndex: 0,
      agencyName: '机构首页'
    };
  },
  async created () {
    await this.getUserInfo();
    this.getUserAgencyMyList();
  },
  components: { TitleUserMenu },
  methods: {
    getUserInfo () {
      return new Promise((resolve,reject)=>{
        this.$store.dispatch("GetUserInfo").then((res) => {
          this.userInfo = res.data;
          resolve()
        }).catch(()=>{
          reject()
        });
      })
    },
    getUserAgencyMyList () {
      this.agencyId = getSessionStorageObj('agencyId')
      if(!this.agencyId){
        console.log('sss',this.userInfo)
      }
      this.getAgency(this.agencyId)
    },
    // lxq 2023-07-02
    getAgency (agencyId) {
      if(agencyId)
      getAgency(agencyId).then(res => {
        this.logoImg = res.data.leftTopLogo || res.data.logo
        this.$store.dispatch('SetDesignViewLogo',this.logoImg);
      })
    },
    //保存登录后默认的跳转
    saveDefaultRouer (tag) {
      localStorage.setItem('defaultRouter', tag)
      this.$router.push({ path: tag, query: { agencyId: this.agencyId } })
      // this.$router.go(0)
    }

  },
};
</script>

<style lang="scss" scoped>
@import url('/assets/styles/index.scss');
.hah {
  width: 100%;
  text-align: center;
  background: linear-gradient(90deg, #0025fc 0%, #0078f8 100%);
  -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 85, 221, 0.3);
  box-shadow: 0px 6px 8px 0px rgba(0, 85, 221, 0.3);
  margin-bottom: 20px;
}

.navbar {
  padding-left: 12px;
  height: 60px;
  display: inline-flex;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
  align-items: center;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .left-menu {
    $leftMenuHeight: 40px;
    display: inline-block;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: left;
    height: $leftMenuHeight;

    img {
      // width: 108px;
       height:$leftMenuHeight
    }
  }

  .center-menu {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    display: inline-block;
    align-items: start;

    .menu-ul {
      width: 100%;
      margin: 0;
      height: 60px;
      line-height: 60px;
      padding: 0;
      text-align: left;
      li {
        list-style: none;
        display: inline-block;
        padding: 0px 20px;
        font-size: 16px;
        color: white;
        // vertical-align: middle;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        &.active {
          background-color: rgba(255, 255, 255, 0.2);
        }
        a {
          position: relative;
          display: inline-block;
          &.router-link-active {
            //color: #394349;
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 52px;
              left: 50%;
              bottom: 0;
              height: 4px;
              background: #ffffff;
              z-index: 1;
              margin-left: -26px;
              border-radius: 3px 3px 0 0;
            }
          }
        }
        span {
          margin-left: 8px;
        }
      }

      ::v-deep .el-dropdown {
        list-style: none;
        display: inline-block;
        padding: 0px 0px;
        font-size: 16px;
        color: #fff;
      }
    }
  }
}
</style>
