<template>
  <div :style="{height}">
    <MyVxeTable ref="fillGridTable" :columns="renderFillSelectTableViewColumns(fields,showDelete)" :data="datas" :params="params" @view-picture="onHandlePreviewImg" @clear-global-search="clearGlobalSearch" @filter-change="fillFilterChanged" @sort-change="fillSortChanged">
      <template v-slot:operate>
        <vxe-column title="操作" :width="operatorWidth" slots:default='operate' header-class-name="sysfield" fixed="right" align="center">
          <template #default="{ row }" v-if="inFillSelect">
            <!-- 在数据关联字段里 -->
            <el-button type="text" icon="el-icon-view" status="primary" @click="viewItem(row, row._index)" style="min-width:30px;"><span v-if="!isMobile">查看</span></el-button>
            <el-button type="text" icon="el-icon-remove" status="primary" :disabled="disabled" v-if="!disabled" style="min-width:30px;" @click="removeItem(row, row._index)"><span v-if="!isMobile">移除</span></el-button>
          </template>
          <template #default="{ row }" v-else>
            <el-button type="text" icon="el-icon-view" status="primary" :disabled="disabled" style="min-width:30px;" @click="viewItem(row, row._index)"><span v-if="!isMobile">查看</span></el-button>
            <el-button type="text" icon="el-icon-view" status="primary" :disabled="disabled" v-if="showDelete || ( row.userId === userAgency.userId)" style="min-width:30px;" @click="editItem(row, row._index)"><span v-if="!isMobile">编辑</span></el-button>
            <el-button type="text" icon="el-icon-remove" status="primary" :disabled="disabled" v-if="showDelete || ( row.userId === userAgency.userId)" style="min-width:30px;" @click="removeItem(row, row._index)"><span v-if="!isMobile">删除</span></el-button>
          </template>
        </vxe-column>
      </template>
    </MyVxeTable>
  </div>
</template>
<script>
import MyVxeTable from '@/views/system/form/components/tables/table.vue'
import gridConfig from '@/utils/grid.js'
import { setSessionStorageObj, getSessionStorageObj } from "@/utils/db";
import { deepClone, isMobile } from '@/utils/index'
export default {
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    datas: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    viewItem: {
      type: Function,
      default: () => { }
    },
    editItem: {
      type: Function,
      default: () => { }
    },
    removeItem: {
      type: Function,
      default: () => { }
    },
    inFillSelect: {
      type: Boolean,
      default: true
    },
    formOptionData: {
      type: Object,
      default: () => { }
    },
    onHandlePreviewImg: {
      type: Function,
      default: () => { }
    },
    field: {
      type: Object,
      default: () => { }
    },
    fieldRef: Object,
    enableSearch: {
      type: Boolean,
      default: true
    },
    enableFilter: {
      type: Boolean,
      default: true
    },
    formDetail: {
      type: Object,
      default: () => { }
    },
    formData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      userAgency: {},
      params: {
        selectedRowKeys: [],
        excludeIds: []
      },
      filterData: {},
      sortData: [],
      fillQueryParams: {
        queryStatus: "",
        payStatus: "",
        fillStartTime: "",
        fillEndTime: "",
        formFieldId: "",
        fillValue: "",
      },
      isMobile: isMobile()
    }
  },
  components: {
    MyVxeTable
  },
  mounted () {
    this.userAgency = getSessionStorageObj('agencyUser');
  },
  computed: {
    height: function () {
      var dataHeight = 42 * this.datas.length + 50 * 2; //数据行高 * 行数+ 标题高+ 搜索区域高
      return (400 < dataHeight ? 400 : dataHeight) + 'px';
    },
    operatorWidth: function () {
      if (!this.isMobile) {
        return this.isAdmin ? 220 : 120;
      } else {
        return 100;
      }
    },
    // 判断是不是表单子管理员
    isFormSubAdmin: function () {
      if (this.formDetail.managerUserId && this.userAgency) {
        // 判断字符串是否包含this.userAgency.userId
        return this.formDetail.managerUserId.indexOf(this.userAgency.userId) !== -1
      } else {
        return false;
      }
    },
    isCreateUser: function () {
      return this.formData.currentIsCreateUser!==false;
    },
    isDataManager: function () {
      return this.formData.currentIsDataManager!==false;
    },
    showDelete: function () {
      return this.isAdmin || this.isFormSubAdmin || this.isCreateUser || this.isDataManager
    }
  },
  methods: {
    renderFillSelectTableViewColumns (fields, isAdmin) {
      const _this = this
      var columns = [];
      gridConfig
        .renderFillSelectTableViewColumns(
          deepClone(fields) // 复制一下field 避免产生消息的循环
        )
        .forEach((c) => {
          c.minWidth = c.width;
          delete c.width;

          if (!this.enableSearch) {
            c.sortType = undefined;
            c.sortable = undefined;
          }
          if (!this.enableFilter) {
            c.filterRender = null;
            c.filters = null;
          }
          columns.push(c)
        })
      console.log('columns', columns)
      return columns;
    },
    clearGlobalSearch () {
      this.fillQueryParams.fillValue = "";
    },
    fillFilterChanged (filterObjects) {
      this.filterData = {};
      if (filterObjects.length > 0) {
        this.clearGlobalSearch();
      }

      filterObjects.forEach((x) => {
        if (x.sysField) {
          if (x.filterType == "createTime") {
            let showValue = x.showValue.split("~");
            this.fillQueryParams.fillStartTime = showValue[0] + " 00:00:00";
            this.fillQueryParams.fillEndTime = showValue[1] + " 23:59:59";
          } else if (x.filterType == "sysStatus") {
            this.fillQueryParams.queryStatus = x.value;
          } else if (x.filterType == "payStatus") {
            this.fillQueryParams.payStatus = x.value;
          } else if (x.filterType == "fillStatus") {
            this.fillQueryParams.fillStatus = x.value;
          }
        } else {
          this.filterData[x.field] = x.value;
        }
      });
      if (filterObjects.length > 0) {
        this.fillQueryParams.filterData = this.filterData;
        this.fillQueryParams.filterObjects = filterObjects;
      } else {
        this.fillQueryParams.filterData = undefined;
        this.fillQueryParams.fillStartTime = undefined;
        this.fillQueryParams.fillEndTime = undefined;
        this.fillQueryParams.queryStatus = undefined;
        this.fillQueryParams.payStatus = undefined;
      }

      this.formOptionData.formDataSelectMultiFillHandler(this.formDataSelectParams(), (fill) => {
        this.fieldRef.updateFillSelectList(fill);

        this.restoreFillter(this.$refs.fillGridTable, this.filterData, this.sortData);
      }, '', this.filterData)

      // this.getMyFillList(() => {
      // let sortData = this.fillQueryParams.sortData;
      // this.restoreFillter(this.$refs.fillGridTable, filterData, sortData);

      // this.restoreFillter(this.$refs.fillGridTable, this.fillQueryParams.filterObjects , sortData)
      // });
    },
    fillSortChanged (sortObjects) {
      this.sortData = sortObjects;
      this.formOptionData.formDataSelectMultiFillHandler(this.formDataSelectParams(), (fill) => {
        this.fieldRef.updateFillSelectList(fill);

        setTimeout(() => {
          this.restoreFillter(this.$refs.fillGridTable, this.filterData, sortObjects);
        }, 10)
      }, '', this.filterData)

      // this.getMyFillList(() => {
      //   let sortData = this.fillQueryParams.sortData;
      //   this.restoreFillter(
      //     this.$refs.fillGridTable,
      //     this.fillQueryParams.filterObjects,
      //     sortData
      //   );
      // });
    },
    formDataSelectParams () {
      return {
        fillSource: this.field.options.fillSource,
        express: this.field.options.express,
        formData: this.formModel,
        // maxCount: this.field.options.maxSelect,
        selectedList: this.selectedList,
        title: this.field.options.label,
        options: this.field.options,
        formAgencyId: this.formAgencyId,
        formId: this.formId,
        subFormRowIndex: this.subFormRowIndex,
        subFormRowId: this.subFormRowId,
        sortData: this.sortData
      };
    },
    restoreFillter (el, filterDataArray, sortData) {
      if (filterDataArray && Array.isArray(filterDataArray)) {
        filterDataArray.forEach((f) => {
          el.setFilter(f.field, f.value);
        });
      } else if (filterDataArray) {
        Object.keys(filterDataArray).forEach((f) => {
          el.setFilter(f, filterDataArray[f]);
        });
      }

      if (sortData) {
        sortData.forEach((field) => {
          el.setSort(field);
        });
      }
    },
  }
}
</script>
<style scoped>
</style>