import headers from "./gridHeader";
import { isBlank } from "@/utils/whale";

//添加一个对象或者数组
Array.prototype.add = function (item) {
  let $this = this;
  if (Array.isArray(item)) {
    item.forEach((x) => {
      $this.push(x);
    });
  } else {
    $this.push(item);
  }
};

class Columns {
  columns = [];

  add(header, isShow) {
    if (isShow == undefined || !!isShow) {
      this.columns.add(header);
    }
  }
  forEach(callback) {
    this.columns.forEach((x) => {
      callback(x);
    });
  }
  toArray() {
    return this.columns;
  }
}
let grid = {
  sortConfig: {
    multiple: true,
    showIcon: true,
    trigger: "default",
    chronological: true,
    orders: ["desc", "asc", "null"],
    defaultSort: { field: "id", order: "desc" },
    remote: true,
    // headerCellClickEvent: ({
    //   $table,
    //   column,
    //   triggerResizable,
    //   triggerSort,
    //   triggerFilter,
    // }) => {
    //   // 如果触发了列的其他功能按钮

    //   console.log(column);
    //   if ( column && column.sortable && !(triggerResizable || triggerSort || triggerFilter) ) {
    //     if (column.order === "desc") {
    //       $table.clearSort();
    //     } else if (column.order === "asc") {
    //       $table.sort(column.property, "desc");
    //     } else {
    //       $table.sort(column.property, "asc");
    //     }
    //   }
    // },
  },
  expandConfig: {
    toggleMethod: ({ expanded, column, columnIndex, row, rowIndex }) => {
      // console.log("expand column",expanded,column,columnIndex);
    },
  },
  //获取所有表单数据表头
  renderFillSelectTableViewColumns: (fieldList, addOperator = true) => {
    let columns = new Columns();
    fieldList.forEach((item) => {
      if (headers[item.type] && typeof headers[item.type] === "function") {
        //列显示的条件： 不隐藏隐藏字段 并且 字段不是隐藏的
        columns.add(headers[item.type](item));
      } else if (headers[item.type]) {
        columns.add(headers[item.type]);
      } else {
        console.log(`未定义${item.displayName}的渲染方式：${item.type}`);
      }
    });
    if (addOperator) columns.add(headers.remove);

    return columns.toArray();
  },
  //获取所有表单数据表头
  //dataFillStatus 表单的批量导入状态 ，数据查看列表，是否显示填报状态列(管理员通过批量导入他人填报数据)
  renderHeaderColumns: (
    fieldList,
    { formDetail, dataFillStatus },
    { hideHiddenField = false } = {}
  ) => {
    let columns = new Columns();

    columns.add(headers.index);
    fieldList
      .filter((x) => {
        return ["divider", "static-text", "html-text"].indexOf(x.type) === -1;
      })
      .forEach((item) => {
        if (headers[item.type] && typeof headers[item.type] === "function") {
          //列显示的条件： 不隐藏隐藏字段 并且 字段不是隐藏的
          columns.add(
            headers[item.type](item),
            !hideHiddenField || !item.hidden
          );
        } else if (headers[item.type]) {
          columns.add(headers[item.type]);
        } else {
          console.log(`未定义${item.displayName}的渲染方式：${item.type}`);
        }
      });
    columns.forEach((x) => (x.formDetail = formDetail));
    let last = columns.columns.length - 1;
    columns.columns[last].minWidth = 50;
    columns.columns[last].width = "auto";
    return columns.toArray();
  },
  //获取所有管理列表数据表头
  renderAdminHeaderColumns: (fieldList, { formDetail, dataFillStatus }) => {
    let columns = new Columns();
    // 如果表单需要支付
    console.log("判断表单是否需要支付", formDetail.pay);
    columns.add(headers.pay(), formDetail.pay);
    // 表单设置审核流程，显示审核状态
    columns.add(headers.audit, formDetail.auditAuth);
    // columns.add(headers.audit); //始终显示审核状态列
    //表单的批量导入状态
    columns.add(headers.dataFillStatus, dataFillStatus);
    columns.add(headers.fillUser());
    // columns.add(headers.ownUser);
    // columns.add(headers.createUser);
    // columns.add(headers.agencyDeptCell);
    // columns.add(headers.agencyDept);
    columns.add(headers.operate);
    return columns.toArray();
  },
  //获取所有审核列表数据表头
  renderAuditHeaderColumns: (fieldList, { formDetail, dataFillStatus }) => {
    let columns = new Columns();
    // 如果表单需要支付
    console.log("判断表单是否需要支付", formDetail.pay);
    columns.add(headers.pay(), formDetail.pay);
    // 表单设置审核流程，显示审核状态
    columns.add(headers.fillUser());
    //表单的批量导入状态
    columns.add(headers.dataFillStatus, dataFillStatus);
    // columns.add(headers.agencyDeptCell);
    // columns.add(headers.agencyDept);
    // columns.add(headers.status);

    //仅显示审核备注
    columns.add(headers.audit, formDetail.auditAuth);
    columns.add(headers.operate);

    return columns.toArray();
  },
  //获取所有个人填报列表数据表头
  renderPersonalHeaderColumns: (fieldList, { formDetail, dataFillStatus }) => {
    let columns = new Columns();
    // 如果表单需要支付
    console.log("判断表单是否需要支付", formDetail.pay);
    // 表单设置审核流程，显示审核状态
    columns.add(headers.pay(), formDetail.pay);
    columns.add(headers.fillUser());
    columns.add(headers.audit[0], formDetail.auditAuth);
    columns.add(headers.operate);

    return columns.toArray();
  },
  //获取所有视图列表数据表头
  renderViewHeaderColumns: (fieldList, { formDetail, dataFillStatus }) => {
    let columns = new Columns();

    columns.add(headers.index);

    const sysColumns = {
      name: headers.viewFillUser(),
      fillTime: headers.viewCreateTime(),
      updateTime: headers.viewUpdateTime(),
      status: headers.status,
      payStatus: headers.viewPay(),
      auditStatus: headers.audit,
      confirmStatus: headers.dataFillStatus(),
    };

    fieldList.forEach((item) => {
      if (item.fieldType === "formField") {
        if (headers[item.type]) {
          //列显示的条件： 不隐藏隐藏字段 并且 字段不是隐藏的
          columns.add(headers[item.type](item), !item.hidden);
        }
      } else if (item.fieldType === "sysField") {
        if (
          !(item.field === "payStatus" && !formDetail.pay) &&
          !(item.field === "auditStatus" && !formDetail.auditAuth) &&
          !(item.field === "confirmStatus" && !formDetail.isBatchFill)
        ) {
          let addSys = sysColumns[item.field];
          if (Array.isArray(addSys)) {
            addSys.forEach((t) => {
              t.fixed = item.left ? "left" : item.right ? "right" : undefined;
            });
          } else {
            addSys.fixed = item.left
              ? "left"
              : item.right
              ? "right"
              : undefined;
          }
          columns.add(addSys, !item.hidden);
        }
      } else {
        console.log(`未定义${item.displayName}的渲染方式：${item.type}`);
      }
    });

    // 支付状态
    // columns.add(headers.pay(), formDetail.pay);
    // 显示审核状态
    // columns.add(headers.audit, formDetail.auditAuth);
    // columns.add(headers.audit); //始终显示审核状态列
    // 批量导入后的确认状态
    // columns.add(headers.dataFillStatus, dataFillStatus);
    // 填报人状态
    // columns.add(headers.fillUser());
    // columns.add(headers.ownUser);
    // columns.add(headers.createUser);
    // columns.add(headers.agencyDeptCell);
    // columns.add(headers.agencyDept);

    // 操作
    columns.add(headers.viewOperate);
    columns.forEach((x) => {
      x.formDetail = formDetail;
    });
    console.log("columns", columns);
    return columns.toArray();
  },
  filter: (column) => {},
};

export default grid;

//从读取的表单数据里获取支付的信息
export const payInfo = (fillItem) => {
  let obj = {};
  // 表单开通支付
  obj["payStatus"] = fillItem.payStatus;
  switch (fillItem.payStatus) {
    case 1:
      fillItem.payStatusTxt = "无需支付";
      break;
    case 2:
      fillItem.payStatusTxt = "待支付";
      break;
    case 3:
      fillItem.payStatusTxt = "已支付";
      break;
    case 4:
      fillItem.payStatusTxt = "确认支付";
      break;
  }
  obj["payStatusTxt"] = fillItem.payStatusTxt;
  obj["orderPriceTxt"] = fillItem.orderPrice / 100 + "元";
  obj["payTime"] = !isBlank(fillItem.payTime) ? fillItem.payTime : "--";
  return obj;
};

//从读取的表单数据里获取状态数据
export const statusInfo = (fillItem) => {
  let obj = {};

  let statusTxt = "";
  switch (fillItem.status) {
    case 1:
      statusTxt = "无需审核";
      break;
    case 2:
      if (!isBlank(fillItem.auditAuth1AgencyUserList)) {
        let auditAuth1AgencyUser = "";
        for (let j = 0; j < fillItem.auditAuth1AgencyUserList.length; j++) {
          auditAuth1AgencyUser +=
            fillItem.auditAuth1AgencyUserList[j].name + ",";
        }
        // 去掉最后一个逗号
        if (auditAuth1AgencyUser.length > 0) {
          auditAuth1AgencyUser = auditAuth1AgencyUser.substr(
            0,
            auditAuth1AgencyUser.length - 1
          );
        }
        statusTxt = "待" + auditAuth1AgencyUser + "审核"; // '待一级审核'
      } else {
        statusTxt = "--";
      }
      break;
    case 3:
      statusTxt = "已通过"; // 一级审核通过
      break;
    case 4:
      statusTxt = "已驳回"; // 一级审核不通过
      break;
    case 5:
      statusTxt = "返回修改"; // 一级审核返回修改
      break;
    case 6:
      if (!isBlank(fillItem.auditAuth2AgencyUserList)) {
        let auditAuth2AgencyUser = "";
        for (let k = 0; k < fillItem.auditAuth2AgencyUserList.length; k++) {
          auditAuth2AgencyUser +=
            fillItem.auditAuth2AgencyUserList[k].name + ",";
        }
        // 去掉最后一个逗号
        if (auditAuth2AgencyUser.length > 0) {
          auditAuth2AgencyUser = auditAuth2AgencyUser.substr(
            0,
            auditAuth2AgencyUser.length - 1
          );
        }
        statusTxt = "待" + auditAuth2AgencyUser + "审核"; // '待二级审核'
      } else {
        statusTxt = "--";
      }
      break;
    case 7:
      statusTxt = "已通过"; // 二级审核通过
      break;
    case 8:
      statusTxt = "已驳回"; // 二级审核不通过
      break;
    case 9:
      statusTxt = "返回修改"; // 二级审核返回修改
      break;
  }
  obj["status"] = fillItem.status;
  obj["statusTxt"] = statusTxt;
  return obj;
};

//从读取的表单数据里获取填报数据状态
export const fillStatusInfo = (fillItem, dataFillStatus = false) => {
  let obj = {};
  if (dataFillStatus) {
    switch (fillItem.fillStatus) {
      case 1:
        fillItem.fillStatusTxt = "个人填报";
        break;
      case 2:
        fillItem.fillStatusTxt = "导入待确认";
        break;
      case 3:
        fillItem.fillStatusTxt = "导入已确认";
        break;
      case 4:
        fillItem.fillStatusTxt = "导入无需确认";
        break;
    }
    obj["fillStatusTxt"] = fillItem.fillStatusTxt;
    obj["fillStatus"] = fillItem.fillStatus;
  }
  return obj;
};

//从读取的表单数据里获取机构信息
export const agencyInfo = (fillItem) => {
  let obj = {};
  if (!isBlank(fillItem.agencyDeptLevel1)) {
    let agencyDeptLevel1 = "";
    for (let j = 0; j < fillItem.agencyDeptLevel1.length; j++) {
      agencyDeptLevel1 += fillItem.agencyDeptLevel1[j].deptName + ",";
    }
    // 去掉最后一个逗号
    if (agencyDeptLevel1.length > 0) {
      agencyDeptLevel1 = agencyDeptLevel1.substr(
        0,
        agencyDeptLevel1.length - 1
      );
    }
    obj["agencyDeptLevel1"] = agencyDeptLevel1;
  } else {
    obj["agencyDeptLevel1"] = "--";
  }
  if (!isBlank(fillItem.agencyDeptLevel2)) {
    let agencyDeptLevel2 = "";
    for (let j = 0; j < fillItem.agencyDeptLevel2.length; j++) {
      agencyDeptLevel2 += fillItem.agencyDeptLevel2[j].deptName + ",";
    }
    // 去掉最后一个逗号
    if (agencyDeptLevel2.length > 0) {
      agencyDeptLevel2 = agencyDeptLevel2.substr(
        0,
        agencyDeptLevel2.length - 1
      );
    }
    obj["agencyDeptLevel2"] = agencyDeptLevel2;
  } else {
    obj["agencyDeptLevel2"] = "--";
  }
  return obj;
};

//从读取的表单数据里获取其他填报信息
export const extraFillInfo = (fillItem) => {
  let obj = {};
  obj["reviewRemark"] = !isBlank(fillItem.auditAuth2Remarks)
    ? fillItem.auditAuth2Remarks
    : !isBlank(fillItem.auditAuth1Remarks)
    ? fillItem.auditAuth1Remarks
    : "--";
  obj["fillUserNumber"] = !isBlank(fillItem.fillUserNumber)
    ? fillItem.fillUserNumber
    : "-";
  obj["fillUserName"] = fillItem.fillUserName;
  obj["createTime"] = fillItem.createTime;
  obj["agencyDeptList"] = fillItem.agencyDeptList;
  obj["fillUserAvatarUrl"] = fillItem.fillUserAvatarUrl;

  obj["fillId"] = fillItem.fillId;
  return obj;
};
