<template>
  <div style="display: flex;position: absolute;bottom: -70px;">
    <div class="qr-code-wrapper" style="display: inline-block;width: 100px;height: 100px;position: relative;">
      <img :src="qrcode" @click="copyRandom" style="margin-top:0px;position:absolute;bottom:0px;left:0px;" />
    </div>
    <div style="margin-top:20px;cursor:pointer;line-height:25px;">
      <a :href="url" target="_blank" >电脑端签字不好看，试试手机扫码完成签字~</a>
      <div style="margin-top:5px;color:red">
        请不要将此二维码分享给他人</div>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.qr-code-wrapper {
  &:hover {
    img {
      width: 200px;
      height: 200px;
      max-width: inherit;
    }
  }
}

.el-popover {
  /* 添加自定义样式 */
}
</style>

<script>
import { generateCode } from '@/api/system/form'
const websocketUrl = process.env.VUE_APP_WEBSOCKET_BASE_URL;
export default {
  name: 'useMobileSignTip',
  props: {
    fillId: {
      type: String,
      default: "fillId"
    },
    formId: {
      type: String,
      default: "formId"
    },
  },
  data () {
    return {
      ws: null,
      user: {},
      random: '',
      url: '',
      qrcode: '',
      imageData:'',
      heartBeatTimer: null,
    }
  },
  created () {
    this.random = this.generateRandomNumber();
    generateCode({
      url: window.location.origin + '/sign.html#/sign-pc?random=' + encodeURIComponent(this.random),
      height: 180,
      width: 180
    }).then(res => {
      this.qrcode = res;
      this.url =  window.location.origin + '/sign.html#/sign-pc?random=' + encodeURIComponent(this.random);
    })
  },
  mounted () {
    window.ws && window.ws.close();
    this.initWebSocket();

    this.heartBeatTimer = setInterval(() => {
      this.heartBeat();
    }, 1000)
  },
  beforeDestroy () {
    window.ws && window.ws.close();
    this.heartBeatTimer && clearInterval(this.heartBeatTimer)
  },
  methods: {
    // 生成随机的6位数字
    generateRandomNumber () {
      return Math.floor(100000 + Math.random() * 900000);
    },
    initWebSocket () {
      // 初始化webSocket链接
      window.ws = new WebSocket(`${websocketUrl}${this.random.toString()}-PC`);
      window.ws.onopen = () => {
        console.log('WebSocket连接成功');
      }
      window.ws.onmessage = (event) => {
        const { data, rotate, deviceType } = JSON.parse(event.data);
        this.imageData = data;
        this.$emit('update', { data, rotate, deviceType })
      }
      window.ws.onerror = (error) => {
        console.log(error);
      }
      window.ws.onclose = (closeEvent) => {
        if (closeEvent.code == 1006) {
          this.initWebSocket();
        }
        console.log('WebSocket连接关闭', closeEvent);
      }
    },
    heartBeat () {
      window.ws && window.ws.send('心跳包检测');
    },
    copyRandom () {
      var input = document.createElement('input');
      input.style.position = 'fixed';
      input.style.opacity = 0;
      input.value = this.random;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
    }
  }
}
</script>