var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.isShow,
            width: _vm.isMobile ? "95%" : "1220px",
            "custom-class": "fill-selector",
            "before-close": _vm.close,
            fullscreen: _vm.isMobile,
            "append-to-body": true,
            "destroy-on-close": "",
            "close-on-click-modal": false,
            "modal-append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("div", { staticClass: "dialog-title" }, [
                    _c("div", [_vm._v(_vm._s(_vm.title))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.fillSelectWidgetOptions.exactSearch == undefined ||
          !_vm.fillSelectWidgetOptions.exactSearch
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "padding-right": "5px",
                      "box-sizing": "border-box",
                    },
                  },
                  [
                    _vm.fillSelectWidgetOptions.listAllData === false
                      ? _c("div", { staticClass: "searchTip" }, [
                          _vm._v("说明：为保护数据安全，已设置为"),
                          _c("strong", [_vm._v("模糊检索")]),
                          _vm._v(
                            "，数据默认不显示。需在下方输入关键字检索后才能查询出相关数据"
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "el-input",
                      {
                        attrs: {
                          size: "small",
                          placeholder: "搜索",
                          clearable: true,
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchHandler($event)
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: { slot: "append", icon: "el-icon-search" },
                          on: { click: _vm.searchHandler },
                          slot: "append",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : [
                _vm.fillSelectWidgetOptions.listAllData === false
                  ? _c("div", { staticClass: "searchTip" }, [
                      _vm._v("说明：为保护数据安全，已设置为"),
                      _c("strong", [_vm._v("精确检索")]),
                      _vm._v(
                        "，数据默认不显示。需在下方输入关键字检索后才能查询出相关数据"
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "search-container" },
                  [
                    _vm._l(
                      _vm.fillSelectWidgetOptions.exactSearchField,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "search-item" },
                          [
                            _c("div", { staticClass: "search-item-title" }, [
                              _vm._v(_vm._s(item.displayName)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "search-item-field" },
                              [
                                _c("el-input", {
                                  attrs: { size: "small", clearable: true },
                                  model: {
                                    value: _vm.searchObject[item.name],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchObject, item.name, $$v)
                                    },
                                    expression: "searchObject[item.name]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.searchHandler },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShow,
                  expression: "isShow",
                },
              ],
              staticClass: "box-card-container",
            },
            [
              _vm.table.data.length > 0
                ? [
                    _vm.isMobile ||
                    _vm.fillSelectWidgetOptions.defaultView == undefined ||
                    _vm.view == "list"
                      ? _c("VirtualList", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.view == "list",
                              expression: "view=='list'",
                            },
                          ],
                          staticStyle: {
                            height: "360px",
                            "overflow-y": "auto",
                          },
                          attrs: {
                            "data-key": "fillId",
                            "data-sources": _vm.table.data,
                            "data-component": _vm.filleCardItem,
                            "extra-props": {
                              maxCount: _vm.maxCount,
                              filterMaxRow: _vm.filterMaxRow,
                              checkMaxSelected: _vm.checkMaxSelected,
                              selectItem: _vm.select,
                              fields: (_vm.fillSelectOption.fillSource || {})
                                .fields,
                              isSelected: _vm.isSelected,
                              isActive: _vm.isActive,
                              viewItemHandler: this.viewItem,
                              selectItemHandler: this.select,
                              selectedMark: _vm.selectedMark,
                            },
                            "page-mode": false,
                            "estimate-size": 96,
                            keeps: 50,
                          },
                          on: { totop: _vm.totop, tobottom: _vm.tobottom },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.view == "list" && _vm.table.data.length == 0
                ? _c("div", { staticClass: "empty-data" }, [
                    _vm._v(" " + _vm._s(_vm.dataEmptyText) + " "),
                  ])
                : _vm._e(),
              !_vm.isMobile
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.view == "table",
                          expression: "view=='table'",
                        },
                      ],
                      staticStyle: {
                        height: "400px",
                        overflow: "hidden",
                        "padding-right": "5px",
                      },
                    },
                    [
                      _c("MyVxeTable", {
                        ref: "gridTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          columns: _vm.viewTable.columns,
                          data: _vm.viewTable.data,
                          params: _vm.viewTable.params,
                          customTableConfig: {
                            columnFilter: false,
                            showToolbar: false,
                          },
                          pageConfig: _vm.viewTable.pagerConfig,
                          emptyText: _vm.dataEmptyText,
                          selectedMark: _vm.selectedMark,
                          extendTableConfig: _vm.tableExtendConfig,
                          formDetail: _vm.formDetail,
                          allowCheckAllData: false,
                        },
                        on: {
                          "page-change": _vm.handleDataPageChange,
                          "view-picture": _vm.onHandlePreviewImg,
                          "filter-change": _vm.gridFilterChanged,
                          "sort-change": _vm.gridSortChanged,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "operate",
                              fn: function () {
                                return [
                                  _c("vxe-column", {
                                    attrs: {
                                      field: _vm.statusTxt,
                                      title: "操作",
                                      width: "120",
                                      "header-class-name": "sysfield",
                                      "show-overflow": false,
                                      "slots:default": "operate",
                                      fixed: "right",
                                      align: "center",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var row = ref.row
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    icon: "el-icon-view",
                                                    status: "primary",
                                                    content: "查看",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewItem(row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2535338920
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1975997979
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("VFormInsideViewer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShow,
                    expression: "isShow",
                  },
                ],
                ref: "dlgvFormInsideViewer",
              }),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-container" }, [
                _c(
                  "div",
                  [
                    !!_vm.fillSelectWidgetOptions.allowAdd
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.addNewData },
                          },
                          [_vm._v("+ 新增数据")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.confirm()
                          },
                        },
                      },
                      [_vm._v("确 定")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            _vm.isShow = false
                          },
                        },
                      },
                      [_vm._v("关 闭")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增数据",
            visible: _vm.showAddNew,
            width: _vm.isMobile ? "350px" : "1220px",
            "before-close": _vm.closeNewData,
            "append-to-body": true,
            "modal-append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddNew = $event
            },
          },
        },
        [
          [
            _c("v-form-render", {
              ref: "vformRender",
              attrs: {
                formJson: _vm.formJson,
                formData: _vm.formData,
                disableAutoFill: true,
                optionData: _vm.optionData,
                previewState: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "myVxeTable",
                  fn: function (ref) {
                    var fields = ref.fields
                    var datas = ref.datas
                    var isAdmin = ref.isAdmin
                    var viewItem = ref.viewItem
                    var removeItem = ref.removeItem
                    return [
                      _c("fill-select-table-view", {
                        attrs: {
                          fields: fields,
                          datas: datas,
                          isAdmin: isAdmin,
                          viewItem: viewItem,
                          removeItem: removeItem,
                          onHandlePreviewImg: _vm.onHandlePreviewImg,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-container" }, [
                _c("div"),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.saveNewData },
                      },
                      [_vm._v("确 定")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.closeNewData },
                      },
                      [_vm._v("关 闭")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }