import request from '@/utils/request'
import {isBlank} from "@/utils/whale";

// 查询参数列表
export function jsApiTicket(url) {
  return request({
    url: 'wechat/web/jsApiTicket',
    method: 'get',
    params: { url }
  })
}
