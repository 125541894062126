import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'

import {
  FilterStringRender,
  FilterNumberRender,
  FilterSelectRender,
  FilterCheckboxRender,
  FilterRegionRender,
  FilterAddressRender,
  FilterNationalityRender,
  FilterUniversityRender,
  FilterTimeRender,
  FilterDateRender,
  FilterRateRender,
  FilterStatusRender,
  FilterDepartRender,
  FilterUserSelectRender,
  FilterSignRender,
  FilterPictureUploadRender,
  FilterFileUPloadRender,
  FilterCascaderRender,
  FilterPayRender
} from './gridrender/filterRender';


import {
  CellCheckRender,
  CellRegionRender,
  CellAddressRender,
  CellUniversityRender,
  CellRadioRender,
  CellCheckboxRender,
  CellCascaderRender,
  CellRateRender,
  CellRichEditorRender,
  CellSubFormRender,
  CellPictureUploaderRender,
  CellFileUploaderRender,
  CellAgencyDeptRender,
  CellPictureSelectRender,
  CellFillSelectRender,
  CellSignRender,
  CellUserSelectRender,
  CellSysUserRender,
  CellPayStatusRender,
  CellOCRCodeRender,
  CellAuditRemarkRender,
  CellAuditSignRender,
  CellDataChooseRender,
  CellSysTimeRender,
  EditInputRender
} from './gridrender/cellRender';


//文本框筛选
VXETable.renderer.add('filter-string', FilterStringRender)

//数值筛选
VXETable.renderer.add('filter-number', FilterNumberRender)

//下拉筛选
// VXETable.renderer.add('filter-radio', FilterSelectRender)
VXETable.renderer.add('filter-radio', FilterCheckboxRender)

//下拉筛选
VXETable.renderer.add('filter-single-select', FilterSelectRender)
VXETable.renderer.add('filter-select', FilterCheckboxRender)

//下拉筛选
VXETable.renderer.add('filter-checkbox', FilterCheckboxRender)

//省市区区筛选
VXETable.renderer.add('filter-region', FilterRegionRender)

//地址筛选
VXETable.renderer.add('filter-address', FilterAddressRender)

//民族筛选
VXETable.renderer.add('filter-nationality', FilterNationalityRender)

//高校筛选
VXETable.renderer.add('filter-university', FilterUniversityRender)

//时间筛选
VXETable.renderer.add('filter-time', FilterTimeRender)

//日期筛选
VXETable.renderer.add('filter-date', FilterDateRender)

//评分筛选
VXETable.renderer.add('filter-rate', FilterRateRender);

//状态筛选
VXETable.renderer.add('filter-status', FilterStatusRender);

//部门筛选
VXETable.renderer.add('filter-depart', FilterDepartRender);

//用户筛选
VXETable.renderer.add('filter-userselect', FilterUserSelectRender);

//签名筛选
VXETable.renderer.add('filter-sign', FilterSignRender);

//图片筛选
VXETable.renderer.add('filter-picture-upload', FilterPictureUploadRender);

//文件筛选
VXETable.renderer.add('filter-file-upload', FilterPictureUploadRender);

//cascader筛选
VXETable.renderer.add('filter-cascader', FilterCascaderRender);


VXETable.renderer.add('filter-pay', FilterPayRender);


////////////////////////////////////////////////////////////////////////////////////


VXETable.renderer.add('cell-checkbox',CellCheckRender)

//地区显示
VXETable.renderer.add('cell-region', CellRegionRender)

//详细地址显示
VXETable.renderer.add('cell-address', CellAddressRender)

//高校显示
VXETable.renderer.add('cell-university', CellUniversityRender)

//单选显示
VXETable.renderer.add('cell-radio', CellRadioRender)

//多选显示
VXETable.renderer.add('cell-checkbox', CellCheckboxRender)


VXETable.renderer.add('cell-rate', CellRateRender)


VXETable.renderer.add('cell-sub-form', CellSubFormRender)


VXETable.renderer.add('cell-rich-editor', CellRichEditorRender)


VXETable.renderer.add('cell-picture-upload', CellPictureUploaderRender)


VXETable.renderer.add('cell-file-upload', CellFileUploaderRender)


VXETable.renderer.add('cell-depart', CellAgencyDeptRender)


VXETable.renderer.add('cell-picture-select',CellPictureSelectRender)


VXETable.renderer.add('cell-fill-select',CellFillSelectRender)


VXETable.renderer.add('cell-cascader',CellCascaderRender)


VXETable.renderer.add('cell-sign',CellSignRender)


VXETable.renderer.add('cell-user-select',CellUserSelectRender)


VXETable.renderer.add('cell-ownUser',CellSysUserRender)


VXETable.renderer.add('cell-createUser',CellSysUserRender)


VXETable.renderer.add('cell-fillUser',CellSysUserRender)


VXETable.renderer.add('cell-payStatus',CellPayStatusRender)


VXETable.renderer.add('cell-ocrcode',CellOCRCodeRender)


VXETable.renderer.add('cell-auditRemark',CellAuditRemarkRender)


VXETable.renderer.add('cell-auditSign',CellAuditSignRender)

VXETable.renderer.add('cell-data-choose', CellDataChooseRender)

VXETable.renderer.add('cell-systime', CellSysTimeRender)


VXETable.renderer.add('edit-input', EditInputRender)

