<template>
    <div class="my-filter-input">
        <!-- {{moment(option.data[0]).format('YYYY-MM-DD HH:mm:ss')}}
        {{moment(option.data[1]).format('YYYY-MM-DD HH:mm:ss')}} -->
        <div class="filter-data">
            <el-time-picker v-model="option.data" is-range
             range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
            </el-time-picker>
        </div>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterTime',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
            moment:require('moment')
        };
    },
    created() {
        this.load();
        console.log('data', this.option);
    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!this.option.data;
            $panel.changeOption(null, checked, option);
        },
        clearFilter() {
            this.option.data = [new Date(2022, 1, 1, 0, 0), new Date(2022, 1, 1, 23, 59,59)];
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data;

            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
.my-filter-input {
    padding: 10px;
}

</style>