<template>
    <div class="my-filter-input">
       <el-cascader v-model="option.data" placeholder="请选择筛选内容" :filterable="true" 
         :props="{ checkStrictly:true, multiple:false, expandTrigger: 'hover' ,lazy:true, lazyLoad:lazyLoad }">
        </el-cascader>
        <div style="text-align:center;margin-top:5px;">
            <el-button size="mini" @click="clearFilter">清空</el-button>
            <el-button size="mini" type="primary" @click="confirmFilter">确认</el-button>
        </div>
    </div>
</template>

<script>


import {getToken} from '@/utils/auth'
export default {
    name: 'FilterAddress',
    props: {
        params: Object,
    },
    data() {
        return {
            column: null,
            option: null,
            pcas:[],
            authorization:getToken()
        };
    },
    created() {
        this.load();

    },
    methods: {
        load() {
            const { column } = this.params;
            const option = column.filters[0];
            this.column = column;
            this.option = option;
        },
         lazyLoad(node, resolve) {
            const { level } = node;    
            if(level==0){
                this.loadAreaList(0,'','',resolve);
            }else if(level==1){
                this.loadAreaList(1,node.data.value,'',resolve);
            }else if(level==2){
                this.loadAreaList(2,node.parent.value,node.data.value,resolve);
            }else{
                resolve();
            }
        },
        loadAreaList(level,province,city,resolve) {
            axios({
                method: 'get',
                url: `/api/sys/areaSelectList?province=${province}&city=${city}`,
                headers:{
                    Authorization:this.authorization
                }
            }).then((res) => {
                const nodes=[];
                res.data.data.forEach(x=>{
                    nodes.push({
                        value:x,
                        label:x,
                        leaf:level>=2
                    })
                })
                resolve(nodes);
            });
        },
        changeOptionEvent() {
            const { params, option } = this;
            const { $panel } = params;
            const checked = !!option.data;
            $panel.changeOption(null, checked, option);
        },
        clearFilter() {
            this.option.data = '';
            const { $panel } = this.params;
            $panel.resetFilter();
        },
        confirmFilter() {
            const { $panel } = this.params;
            const checked = !!this.option.data;

            //对于弹窗的选择 必须先执行一下changeOption否则无法调用到filterMethod
            $panel.changeOption(null, checked, this.option);
            $panel.confirmFilter(this.params);
        },
    },
};
</script>

<style scoped>
.my-filter-input {
    padding: 10px;
}
</style>