var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("canvas", {
    ref: "canvas",
    staticStyle: { "background-color": "#dfdfdf" },
    on: {
      mousedown: _vm.mouseDown,
      mousemove: _vm.mouseMove,
      touchstart: _vm.touchStart,
      touchmove: _vm.touchMove,
      touchend: _vm.touchEnd,
    },
    nativeOn: {
      mouseup: function ($event) {
        return _vm.mouseUp($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }